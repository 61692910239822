import { FC, forwardRef, useState } from 'react'
import { Card, CardContent, Divider, Grid, Snackbar, TextField } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { format } from 'date-fns'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { ICompletedChecklist } from '../../models/completedChecklist'

interface CompletedChecklistProps {
  data: ICompletedChecklist
}

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const CompletedChecklistDetails: FC<CompletedChecklistProps> = ({ data }) => {
  const [errorMessage, setErrorMessage] = useState<string>()

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader
            title="Completed Checklist"
            subtitle="View details about the completed checklist"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  label="Open Date"
                  value={format(new Date(data.createdAt), 'MMMM dd yyyy')}
                  disabled
                />
                <TextField
                  variant="outlined"
                  label="Completed By"
                  value={`${data.createdBy.name} ${data.createdBy.surname}`}
                  disabled
                />
                <TextField
                  variant="outlined"
                  label="Checklist Name"
                  value={data.checklist.name}
                  disabled
                />
                <TextField variant="outlined" label="Comment" value={data.comment} disabled />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Footer />
    </>
  )
}

export default CompletedChecklistDetails
