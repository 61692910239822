import axios from 'axios'

import { EquipmentStatusType } from '../models/equipment'
import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IEquipmentCreate {
  acquiredDate: Date
  acquisitionValue: number
  costCentreId: string
  equipmentDescription: string
  functionalLocationId: string
  identification: string
  initialDate: Date
  modelNumber: string
  equipmentName: string
  parentId?: string
  partNumber: string
  serialNumber: string
  status: EquipmentStatusType
  weight: number
  city: string
  code: string
  complexName?: string
  country: string
  email: string
  leadTime: number
  manufacturerName: string
  province: string
  streetName: string
  streetNumber: string
  suburb: string
  telephone: string
  token: string
  unitNumber?: string
  maintenancePlanDescription: string
  maintenanceType: string
  schedulingType: string
}

export interface IEquipmentGet {
  id?: string
  token: string
}

export default class EquipmentRequests {
  static getAll = (data: IEquipmentGet) => {
    return axios.get(`${domain}/equipment`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IEquipmentGet) => {
    return axios.get(`${domain}/equipment/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static create = (data: IEquipmentCreate) => {
    return axios.post(
      `${domain}/equipment`,
      {
        acquiredDate: data.acquiredDate,
        acquisitionValue: data.acquisitionValue,
        costCentreId: data.costCentreId,
        equipmentDescription: data.equipmentDescription,
        functionalLocationId: data.functionalLocationId,
        identification: data.identification,
        initialDate: data.initialDate,
        modelNumber: data.modelNumber,
        equipmentName: data.equipmentName,
        parentId: data.parentId,
        partNumber: data.partNumber,
        serialNumber: data.serialNumber,
        status: data.status,
        weight: data.weight,
        city: data.city,
        code: data.code,
        complexName: data.complexName,
        country: data.country,
        email: data.email,
        leadTime: data.leadTime,
        manufacturerName: data.manufacturerName,
        province: data.province,
        streetName: data.streetName,
        streetNumber: data.streetNumber,
        suburb: data.suburb,
        telephone: data.telephone,
        unitNumber: data.unitNumber,
        maintenancePlanDescription: data.maintenancePlanDescription,
        maintenanceType: data.maintenanceType,
        schedulingType: data.schedulingType,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }
}
