import { ChangeEvent, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { useNavigate } from 'react-router'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import ShiftRequests from '../../server/shift'
import { ShiftBreakType } from '../../models/shift'
import { useAppSelector } from '../../store'

const allowanceType = [
  {
    value: 'enable',
    label: 'Enable',
  },
  {
    value: 'disable',
    label: 'Disable',
  },
]

const shiftType = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

const applicableType = [
  {
    value: 'Specific Department',
    label: 'Specific Department',
  },
  {
    value: 'Entire Plant',
    label: 'Entire Plant',
  },
]

const employeeType = [
  {
    value: 'Sizwe Khumalo',
    label: 'Sizwe Khumalo',
  },
  {
    value: 'John Doe',
    label: 'John Doe',
  },
]

const shiftNameType = [
  {
    value: 'Production Morning Shift',
    label: 'Production Morning Shift',
  },
  {
    value: 'Maintenance Afternoon Shift',
    label: 'Maintenance Afternoon Shift',
  },
]

const payType = [
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'unpaid',
    label: 'Unpaid',
  },
]

const ShiftDetails = () => {
  // shiftAllowanceRate,
  const userState = useAppSelector((state) => state.user).user
  const [shift, setShift] = useState<string>('')
  const [applicable, setApplicable] = useState<string>('')
  const [employee, setEmployee] = useState<string>('')
  const [shiftName, setShiftName] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const [breakPayType, setBreakPayType] = useState<ShiftBreakType>('unpaid')
  const [breakStartTime, setBreakStartTime] = useState<string | null>('')
  const [breakEndTime, setBreakEndTime] = useState<string | null>('')
  const [startTime, setStartTime] = useState<string | null>('')
  const [endTime, setEndTime] = useState<string | null>('')
  const [name, setName] = useState<string>('')
  const [shiftAllowance, setShiftAllowance] = useState<string>('disable')
  const [shiftAllowanceRate, setShiftAllowanceRate] = useState<number>(0)

  const navigate = useNavigate()

  const handleAllowanceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setShiftAllowance(event.target.value as string)
  }

  const handleApplicableChange = (event: ChangeEvent<{ value: unknown }>) => {
    setApplicable(event.target.value as string)
  }

  const handleShiftChange = (event: ChangeEvent<{ value: unknown }>) => {
    setShift(event.target.value as string)
  }

  const handleEmployeeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEmployee(event.target.value as string)
  }

  const handleShiftNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setShiftName(event.target.value as string)
  }

  const handlePayChange = (event: ChangeEvent<{ value: unknown }>) => {
    setBreakPayType(event.target.value as ShiftBreakType)
  }

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleAllowanceRateChange = (event: ChangeEvent<{ value: unknown }>) => {
    setShiftAllowanceRate(event.target.value as number)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  const onAddShift = async () => {
    if (!breakStartTime) {
      return
    }

    if (!breakEndTime) {
      return
    }

    if (!startTime) {
      return
    }

    if (!endTime) {
      return
    }

    const payload = {
      breakStart: breakStartTime,
      breakEnd: breakEndTime,
      breakPayType,
      createdBy: userState!.id,
      endTime,
      name,
      shiftAllowance: shiftAllowance === 'enable' ? true : false,
      shiftAllowanceRate,
      startTime,
    }

    const response = await ShiftRequests.create(payload)

    if (response.status === 201) {
      return navigate('../shift-manager/')
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Configure New Shift" subtitle="Specify details of new shift" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="super"
                  select
                  variant="outlined"
                  required
                  label="Is this a Super Shift?"
                  value={shift}
                  onChange={handleShiftChange}
                >
                  {shiftType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="outlined"
                  required
                  id="name"
                  label="Shift Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <MobileTimePicker
                  label="Shift Starts"
                  value={startTime}
                  onChange={() => {}}
                  onAccept={(newValue) => {
                    setStartTime(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileTimePicker
                  label="Shift Ends"
                  value={endTime}
                  onChange={() => {}}
                  onAccept={(newValue) => {
                    setEndTime(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  id="shift-allowance"
                  select
                  variant="outlined"
                  required
                  label="Shift Allowance"
                  value={shiftAllowance}
                  onChange={handleAllowanceChange}
                >
                  {allowanceType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="number"
                  variant="outlined"
                  required
                  id="allowance-rate"
                  label="If applicable, what is the Rate per Hour?"
                  value={shiftAllowanceRate}
                  onChange={handleAllowanceRateChange}
                />
                <TextField
                  id="applicable"
                  select
                  variant="outlined"
                  required
                  label="Applicable To"
                  value={applicable}
                  onChange={handleApplicableChange}
                >
                  {applicableType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Shift Mapping" subtitle="Assign Shift to a specific employee" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="applicable for"
                  select
                  variant="outlined"
                  required
                  label="Applicable for"
                  value={employee}
                  onChange={handleEmployeeChange}
                >
                  {employeeType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="shift-name"
                  select
                  variant="outlined"
                  required
                  label="Select Shift"
                  value={shiftName}
                  onChange={handleShiftNameChange}
                >
                  {shiftNameType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MobileDatePicker
                  label="From"
                  inputFormat="dd MMM yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="To"
                  inputFormat="dd MMM yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Applicable Break" subtitle="Assign breaks to specific shifts" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <MobileTimePicker
                  label="Break Starts"
                  value={breakStartTime}
                  onChange={() => {}}
                  onAccept={(newValue) => {
                    setBreakStartTime(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileTimePicker
                  label="Break Ends"
                  value={breakEndTime}
                  onChange={() => {}}
                  onAccept={(newValue) => {
                    setBreakEndTime(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  id="paid"
                  select
                  variant="outlined"
                  required
                  label="Pay Type"
                  value={breakPayType}
                  onChange={handlePayChange}
                >
                  {payType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddShift}>
          Create Shift
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}

export default ShiftDetails
