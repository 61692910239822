import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import BomRequests, { IBomCreate, IBomGet } from '../server/bom'
import { IBom } from '../models/bom'
import { formatApiError } from '../utilities/Helpers'

interface IBomState {
  selectedBom: IBom | null
  boms: IBom[]
}

export const getBom = createAsyncThunk('bom/getBom', async (data: IBomGet, thunkAPI) => {
  try {
    const response = await BomRequests.get(data)

    return {
      item: response.data,
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(formatApiError(error))
  }
})

export const getAllBoms = createAsyncThunk('bom/getAllBoms', async (data: IBomGet, thunkAPI) => {
  try {
    const response = await BomRequests.getAll(data)

    return {
      items: response.data,
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(formatApiError(error))
  }
})

export const createBom = createAsyncThunk('bom/createBom', async (data: IBomCreate, thunkAPI) => {
  try {
    await BomRequests.create(data)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(formatApiError(error))
  }
})

export const clearBom = createAsyncThunk('bom/clearBom', () => {})

const initialState: IBomState = {
  selectedBom: null,
  boms: [],
}

const bomSlice = createSlice({
  name: 'bom',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBoms.fulfilled, (state, { payload }) => {
      state.boms = payload.items
    })
    builder.addCase(getBom.fulfilled, (state, { payload }) => {
      state.selectedBom = payload.item
    })
    builder.addCase(clearBom.fulfilled, (state) => {
      state.selectedBom = null
    })
  },
})

const { reducer } = bomSlice
export default reducer
