import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function Breakdowns() {
  return (
    <>
      <Helmet>
        <title>Breakdowns</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Breakdowns"
          subHeading="These are your breakdowns."
          buttonText="Add Breakdown"
          showButton
          onPress={() => console.log('Add Breakdown')}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default Breakdowns
