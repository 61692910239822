import PropTypes from 'prop-types'
import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const useIsWidthUp = (breakpoint: Breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

const useIsWidthDown = (breakpoint: Breakpoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down(breakpoint))
}

const HiddenTs = (props: any) => {
  const { children, only, width } = props
  const theme = useTheme()

  const xsUp = useIsWidthUp('xs')
  const smUp = useIsWidthUp('sm')
  const mdUp = useIsWidthUp('md')
  const lgUp = useIsWidthUp('lg')
  const xlUp = useIsWidthUp('xl')

  const xsDown = useIsWidthDown('xs')
  const smDown = useIsWidthDown('sm')
  const mdDown = useIsWidthDown('md')
  const lgDown = useIsWidthDown('lg')
  const xlDown = useIsWidthDown('xl')

  let visible = true

  // `only` check is faster to get out sooner if used.
  if (only) {
    if (Array.isArray(only)) {
      for (let i = 0; i < only.length; i += 1) {
        const breakpoint = only[i]
        if (width === breakpoint) {
          visible = false
          break
        }
      }
    } else if (only && width === only) {
      visible = false
    }
  }

  // Allow `only` to be combined with other props. If already hidden, no need to check others.
  if (visible) {
    // determine visibility based on the smallest size up
    for (let i = 0; i < theme.breakpoints.keys.length; i += 1) {
      const breakpoint = theme.breakpoints.keys[i]
      const breakpointUp = props[`${breakpoint}Up`]
      const breakpointDown = props[`${breakpoint}Down`]

      let up
      let down
      switch (breakpoint) {
        case 'xs':
          up = xsUp
          down = xsDown
          break
        case 'sm':
          up = smUp
          down = smDown
          break
        case 'md':
          up = mdUp
          down = mdDown
          break
        case 'lg':
          up = lgUp
          down = lgDown
          break
        case 'xl':
          up = xlUp
          down = xlDown
          break
      }

      if ((breakpointUp && up) || (breakpointDown && down)) {
        visible = false
        break
      }
    }
  }

  if (!visible) {
    return null
  }

  return children
}

HiddenTs.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Specify which implementation to use.  'js' is the default, 'css' works better for
   * server-side rendering.
   */
  implementation: PropTypes.oneOf(['js', 'css']),
  /**
   * You can use this prop when choosing the `js` implementation with server-side rendering.
   *
   * As `window.innerWidth` is unavailable on the server,
   * we default to rendering an empty component during the first mount.
   * You might want to use an heuristic to approximate
   * the screen width of the client browser screen width.
   *
   * For instance, you could be using the user-agent or the client-hints.
   * https://caniuse.com/#search=client%20hint
   */
  initialWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * If `true`, screens this size and down will be hidden.
   */
  lgDown: PropTypes.bool,
  /**
   * If `true`, screens this size and up will be hidden.
   */
  lgUp: PropTypes.bool,
  /**
   * If `true`, screens this size and down will be hidden.
   */
  mdDown: PropTypes.bool,
  /**
   * If `true`, screens this size and up will be hidden.
   */
  mdUp: PropTypes.bool,
  /**
   * Hide the given breakpoint(s).
   */
  only: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    PropTypes.arrayOf(PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])),
  ]),
  /**
   * If `true`, screens this size and down will be hidden.
   */
  smDown: PropTypes.bool,
  /**
   * If `true`, screens this size and up will be hidden.
   */
  smUp: PropTypes.bool,
  /**
   * @ignore
   * width prop provided by withWidth decorator.
   */
  width: PropTypes.string,
  /**
   * If `true`, screens this size and down will be hidden.
   */
  xlDown: PropTypes.bool,
  /**
   * If `true`, screens this size and up will be hidden.
   */
  xlUp: PropTypes.bool,
  /**
   * If `true`, screens this size and down will be hidden.
   */
  xsDown: PropTypes.bool,
  /**
   * If `true`, screens this size and up will be hidden.
   */
  xsUp: PropTypes.bool,
}

export default HiddenTs
