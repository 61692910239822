import { FC, memo } from 'react'
import { Handle, Node, Position } from 'reactflow'
import { Avatar, Card, CardHeader, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { primaryColour } from '../../utilities/Constants'
import { toTitleCase } from '../../utilities/Helpers'

import ConveyorBeltImg from '../../img/conveyor_belt.svg'
import CrusherImg from '../../img/crusher.svg'
import FrontLoaderImg from '../../img/front_loader.svg'
import IspImg from '../../img/triangle.svg'
import MachineImg from '../../img/machine.svg'
import ScreenImg from '../../img/screen.svg'
import SplitImg from '../../img/split.svg'

const CustomNode: FC<Node> = ({ data }) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={
              data.equipmentType === 'feed'
                ? FrontLoaderImg
                : data.equipmentType === 'crusher'
                ? CrusherImg
                : data.equipmentType === 'conveyorBelt'
                ? ConveyorBeltImg
                : data.equipmentType === 'screen'
                ? ScreenImg
                : data.equipmentType === 'chute'
                ? SplitImg
                : data.equipmentType === 'isp'
                ? IspImg
                : MachineImg
            }
          />
        }
        action={
          <IconButton sx={{ marginLeft: 2 }} onClick={data.onSettingsPress}>
            <MenuIcon />
          </IconButton>
        }
        title={data.title}
        subheader={toTitleCase(data.equipmentType)}
      />

      {data.nodeType === 'input' && (
        <Handle
          id="d"
          type="source"
          position={Position.Bottom}
          style={{ ...styles.horizontalHandle, ...styles.bottomHandle, ...styles.source }}
        />
      )}

      {data.nodeType === 'middle' && (
        <>
          <Handle
            id="a"
            type="target"
            position={Position.Top}
            style={{ ...styles.horizontalHandle, ...styles.topHandle, ...styles.target }}
          />
          <Handle
            id="b"
            type="source"
            position={Position.Left}
            style={
              data.equipmentType === 'screen'
                ? { ...styles.verticalHandle, ...styles.rightHandle }
                : { opacity: '0%' }
            }
            isConnectable={data.equipmentType === 'screen' ? true : false}
          />
          <Handle
            id="c"
            type="source"
            position={Position.Right}
            style={
              data.equipmentType === 'screen'
                ? { ...styles.verticalHandle, ...styles.rightHandle }
                : { opacity: '0%' }
            }
            isConnectable={data.equipmentType === 'screen' ? true : false}
          />
          <Handle
            id="d"
            type="source"
            position={Position.Bottom}
            style={{ ...styles.horizontalHandle, ...styles.bottomHandle, ...styles.source }}
          />
        </>
      )}

      {data.nodeType === 'output' && (
        <Handle
          id="a"
          type="target"
          position={Position.Top}
          style={{ ...styles.horizontalHandle, ...styles.topHandle, ...styles.target }}
        />
      )}
    </Card>
  )
}

const styles = {
  verticalHandle: {
    width: '10px',
    height: '30px',
    borderRadius: '3px',
    backgroundColor: primaryColour,
  },
  horizontalHandle: {
    width: '40px',
    height: '10px',
    borderRadius: '3px',
    backgroundColor: primaryColour,
  },
  topHandle: {
    top: '-15px',
  },
  leftHandle: {
    left: '-15px',
  },
  rightHandle: {
    right: '-15px',
  },
  bottomHandle: {
    bottom: '-15px',
  },
  source: {
    backgroundColor: 'green',
  },
  target: {
    backgroundColor: 'black',
  },
}

export default memo(CustomNode)
