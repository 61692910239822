import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function Tasks() {
  return (
    <>
      <Helmet>
        <title>Tasks</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Tasks"
          subHeading="These are your tasks."
          buttonText="Add Task"
          showButton
          onPress={() => console.log('Add Task')}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default Tasks
