import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IRoutineMaintenance } from '../models/routineMaintenance'
import RoutineMaintenanceRequests, {
  IRoutineMaintenanceCreate,
  IRoutineMaintenanceGet,
} from '../server/routineMaintenance'
import { formatApiError } from '../utilities/Helpers'

interface IRoutineMaintenanceState {
  selectedRoutineMaintenance: IRoutineMaintenance | null
  routineMaintenances: IRoutineMaintenance[]
}

export const getRoutineMaintenance = createAsyncThunk(
  'routineMaintenance/getRoutineMaintenance',
  async (data: IRoutineMaintenanceGet, thunkAPI) => {
    try {
      const response = await RoutineMaintenanceRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllRoutineMaintenances = createAsyncThunk(
  'routineMaintenance/getAllRoutineMaintenances',
  async (data: IRoutineMaintenanceGet, thunkAPI) => {
    try {
      const response = await RoutineMaintenanceRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createRoutineMaintenance = createAsyncThunk(
  'routineMaintenance/createRoutineMaintenance',
  async (data: IRoutineMaintenanceCreate, thunkAPI) => {
    try {
      await RoutineMaintenanceRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearRoutineMaintenance = createAsyncThunk(
  'routineMaintenance/clearRoutineMaintenance',
  () => {},
)

const initialState: IRoutineMaintenanceState = {
  selectedRoutineMaintenance: null,
  routineMaintenances: [],
}

const routineMaintenanceSlice = createSlice({
  name: 'routineMaintenance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRoutineMaintenances.fulfilled, (state, { payload }) => {
      state.routineMaintenances = payload.items
    })
    builder.addCase(getRoutineMaintenance.fulfilled, (state, { payload }) => {
      state.selectedRoutineMaintenance = payload.item
    })
    builder.addCase(clearRoutineMaintenance.fulfilled, (state) => {
      state.selectedRoutineMaintenance = null
    })
  },
})

const { reducer } = routineMaintenanceSlice
export default reducer
