import { FC, useState, createContext, ReactNode } from 'react'
import PropTypes from 'prop-types'

type SidebarContextType = { sidebarToggle: any; toggleSidebar: () => void }

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType)

interface SidebarProviderProps {
  children?: ReactNode
}

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false)
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }

  return (
    <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  )
}

SidebarProvider.propTypes = {
  children: PropTypes.any,
}
