import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import CostCentreRequests, { ICostCentreCreate, ICostCentreGet } from '../server/costCentre'
import { ICostCentre } from '../models/costCentre'
import { formatApiError } from '../utilities/Helpers'

interface ICostCentresState {
  selectedCostCentre: ICostCentre | null
  costCentres: ICostCentre[]
}

export const getCostCentre = createAsyncThunk(
  'costCentre/getCostCentre',
  async (data: ICostCentreGet, thunkAPI) => {
    try {
      const response = await CostCentreRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllCostCentres = createAsyncThunk(
  'costCentre/getAllCostCentres',
  async (data: ICostCentreGet, thunkAPI) => {
    try {
      const response = await CostCentreRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createCostCentre = createAsyncThunk(
  'costCentre/createCostCentre',
  async (data: ICostCentreCreate, thunkAPI) => {
    try {
      await CostCentreRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearCostCentre = createAsyncThunk('costCentre/clearCostCentre', () => {})

const initialState: ICostCentresState = {
  selectedCostCentre: null,
  costCentres: [],
}

const costCentreSlice = createSlice({
  name: 'costCentre',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCostCentres.fulfilled, (state, { payload }) => {
      state.costCentres = payload.items
    })
    builder.addCase(getCostCentre.fulfilled, (state, { payload }) => {
      state.selectedCostCentre = payload.item
    })
    builder.addCase(clearCostCentre.fulfilled, (state) => {
      state.selectedCostCentre = null
    })
  },
})

const { reducer } = costCentreSlice
export default reducer
