import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import PreWorkRequests, { IPreWorkCreate, IPreWorkGet } from '../server/preWork'
import { IPreWork } from '../models/preWork'
import { formatApiError } from '../utilities/Helpers'

interface IPreWorkState {
  selectedPreWork: IPreWork | null
  preWorks: IPreWork[]
}

export const getPreWork = createAsyncThunk(
  'preWork/getPreWork',
  async (data: IPreWorkGet, thunkAPI) => {
    try {
      const response = await PreWorkRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllPreWorks = createAsyncThunk(
  'preWork/getAllPreWorks',
  async (data: IPreWorkGet, thunkAPI) => {
    try {
      const response = await PreWorkRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createPreWork = createAsyncThunk(
  'preWork/createPreWork',
  async (data: IPreWorkCreate, thunkAPI) => {
    try {
      await PreWorkRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearPreWork = createAsyncThunk('preWork/clearPreWork', () => {})

const initialState: IPreWorkState = {
  selectedPreWork: null,
  preWorks: [],
}

const preWorkSlice = createSlice({
  name: 'preWork',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPreWorks.fulfilled, (state, { payload }) => {
      state.preWorks = payload.items
    })
    builder.addCase(getPreWork.fulfilled, (state, { payload }) => {
      state.selectedPreWork = payload.item
    })
    builder.addCase(clearPreWork.fulfilled, (state) => {
      state.selectedPreWork = null
    })
  },
})

const { reducer } = preWorkSlice
export default reducer
