import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Snackbar, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import PreWorksTable from './PreWorksTable'
import { getAllMaintenanceRequests } from '../../slice/maintenanceRequest'
import { useAppDispatch, useAppSelector } from '../../store'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'
import MaintenanceRequestTable from './MaintenanceRequestTable'
import { getAllPreWorks } from '../../slice/preWork'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const Inspection = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentTab, setCurrentTab] = useState<string>('maintenanceRequests')

  const user = useAppSelector((state) => state.user).user
  const maintenanceRequests = useAppSelector(
    (state) => state.maintenanceRequest,
  ).maintenanceRequests

  const preWorks = useAppSelector((state) => state.preWork).preWorks

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllMaintenanceRequests({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllPreWorks({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  const tabs = [
    { value: 'maintenanceRequests', label: 'Notifications' },
    { value: 'preWorks', label: 'Pre Works' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const getButtonText = (): string => {
    switch (currentTab) {
      case 'maintenanceRequests':
        return 'Add Notification'

      default:
        return ''
    }
  }

  const getButtonLink = (): string => {
    switch (currentTab) {
      case 'maintenanceRequests':
        return 'add-maintenance-request'

      default:
        return ''
    }
  }

  return (
    <>
      <Helmet>
        <title>Inspections</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Operator Checklists"
          subHeading="Check list items allocated to the operator"
          buttonText={getButtonText()}
          showButton={getButtonText() ? true : false}
          link={getButtonLink()}
        />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg" sx={{ pb: 4 }}>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'preWorks' && <PreWorksTable data={preWorks} />}
            {currentTab === 'maintenanceRequests' && (
              <MaintenanceRequestTable data={maintenanceRequests} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Inspection
