import { ChangeEvent, forwardRef, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import Footer from '../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../store'
import { createCostCentre } from '../../slice/costCentre'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const CostCentreDetails = () => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const user = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const onAddCostCentre = async () => {
    const payload = {
      token: user!.accessToken!,
      description,
      name,
    }

    try {
      await dispatch(createCostCentre(payload)).unwrap()
      navigate('../cost-centres/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Create Cost Centre" subtitle="Add cost centre details below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
        <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
          <div />
          <Button variant="contained" onClick={onAddCostCentre}>
            Create Cost Centre
          </Button>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}

export default CostCentreDetails
