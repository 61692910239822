import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IBomCreate {
  description: string
  isCritical: boolean
  itemNumber: string
  minSparesRequired: number
  quantity: number
  symbolNumber: string
  token: string
}

export interface IBomGet {
  id?: string
  token: string
}

export default class BomRequests {
  static create = (data: IBomCreate) => {
    return axios.post(
      `${domain}/bom`,
      {
        description: data.description,
        isCritical: data.isCritical,
        itemNumber: data.itemNumber,
        minSparesRequired: data.minSparesRequired,
        quantity: data.quantity,
        symbolNumber: data.symbolNumber,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IBomGet) => {
    return axios.get(`${domain}/bom`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IBomGet) => {
    return axios.get(`${domain}/bom/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
