import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import EquipmentChecklistDetails from './EquipmentChecklistDetails'

const AddEquipmentChecklist = () => {
  return (
    <>
      <Helmet>
        <title>Add Equipment Checklist</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <EquipmentChecklistDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddEquipmentChecklist
