import { ChangeEvent, FC, forwardRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { format } from 'date-fns'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { IMaintenanceRequest } from '../../models/maintenanceRequest'
import { IUser } from '../../models/users'
import { useAppDispatch, useAppSelector } from '../../store'
import { createPreWork } from '../../slice/preWork'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

interface MaintenanceRequestProps {
  data: IMaintenanceRequest
  isAssigningUser: boolean
  users: IUser[]
}

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const MaintenanceRequestDetails: FC<MaintenanceRequestProps> = ({
  data,
  isAssigningUser,
  users,
}) => {
  const [assignedUserId, setAssignedUserId] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const user = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleAssignUser = (event: ChangeEvent<{ value: unknown }>) => {
    setAssignedUserId(event.target.value as string)
  }

  const onCreatePreWork = async () => {
    const payload = {
      assignedUserId,
      maintenanceRequestId: data.id,
      token: user!.accessToken!,
    }

    try {
      await dispatch(createPreWork(payload)).unwrap()
      navigate('../inspection/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader
            title="Notification"
            subtitle={`${isAssigningUser ? 'Assign' : 'View'} Maintenance Request`}
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                {data.completedChecklistId && (
                  <div style={{ marginBottom: 10 }}>
                    <Link
                      href={`/maintenance/report/completed-checklist/${data.completedChecklistId}`}
                      underline="hover"
                      sx={{ m: 1 }}
                    >
                      Click here to view failed checklist
                    </Link>
                  </div>
                )}
                <TextField
                  variant="outlined"
                  label="Open Date"
                  value={format(new Date(data.createdAt), 'MMMM dd yyyy')}
                  disabled
                />
                {data.closedDate && (
                  <TextField
                    variant="outlined"
                    label="Closed Date"
                    value={format(new Date(data.closedDate), 'MMMM dd yyyy')}
                    disabled
                  />
                )}
                <TextField variant="outlined" label="Header" value={data.title} disabled />
                {data.functionalLocation && (
                  <TextField
                    variant="outlined"
                    label="Activity Area"
                    value={data.functionalLocation.name}
                    disabled
                  />
                )}
                {data.equipment && (
                  <TextField
                    variant="outlined"
                    label="Equipment"
                    value={data.equipment.name}
                    disabled
                  />
                )}
                <TextField
                  variant="outlined"
                  label="Description"
                  value={data.description}
                  disabled
                />
                <TextField
                  variant="outlined"
                  label="Effect on Operations"
                  value={data.operationalEffect}
                  disabled
                />
                <TextField
                  variant="outlined"
                  label="Escalation Required"
                  value={data.escalation}
                  disabled
                />
                {isAssigningUser && (
                  <TextField
                    select
                    variant="outlined"
                    required
                    label="Assign User"
                    value={assignedUserId}
                    onChange={handleAssignUser}
                  >
                    {users.map((option) => (
                      <MenuItem key={option.id} value={option.id} sx={{ width: '100%' }}>
                        {`${option.name} ${option.surname}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        {isAssigningUser && (
          <Button variant="contained" onClick={onCreatePreWork}>
            Create Pre Works
          </Button>
        )}
      </Grid>
      <Footer />
    </>
  )
}

export default MaintenanceRequestDetails
