import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IDepartment } from '../models/department'
import DepartmentRequests, { IDepartmentCreate, IDepartmentGet } from '../server/department'
import { formatApiError } from '../utilities/Helpers'

interface IDepartmentState {
  selectedDepartment: IDepartment | null
  departments: IDepartment[]
}

export const getDepartment = createAsyncThunk(
  'department/getDepartment',
  async (data: IDepartmentGet, thunkAPI) => {
    try {
      const response = await DepartmentRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllDepartments = createAsyncThunk(
  'department/getAllDepartments',
  async (data: IDepartmentGet, thunkAPI) => {
    try {
      const response = await DepartmentRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createDepartment = createAsyncThunk(
  'department/createDepartment',
  async (data: IDepartmentCreate, thunkAPI) => {
    try {
      await DepartmentRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearDepartment = createAsyncThunk('department/clearDepartment', () => {})

const initialState: IDepartmentState = {
  selectedDepartment: null,
  departments: [],
}

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDepartments.fulfilled, (state, { payload }) => {
      state.departments = payload.items
    })
    builder.addCase(getDepartment.fulfilled, (state, { payload }) => {
      state.selectedDepartment = payload.item
    })
    builder.addCase(clearDepartment.fulfilled, (state) => {
      state.selectedDepartment = null
    })
  },
})

const { reducer } = departmentSlice
export default reducer
