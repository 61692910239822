import axios from 'axios'

import { IEdge } from '../models/edge'
import { INode } from '../models/node'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

interface IProductionModeCreate {
  createdBy: string
  description: string
  name: string
  functionalLocationId: string
  edges: IEdge[]
  nodes: INode[]
}

export default class ProductionModeRequests {
  static create = async (data: IProductionModeCreate) => {
    try {
      const response = await axios.post(`${domain}/mode`, data)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }

  static getAll = async () => {
    try {
      const response = await axios.get(`${domain}/mode`)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
