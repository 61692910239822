import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IPreWorkCreate {
  assignedUserId: string
  maintenanceRequestId: string
  token: string
}

export interface IPreWorkGet {
  id?: string
  token: string
}

export default class PreWorkRequests {
  static create = (data: IPreWorkCreate) => {
    return axios.post(
      `${domain}/pre-work`,
      {
        assignedUserId: data.assignedUserId,
        maintenanceRequestId: data.maintenanceRequestId,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IPreWorkGet) => {
    return axios.get(`${domain}/pre-work`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IPreWorkGet) => {
    return axios.get(`${domain}/pre-work/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
