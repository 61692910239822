import { forwardRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import Footer from '../../components/Footer'
import CostCentreTable from './CostCentreTable'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllCostCentres } from '../../slice/costCentre'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const CostCentres = () => {
  const [errorMessage, setErrorMessage] = useState<string>()

  const user = useAppSelector((state) => state.user).user
  const costCentres = useAppSelector((state) => state.costCentre).costCentres

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllCostCentres({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  return (
    <>
      <Helmet>
        <title>Cost Centres</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Cost Centres"
          subHeading="List of available cost centres"
          buttonText="Add Cost Centre"
          showButton
          link="add"
        />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <CostCentreTable data={costCentres} />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  )
}

export default CostCentres
