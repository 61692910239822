import { ChangeEvent, useState } from 'react'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const superiorType = [
  {
    value: 'Primary Section',
    label: 'Primary Section',
  },
  {
    value: 'Secondary Section',
    label: 'Secondary Section',
  },
  {
    value: 'Tertiary Section',
    label: 'Tertiary Section',
  },
]

const superEquipmentType = [
  {
    value: 'Conveyors',
    label: 'Conveyors',
  },
  {
    value: 'Evo Wash',
    label: 'Evo Wash',
  },
]

const subAssemblyType = [
  {
    value: 'Discharge point',
    label: 'Discharge point',
  },
  {
    value: 'Scrapers',
    label: 'Scrapers',
  },
  {
    value: 'Drum and Idlers',
    label: 'Drum and Idlers',
  },
]

const actionType = [
  {
    value: 'Breakdown',
    label: 'Breakdown Maintenance',
  },
  {
    value: 'Production Maintenance',
    label: 'Production Stop Maintenance',
  },
]

const ActualMaintenanceDetails = () => {
  const [superiorLocation, setSuperiorLocation] = useState<string>('')
  const [superEquipment, setSuperEquipment] = useState<string>('')
  const [subAssembly, setSubAssembly] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [value, setValue] = useState<Date | null>(new Date())
  const [value2, setValue2] = useState<Date | null>(new Date())

  const handleSuperEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperEquipment(event.target.value as string)
  }

  const handleSuperiorLocationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperiorLocation(event.target.value as string)
  }

  const handleSubAssemblyChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubAssembly(event.target.value as string)
  }

  const handleActionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAction(event.target.value as string)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Maintenance Location"
            subtitle="Specify details of maintenance work to be undertaken"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="reference-location"
                  select
                  variant="outlined"
                  required
                  label="Select Reference Activity Area"
                  value={superiorLocation}
                  onChange={handleSuperiorLocationChange}
                >
                  {superiorType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="equipment"
                  select
                  variant="outlined"
                  required
                  label="Equipment"
                  value={superEquipment}
                  onChange={handleSuperEquipmentChange}
                >
                  {superEquipmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="sub-assembly"
                  select
                  variant="outlined"
                  required
                  label="Sub Assembly"
                  value={subAssembly}
                  onChange={handleSubAssemblyChange}
                >
                  {subAssemblyType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="type of work"
                  select
                  variant="outlined"
                  required
                  label="Type of work"
                  value={action}
                  onChange={handleActionChange}
                >
                  {actionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="comments"
                  variant="outlined"
                  required
                  id="comments"
                  label="Maintenance Comments"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Planning Data" subtitle="" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="actual work duration"
                  variant="outlined"
                  required
                  id="description"
                  label="Time required to complete the work"
                />
                <TextField
                  name="no-pers"
                  variant="outlined"
                  required
                  id="no-pers"
                  label="Action Group"
                />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Schedule Start Date/Time"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                  }}
                />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Schedule End Date/Time "
                  value={value2}
                  onChange={(newValue) => {
                    setValue2(newValue)
                  }}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Spare(s) Order"
            subtitle="Place an order for spares required order"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Spare Required (search by symbol number)"
                />
                <TextField
                  name="Symbol number"
                  variant="outlined"
                  required
                  id="symbol number"
                  label="Quantity"
                />
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Spare Required (search by symbol number)"
                />
                <TextField
                  name="Symbol number"
                  variant="outlined"
                  required
                  id="symbol number"
                  label="Quantity"
                />
                <TextField
                  name="action-completed"
                  variant="outlined"
                  required
                  id="action-completed"
                  label="Action Completed"
                />
                <TextField
                  name="comments"
                  variant="outlined"
                  required
                  id="comments"
                  label="Comments"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Task Engagement"
            subtitle="Report on your work with the task at hand"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="sub-assembly"
                  select
                  variant="outlined"
                  required
                  label="Equipment/Subassembly "
                  value={subAssembly}
                  onChange={handleSubAssemblyChange}
                >
                  {subAssemblyType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="spare" variant="outlined" label="Summary of Scope of Work" />
                <TextField id="spare" variant="outlined" label="Spare Used" />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Request Extension"
                  value={value2}
                  onChange={(newValue) => {
                    setValue2(newValue)
                  }}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </LocalizationProvider>
  )
}

export default ActualMaintenanceDetails
