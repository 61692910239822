import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Snackbar, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import JobCardTable from './JobCardTable'
import RootCauseAnalysisTable from './RootCauseAnalysisTable'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllJobCards } from '../../slice/jobCard'
import { logout } from '../../slice/user'
import { IApiError } from '../../models/helpers'
import { getAllCompletedChecklists } from '../../slice/completedChecklist'
import CompletedChecklistTable from './CompletedChecklistTable'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const Report = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentTab, setCurrentTab] = useState<string>('checklists')

  const user = useAppSelector((state) => state.user).user
  const jobCards = useAppSelector((state) => state.jobCard).jobCards
  const completedChecklists = useAppSelector(
    (state) => state.completedChecklist,
  ).completedChecklists

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllJobCards({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllCompletedChecklists({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  const tabs = [
    { value: 'checklists', label: 'Checklists' },
    { value: 'jobCards', label: 'Job Cards' },
    { value: 'rootCauseAnalysis', label: 'Root Cause Analysis' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const getButtonText = (): string => {
    switch (currentTab) {
      case 'rootCauseAnalysis':
        return 'Add Root Cause Analysis'

      default:
        return ''
    }
  }

  const getButtonLink = (): string => {
    switch (currentTab) {
      case 'rootCauseAnalysis':
        return 'add'

      default:
        return ''
    }
  }

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="List of Reports"
          subHeading="Completed reports based on maintenance tasks"
          buttonText={getButtonText()}
          showButton={getButtonText() ? true : false}
          link={getButtonLink()}
        />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'checklists' && <CompletedChecklistTable data={completedChecklists} />}
            {currentTab === 'jobCards' && <JobCardTable data={jobCards} />}
            {currentTab === 'rootCauseAnalysis' && <RootCauseAnalysisTable data={[]} />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Report
