import { ChangeEvent, useState } from 'react'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const actionType = [
  {
    value: 'OK',
    label: 'OK',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Replace',
    label: 'Replace',
  },
  {
    value: 'Re-visit',
    label: 'Re-visit',
  },
]

const actionType1 = [
  {
    value: 'Primary Section',
    label: 'Primary Section',
  },
  {
    value: 'Secondary Section',
    label: 'Secondary Section',
  },
]

const failureType = [
  {
    value: 'Mechanical',
    label: 'Mechanical',
  },
  {
    value: 'Electrical',
    label: 'Electrical',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]
const severityType = [
  {
    value: 'Critical',
    label: 'Critical',
  },
  {
    value: 'Non-Critical',
    label: 'Non-Critical',
  },
]
const severityType1 = [
  {
    value: '01',
    label: '01-Production Stopped',
  },
  {
    value: '02-',
    label: '02-Mode Change',
  },
  {
    value: '03-',
    label: '03-No effect',
  },
]

const fixType = [
  {
    value: 'Operator',
    label: 'Operator to initiate repair themselves',
  },
  {
    value: 'Maintenance',
    label: 'Maintenance Personnel Required',
  },
  {
    value: 'Electrical Personnel Required',
    label: 'Electrical Personnel Required',
  },
]
const fixType1 = [
  {
    value: 'Fitter',
    label: 'Fitter',
  },
  {
    value: 'Mechanic',
    label: 'Mechanic',
  },
  {
    value: 'Electrician',
    label: 'Electrician',
  },
  {
    value: 'Diesel Mechanic',
    label: 'Diesel Mechanic',
  },
  {
    value: 'Unkown',
    label: 'Unknown',
  },
]

const EquipmentChecklistDetails = () => {
  const [severity, setSeverity] = useState<string>('')
  const [severity1, setSeverity1] = useState<string>('')
  const [failure, setFailure] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [action1, setAction1] = useState<string>('')
  const [fix, setFix] = useState<string>('')
  const [fix1, setFix1] = useState<string>('')
  const [value, setValue] = useState<Date | null>(new Date('2018-01-01T00:00:00.000Z'))
  const Input = styled('input')({ display: 'none' })

  const handleSeverityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSeverity(event.target.value as string)
  }

  const handleSeverity1Change = (event: ChangeEvent<{ value: unknown }>) => {
    setSeverity1(event.target.value as string)
  }

  const handleFailureChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSeverity(event.target.value as string)
  }

  const handleActionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAction(event.target.value as string)
  }
  const handleAction1Change = (event: ChangeEvent<{ value: unknown }>) => {
    setAction(event.target.value as string)
  }

  const handleFixChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFix(event.target.value as string)
  }

  const handleFix1Change = (event: ChangeEvent<{ value: unknown }>) => {
    setFix1(event.target.value as string)
  }

  // const handleDateChange = (date: Date | null) => {
  //   setSelectedDate(date)
  // }

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Equipment 1" subtitle="Sub Assembly" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  disabled
                  id="discharge"
                  variant="outlined"
                  label="Check tension of feed boot skirting rubber"
                />
                <TextField id="discharge" variant="outlined" label="Feedback" />
                <TextField
                  id="condition"
                  select
                  variant="outlined"
                  required
                  label="Condition"
                  value={action}
                  onChange={handleActionChange}
                >
                  {actionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="failure type"
                  select
                  variant="outlined"
                  required
                  label="Type of Failure"
                  value={failure}
                  onChange={handleFailureChange}
                >
                  {failureType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="grading"
                  select
                  variant="outlined"
                  required
                  label="Severity if failed inspection"
                  value={severity}
                  onChange={handleSeverityChange}
                >
                  {severityType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="action-to"
                  select
                  variant="outlined"
                  required
                  label="Type of action to be taken"
                  value={fix}
                  onChange={handleFixChange}
                >
                  {fixType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <label htmlFor="icon-button-file">
                  <Input accept="image/*" id="icon-button-file" type="file" />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Add Equipment Checklist
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default EquipmentChecklistDetails
