import * as React from 'react'
import { Button, Card, CardContent, Divider, Grid, TextField } from '@mui/material'
import { Box } from '@mui/system'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const JobCardAmendmentDetails = () => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Job Card Amendments" subtitle="Amend details of original job card" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField id="time" variant="outlined" required label="Over Time Required" />
                <TextField id="personnel" variant="outlined" required label="Description" />
                <TextField
                  id="personnel"
                  variant="outlined"
                  required
                  label="Extra Personnel Required"
                />
                <TextField
                  id="personnel"
                  variant="outlined"
                  type="number"
                  required
                  label="Head Count"
                />
                <TextField id="spares" variant="outlined" required label="Spares (part number)" />
                <TextField
                  id="quantity"
                  variant="outlined"
                  type="number"
                  required
                  label="Quantity"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Add Job Card Amendment
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default JobCardAmendmentDetails
