import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { countries } from 'countries-list'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { IDepartment } from '../../models/department'
import { IPermission } from '../../models/permission'
import {
  AccountHolderRelationship,
  AccountStatus,
  AccountType,
  EmploymentType,
  Gender,
  IUser,
  MaritalStatus,
  Race,
  Title,
} from '../../models/users'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllDepartments } from '../../slice/department'
import { createUser, getAllUsers, logout } from '../../slice/user'
import { getAllPermissions } from '../../slice/permission'
import { IApiError } from '../../models/helpers'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const countryOptions = Object.keys(countries).map((key) => {
  return {
    code: key.toLowerCase(),
    emoji: (countries as any)[key].emoji,
    label: (countries as any)[key].name,
  }
})

const genderOptions = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

const addressTitleOptions = [
  {
    value: 'mr',
    label: 'Mr',
  },
  {
    value: 'mrs',
    label: 'Mrs',
  },
  {
    value: 'miss',
    label: 'Miss',
  },
  {
    value: 'ms',
    label: 'Ms',
  },
  {
    value: 'dr',
    label: 'Dr',
  },
]

const maritalOptions = [
  {
    value: 'single',
    label: 'Single',
  },
  {
    value: 'married',
    label: 'Married',
  },
  {
    value: 'divorced',
    label: 'Divorced',
  },
  {
    value: 'widowed',
    label: 'Widowed',
  },
  {
    value: 'undisclosed',
    label: 'Undisclosed',
  },
  {
    value: 'life partner',
    label: 'Life Partner',
  },
]

const ethnicOptions = [
  {
    value: 'african',
    label: 'African',
  },
  {
    value: 'coloured',
    label: 'Coloured',
  },
  {
    value: 'indian',
    label: 'Indian',
  },
  {
    value: 'white',
    label: 'White',
  },
]

const employmentTypeOptions = [
  {
    value: 'permanent',
    label: 'Permanent',
  },
  {
    value: 'contract',
    label: 'Contract',
  },
]

const postalType = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'Yes',
    label: 'No',
  },
]

const nextOfKinTypeOptions = [
  {
    value: 'Spouse',
    label: 'Spouse',
  },
  {
    value: 'Child Dependant',
    label: 'Child Dependant',
  },
  {
    value: 'Child Independent',
    label: 'Child Independent',
  },
  {
    value: 'Parent',
    label: 'Parent',
  },
  {
    value: 'Grandparent',
    label: 'Grandparent',
  },
  {
    value: 'Grandchild',
    label: 'Grandchild',
  },
  {
    value: 'Not Related',
    label: 'Not Related',
  },
  {
    value: 'Brother/Sister',
    label: 'Brother/Sister',
  },
  {
    value: 'Life Partner',
    label: 'Life Partner',
  },
  {
    value: 'All other extended family',
    label: 'All other extended family',
  },
]

const disabilityOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

const qualificationType = [
  {
    value: 'Below high school/matric',
    label: 'Below high school/matric',
  },
  {
    value: 'High school diploma (matric)',
    label: 'High school diploma (matric)',
  },
  {
    value: 'Degree/Diploma (under 3 years)',
    label: 'Degree/Diploma (under 3 years)',
  },
  {
    value: 'Bachelor Degree (3years)',
    label: 'Bachelor Degree (3years)',
  },
  {
    value: 'Honours Degree (4 years)',
    label: 'Honours Degree (4 years)',
  },
  {
    value: 'Masters',
    label: 'Masters',
  },
  {
    value: 'Doctorate/PhD',
    label: 'Doctorate/PhD',
  },
  {
    value: 'Postdoctorate',
    label: 'Postdoctorate',
  },
]

const accountTypeOptions = [
  {
    value: 'savings',
    label: 'Savings',
  },
  {
    value: 'cheque',
    label: 'Cheque',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

const accountRelationTypeOptions = [
  {
    value: 'owner',
    label: 'Own',
  },
  {
    value: 'joint',
    label: 'Joint',
  },
  {
    value: '3rd party',
    label: '3rd Party',
  },
]

const schoolType = [
  {
    value: 'Primary School',
    label: 'Primary School',
  },
  {
    value: 'High School',
    label: 'High School',
  },
  {
    value: 'Technikon',
    label: 'Technikon',
  },
  {
    value: 'University',
    label: 'University',
  },
  {
    value: 'Other',
    label: 'Other (specify)',
  },
]

const UserDetails = () => {
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [cellphone, setCellphone] = useState<string>('')
  const [taxNumber, setTaxNumber] = useState<string>('')
  const [taxOffice, setTaxOffice] = useState<string>('')
  const [authority, setAuthority] = useState<string>('')
  const [department, setDepartment] = useState<string>('')
  const [nationality, setNationality] = useState<string>('')
  const [school, setSchool] = useState<string>('')
  const [qualification, setQualification] = useState<string>('')
  const [marital, setMarital] = useState<string>('')
  const [ethnicity, setEthnicity] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [disability, setDisability] = useState<string>('')
  const [addressTitle, setAddressTitle] = useState<string>('')
  const [nextOfKin, setNextOfKin] = useState<string>('')
  const [postal, setPostal] = useState<string>('')
  const [manager, setManager] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [otherGender, setOtherGender] = useState<string>('')
  const [employmentType, setEmploymentType] = useState<string>('')
  const [birthDate, setBirthDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [naturalisationDate, setNaturalisationDate] = useState<Date | null>(null)
  const [passportExpiry, setPassportExpiry] = useState<Date | null>(null)
  const [departmentOptions, setDepartmentOptions] = useState<any[]>([])
  const [authorityOptions, setAuthorityOptions] = useState<any[]>([])
  const [managerOptions, setManagerOptions] = useState<any[]>([])
  const [jobTitle, setJobTitle] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [passportNumber, setPassportNumber] = useState<string>('')
  const [homePhone, setHomePhone] = useState<string>('')
  const [workPhone, setWorkPhone] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [complexName, setComplexName] = useState<string>('')
  const [addressCountry, setAddressCountry] = useState<string>('')
  const [province, setProvince] = useState<string>('')
  const [streetName, setStreetName] = useState<string>('')
  const [streetNumber, setStreetNumber] = useState<string>('')
  const [suburb, setSuburb] = useState<string>('')
  const [unitNumber, setUnitNumber] = useState<string>('')
  const [accountType, setAccountType] = useState<string>('')
  const [accountHolder, setAccountHolder] = useState<string>('')
  const [accountHolderRelationship, setAccountHolderRelationship] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [bank, setBank] = useState<string>('')
  const [universalCode, setUniversalCode] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [date, setDate] = useState<Date | null>(null)

  const user = useAppSelector((state) => state.user).user
  const users = useAppSelector((state) => state.user).users
  const departments = useAppSelector((state) => state.department).departments
  const permissions = useAppSelector((state) => state.permission).permissions

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllPermissions({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllDepartments({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllUsers({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempAuthorityOptions: any = []
    permissions.forEach((item: IPermission) => {
      tempAuthorityOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setAuthorityOptions(tempAuthorityOptions)
  }, [permissions])

  useEffect(() => {
    const tempDepartmentOptions: any = []
    departments.forEach((item: IDepartment) => {
      tempDepartmentOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setDepartmentOptions(tempDepartmentOptions)
  }, [departments])

  useEffect(() => {
    const tempUserOptions: any = []
    users.forEach((item: IUser) => {
      tempUserOptions.push({
        value: item.id,
        label: `${item.name} ${item.surname}`,
      })
    })

    setManagerOptions(tempUserOptions)
  }, [users])

  const handleGenderChange = (event: ChangeEvent<{ value: unknown }>) => {
    setGender(event.target.value as string)
    setOtherGender('')
  }

  const handleEmploymentTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEmploymentType(event.target.value as string)
  }

  const handleAuthorityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAuthority(event.target.value as string)
  }

  const handleAccountTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAccountType(event.target.value as string)
  }

  const handleDisabledChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDisabled(event.target.value === 'Yes' ? true : false)
  }

  const handleAccountHolderRelationshipChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAccountHolderRelationship(event.target.value as string)
  }

  const handleManagerChange = (event: ChangeEvent<{ value: unknown }>) => {
    setManager(event.target.value as string)
  }

  const handlePostalChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPostal(event.target.value as string)
  }

  const handleNextOfKinChange = (event: ChangeEvent<{ value: unknown }>) => {
    // setAuthority(event.target.value as string)
  }

  const handleDepartmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDepartment(event.target.value as string)
  }

  const handleSchoolChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSchool(event.target.value as string)
  }

  const handleQualificationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setQualification(event.target.value as string)
  }

  const handleMaritalChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMarital(event.target.value as string)
  }

  const handleAddressTitleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAddressTitle(event.target.value as string)
  }

  const handleEthnicityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEthnicity(event.target.value as string)
  }

  const handleDisabilityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDisability(event.target.value as string)
  }

  const birthDateChange = (date: Date | null) => {
    setBirthDate(date)
  }

  const startDateChange = (date: Date | null) => {
    setStartDate(date)
  }

  const naturalisationDateChange = (date: Date | null) => {
    setNaturalisationDate(date)
  }

  const passportDateChange = (date: Date | null) => {
    setPassportExpiry(date)
  }

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleSurnameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSurname(event.target.value as string)
  }

  const handleEmailChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const handleCellphoneChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCellphone(event.target.value as string)
  }

  const handleHomePhoneChange = (event: ChangeEvent<{ value: unknown }>) => {
    setHomePhone(event.target.value as string)
  }

  const handleWorkPhoneChange = (event: ChangeEvent<{ value: unknown }>) => {
    setWorkPhone(event.target.value as string)
  }

  const handleTaxNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTaxNumber(event.target.value as string)
  }

  const handleTaxOfficeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTaxOffice(event.target.value as string)
  }

  const handleJobTitleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setJobTitle(event.target.value as string)
  }

  const handleIdNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setIdNumber(event.target.value as string)
  }

  const handlePassportNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPassportNumber(event.target.value as string)
  }

  const handleCityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCity(event.target.value as string)
  }

  const handleCodeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCode(event.target.value as string)
  }

  const handleComplexNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setComplexName(event.target.value as string)
  }

  const handleProvinceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setProvince(event.target.value as string)
  }

  const handleStreetNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStreetName(event.target.value as string)
  }

  const handleStreetNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStreetNumber(event.target.value as string)
  }

  const handleSuburbChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuburb(event.target.value as string)
  }

  const handleUnitNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUnitNumber(event.target.value as string)
  }

  const handleOtherGenderChange = (event: ChangeEvent<{ value: unknown }>) => {
    setOtherGender(event.target.value as string)
  }

  const handleAccountHolderChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAccountHolder(event.target.value as string)
  }

  const handleAccountNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAccountNumber(event.target.value as string)
  }

  const handleBankChange = (event: ChangeEvent<{ value: unknown }>) => {
    setBank(event.target.value as string)
  }

  const handleUniversalCodeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUniversalCode(event.target.value as string)
  }

  const onAddUser = async () => {
    const payload = {
      birthDate: birthDate as Date,
      cellphone,
      city,
      code,
      complexName,
      country: addressCountry,
      departmentId: department,
      disability: disabled ? disability : undefined,
      disabled,
      email,
      gender: gender as Gender,
      homePhone: homePhone || undefined,
      idNumber,
      jobTitle,
      maritalStatus: marital as MaritalStatus,
      name,
      managerId: manager,
      nationality,
      naturalisationDate: naturalisationDate || undefined,
      otherGender: otherGender || undefined,
      passportExpiryDate: passportExpiry || undefined,
      passportNumber,
      permissionId: authority,
      province,
      race: ethnicity as Race,
      startDate: startDate as Date,
      status: 'pending' as AccountStatus,
      streetName,
      streetNumber,
      suburb,
      surname,
      taxNumber,
      taxOffice,
      title: addressTitle as Title,
      type: employmentType as EmploymentType,
      unitNumber,
      workPhone: workPhone || undefined,
      accountHolder,
      accountHolderRelationship: accountHolderRelationship as AccountHolderRelationship,
      accountNumber,
      accountType: accountType as AccountType,
      bank,
      universalCode,
      token: user!.accessToken!,
    }

    try {
      await dispatch(createUser(payload)).unwrap()
      navigate('../manage-users/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Personal Details" subtitle="Personal details of the employee" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
                '& .MuiAutocomplete-root': { width: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="firstName"
                  variant="outlined"
                  required
                  id="firstName"
                  label="Full names"
                  autoFocus
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="lastName"
                  label="Surname"
                  name="lastName"
                  value={surname}
                  onChange={handleSurnameChange}
                />
                <MobileDatePicker
                  label="Date of Birth"
                  inputFormat="dd MMM yyyy"
                  value={birthDate}
                  onChange={birthDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  id="title"
                  select
                  variant="outlined"
                  required
                  label="Form of address (Title)"
                  onChange={handleAddressTitleChange}
                >
                  {addressTitleOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="gender"
                  select
                  variant="outlined"
                  required
                  label="Gender"
                  onChange={handleGenderChange}
                >
                  {genderOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {gender === 'other' && (
                  <TextField
                    id="otherGender"
                    variant="outlined"
                    label="What is your gender"
                    value={otherGender}
                    onChange={handleOtherGenderChange}
                  />
                )}
                <TextField
                  id="maritalStatus"
                  select
                  variant="outlined"
                  required
                  label="Marital Status"
                  onChange={handleMaritalChange}
                >
                  {maritalOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="ethnicity"
                  select
                  variant="outlined"
                  required
                  label="Ethnic Group"
                  onChange={handleEthnicityChange}
                >
                  {ethnicOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="disabled"
                  select
                  variant="outlined"
                  required
                  label="Do you have a disability?"
                  onChange={handleDisabledChange}
                >
                  {disabilityOptions.map((option) => (
                    <MenuItem key={option.label} value={option.label} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {disabled && (
                  <TextField
                    id="disability"
                    variant="outlined"
                    label="What is your disability"
                    value={disability}
                    onChange={handleDisabilityChange}
                  />
                )}
                <Autocomplete
                  id="nationality"
                  options={countryOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(event: any, newValue: any) => {
                    setNationality(newValue.code)
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.emoji} {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nationality"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {nationality === 'za' && (
                  <>
                    <MobileDatePicker
                      label="If South African by naturalization, date thereof"
                      inputFormat="dd MMM yyyy"
                      value={naturalisationDate}
                      onChange={naturalisationDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <TextField
                      id="identityNumber"
                      variant="outlined"
                      label="Identity Number"
                      required
                      value={idNumber}
                      onChange={handleIdNumberChange}
                    />
                  </>
                )}
                {nationality && nationality !== 'za' && (
                  <>
                    <TextField
                      id="passportNumber"
                      variant="outlined"
                      label="Passport Number"
                      required
                      value={passportNumber}
                      onChange={handlePassportNumberChange}
                    />
                    <MobileDatePicker
                      label="Passport Expiry Date"
                      inputFormat="dd MMM yyyy"
                      value={passportExpiry}
                      onChange={passportDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </>
                )}
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Employee Contact Details"
            subtitle="Details about the employee's contacts"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  id="contactNumber"
                  label="Cellphone number"
                  value={cellphone}
                  onChange={handleCellphoneChange}
                />
                <TextField
                  variant="outlined"
                  id="homeNumber"
                  label="Home telephone"
                  value={homePhone}
                  onChange={handleHomePhoneChange}
                />
                <TextField
                  variant="outlined"
                  id="workNumber"
                  label="Work telephone"
                  value={workPhone}
                  onChange={handleWorkPhoneChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Employee Residential Address"
            subtitle="Details about the employee's residential address"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
                '& .MuiAutocomplete-root': { width: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  id="unitNo"
                  label="Unit no."
                  value={unitNumber}
                  onChange={handleUnitNumberChange}
                />
                <TextField
                  variant="outlined"
                  id="complexName"
                  label="Complex name"
                  value={complexName}
                  onChange={handleComplexNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="streetNumber"
                  label="Street Number"
                  value={streetNumber}
                  onChange={handleStreetNumberChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="street"
                  label="Street name"
                  value={streetName}
                  onChange={handleStreetNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="suburb"
                  label="Suburb"
                  value={suburb}
                  onChange={handleSuburbChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="city"
                  label="City"
                  value={city}
                  onChange={handleCityChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="province"
                  label="Province"
                  value={province}
                  onChange={handleProvinceChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="code"
                  label="Code"
                  value={code}
                  onChange={handleCodeChange}
                />

                <Autocomplete
                  id="country"
                  options={countryOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(event: any, newValue: any) => {
                    setAddressCountry(newValue.code)
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.emoji} {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Employee Postal Address"
            subtitle="Details about the employee's postal address"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="postal-same"
                  select
                  variant="outlined"
                  required
                  label="Same as residential?"
                  onChange={handlePostalChange}
                >
                  {postalType.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="outlined"
                  type="po-box"
                  id="po-box"
                  label="P.O Box no."
                  name="po box"
                />
                <TextField variant="outlined" required id="suburb" label="Suburb" name="suburb" />
                <TextField variant="outlined" required id="city" label="City" name="city" />
                <TextField
                  variant="outlined"
                  required
                  id="province"
                  label="Province"
                  name="province"
                />
                <TextField
                  variant="outlined"
                  required
                  id="country"
                  label="Country"
                  name="country"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Emergency Contact Person"
            subtitle="Details about the employee's emergency contact person"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="firstName"
                  variant="outlined"
                  required
                  id="firstName"
                  label="First name"
                />
                <TextField
                  variant="outlined"
                  required
                  id="lastName"
                  label="Surname"
                  name="lastName"
                />
                <TextField
                  variant="outlined"
                  required
                  id="contact-number"
                  label="Cellphone number"
                  name="contact"
                />
                <TextField variant="outlined" id="unit" label="Unit no." name="unit number" />
                <TextField
                  variant="outlined"
                  id="complex"
                  label="Complex name"
                  name="complex name"
                />
                <TextField
                  variant="outlined"
                  id="street"
                  label="Street Number"
                  name="street-number"
                />
                <TextField
                  variant="outlined"
                  required
                  id="street"
                  label="Street name"
                  name="street name"
                />
                <TextField variant="outlined" required id="suburb" label="Suburb" name="suburb" />
                <TextField variant="outlined" required id="city" label="City" name="city" />
                <TextField
                  variant="outlined"
                  required
                  id="province"
                  label="Province"
                  name="province"
                />
                <TextField
                  variant="outlined"
                  required
                  id="country"
                  label="Country"
                  name="country"
                />
                <TextField variant="outlined" required id="code" label="Code" name="code" />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Employment Details"
            subtitle="Details about the employee's job and contract"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="jobTitle"
                  variant="outlined"
                  required
                  label="Job Title"
                  value={jobTitle}
                  onChange={handleJobTitleChange}
                />
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="dd MMM yyyy"
                  value={startDate}
                  onChange={startDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  id="authority"
                  select
                  variant="outlined"
                  required
                  label="Level of Authority"
                  onChange={handleAuthorityChange}
                >
                  {authorityOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="department"
                  select
                  variant="outlined"
                  required
                  label="Department"
                  onChange={handleDepartmentChange}
                >
                  {departmentOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="employmentType"
                  select
                  variant="outlined"
                  required
                  label="Employment Type"
                  onChange={handleEmploymentTypeChange}
                >
                  {employmentTypeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="manager"
                  select
                  variant="outlined"
                  required
                  label="Line Manager"
                  onChange={handleManagerChange}
                >
                  {managerOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Next of Kin"
            subtitle="Details about the employee's next of Kin"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="Name"
                  name="Next-of-keen"
                  variant="outlined"
                  required
                  label="Next of Kin's name"
                />
                <TextField
                  id="last-name"
                  variant="outlined"
                  required
                  label="Surname"
                  name="last-name"
                />
                <TextField
                  id="phone-number"
                  variant="outlined"
                  required
                  label="Contact Number"
                  name="contact number"
                />
                <TextField
                  id="nextofkin-type"
                  select
                  variant="outlined"
                  required
                  label="Relation to Next of Kin"
                  onChange={handleNextOfKinChange}
                >
                  {nextOfKinTypeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="Name"
                  name="Next-of-keen"
                  variant="outlined"
                  required
                  label="Next of Kin's name"
                />
                <TextField
                  id="last-name"
                  variant="outlined"
                  required
                  label="Surname"
                  name="last-name"
                />
                <TextField
                  id="phone-number"
                  variant="outlined"
                  required
                  label="Contact Number"
                  name="contact number"
                />
                <TextField
                  id="nextofkin-type"
                  select
                  variant="outlined"
                  required
                  label="Relation to Next of Kin"
                  value={nextOfKin}
                  onChange={handleNextOfKinChange}
                >
                  {nextOfKinTypeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Previous employment"
            subtitle="Details about the employee's employment history"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="previous-employer"
                  name="Previous employer"
                  variant="outlined"
                  label="Previous employer"
                />
                <TextField
                  id="position-held"
                  variant="outlined"
                  label="Position held"
                  name="Position held"
                />
                <TextField id="country" variant="outlined" label="Country" name="country" />
                <TextField id="city" variant="outlined" label="City" name="city" />
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={() => {}}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="End Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={() => {}}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Highest Qualification"
            subtitle="Details about the employee's qualifications"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="previous-employer"
                  name="Previous employer"
                  variant="outlined"
                  label="Previous employer"
                />
                <TextField
                  id="position-held"
                  variant="outlined"
                  label="Position held"
                  name="Position held"
                />
                <TextField id="country" variant="outlined" label="Country" name="country" />
                <TextField id="city" variant="outlined" label="City" name="city" />
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={() => {}}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="End Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={() => {}}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  id="last-school"
                  select
                  variant="outlined"
                  required
                  label="Last School Attended"
                  value={school}
                  onChange={handleSchoolChange}
                >
                  {schoolType.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="qualification"
                  select
                  variant="outlined"
                  required
                  label="Highest Qualification Obtained"
                  value={qualification}
                  onChange={handleQualificationChange}
                >
                  {qualificationType.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Banking Details"
            subtitle="Please Attach proof of banking details on institution letterhead"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="account-holder"
                  variant="outlined"
                  required
                  id="account-holder"
                  label="Name of Account Holder"
                  value={accountHolder}
                  onChange={handleAccountHolderChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="bankName"
                  label="Name of bank"
                  value={bank}
                  onChange={handleBankChange}
                />
                <TextField
                  variant="outlined"
                  required
                  id="accountNumber"
                  label="Account number"
                  value={accountNumber}
                  onChange={handleAccountNumberChange}
                />
                <TextField
                  variant="outlined"
                  id="universalCode"
                  label="Universal code"
                  value={universalCode}
                  onChange={handleUniversalCodeChange}
                />
                <TextField
                  id="bankType"
                  select
                  variant="outlined"
                  required
                  label="Account type"
                  onChange={handleAccountTypeChange}
                >
                  {accountTypeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="relationType"
                  select
                  variant="outlined"
                  required
                  label="Account holder relationship"
                  onChange={handleAccountHolderRelationshipChange}
                >
                  {accountRelationTypeOptions.map((option) => (
                    <MenuItem key={option.label} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Tax Details" subtitle="Employee's tax information" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="tax-office"
                  required
                  variant="outlined"
                  label="Tax Office"
                  value={taxOffice}
                  onChange={handleTaxOfficeChange}
                />
                <TextField
                  id="tax-number"
                  required
                  variant="outlined"
                  label="Tax Number"
                  value={taxNumber}
                  onChange={handleTaxNumberChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddUser}>
          Add User
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}

export default UserDetails
