import axios from 'axios'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IAuthSignIn {
  email: string
  password: string
}

export default class AuthRequests {
  /**
   * Authenticate a user.
   * @param data.email the user's email address.
   * @param data.password the user's password.
   * @returns the response from the server.
   */
  static signIn = async (data: IAuthSignIn) => {
    return axios.post(`${domain}/auth/login`, {
      email: data.email,
      password: data.password,
    })
  }

  /**
   * Create a user.
   * @param name is the user's name.
   * @param surname is the user's surname.
   * @param email is the user's email address.
   * @param password is the user's password.
   * @returns the response from the server.
   */
  static createAccount = async (name: string, surname: string, email: string, password: string) => {
    try {
      const response = await axios.post(`${domain}/user`, {
        email,
        name,
        password,
        surname,
      })

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
