import { ChangeEvent, useState } from 'react'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import { styled } from '@mui/material/styles'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const statusType = [
  {
    value: 'Breakdown',
    label: 'Breakdown',
  },
  {
    value: 'Mode Change',
    label: 'Mode Change',
  },
  {
    value: 'Normal Operations',
    label: 'Normal Operations',
  },
]

const actionType = [
  {
    value: 'Replace',
    label: 'Replace',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Monitor',
    label: 'Monitor',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

const priorityType = [
  {
    value: '01',
    label: '01-Immediate',
  },
  {
    value: '02- Change Daily Plan',
    label: '02- Change Daily Plan',
  },
  {
    value: '03- Change Weekly Plan',
    label: '03- Change Weekly Plan',
  },
  {
    value: '04- Change Monthly Plan',
    label: '04- Change Monthly Plan',
  },
  {
    value: '05- Change Quarterly Plan',
    label: '05- Change Quarterly Plan',
  },
  {
    value: '06- Change Yearly Plan',
    label: '06- Change Yearly Plan',
  },
  {
    value: '07- Other',
    label: '07- Other',
  },
]

const JobCardRequirementDetails = () => {
  const [status, setStatus] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [priority, setPriority] = useState<string>('')

  const Input = styled('input')({ display: 'none' })

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string)
  }

  const handleActionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAction(event.target.value as string)
  }

  const handlePriorityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPriority(event.target.value as string)
  }

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Notification Details" subtitle="Details of the notification request" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="notification-reference"
                  disabled={true}
                  variant="outlined"
                  label="345869740"
                />
                <TextField id="summary" variant="outlined" required label="Notification Summary" />
                <TextField
                  id="reference"
                  select
                  variant="outlined"
                  required
                  label="Current Status"
                  value={status}
                  onChange={handleStatusChange}
                >
                  {statusType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="affected-equipment"
                  variant="outlined"
                  required
                  label="Affected Equipment"
                />
                <TextField id="diagnosis" variant="outlined" required label="Diagnosis" />
                <TextField
                  id="failure assumptions"
                  variant="outlined"
                  required
                  label="Failure assumptions"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Action Requirements" subtitle="Enter details of action to be taken" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="reference"
                  select
                  variant="outlined"
                  required
                  label="Response Type"
                  value={action}
                  onChange={handleActionChange}
                >
                  {actionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="priority"
                  select
                  variant="outlined"
                  required
                  label="Priority"
                  value={priority}
                  onChange={handlePriorityChange}
                >
                  {priorityType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="time" variant="outlined" required label="Time Required" />
                <TextField id="personnel" variant="outlined" required label="" />
                <TextField id="personnel" variant="outlined" required label="Personnel Required" />
                <TextField
                  id="personnel"
                  variant="outlined"
                  type="number"
                  required
                  label="Head Count"
                />
                <TextField id="spares" variant="outlined" required label="Spares (part number)" />
                <TextField
                  id="quantity"
                  variant="outlined"
                  type="number"
                  required
                  label="Quantity"
                />
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <label htmlFor="icon-button-file">
                  <Input accept="image/*" id="icon-button-file" type="file" />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Add Job Card Requirements
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default JobCardRequirementDetails
