import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Snackbar, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import JobCardAmendment from './JobCardAmendment'
import JobCard from './JobCard'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllJobCards } from '../../slice/jobCard'
import { getAllJobCardAmendments } from '../../slice/jobCardAmendment'
import RoutineMaintenance from './RoutineMaintenance'
import { getAllRoutineMaintenances } from '../../slice/routineMaintenance'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'
import { getAllChecklists } from '../../slice/checklist'
import Checklist from './Checklist'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const PlannedMaintenance = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentTab, setCurrentTab] = useState<string>('plannedMaintenance')
  const [currentPlannedTab, setCurrentPlannedTab] = useState<string>('jobCards')

  const user = useAppSelector((state) => state.user).user
  const jobCards = useAppSelector((state) => state.jobCard).jobCards
  const jobCardAmendments = useAppSelector((state) => state.jobCardAmendment).jobCardAmendments
  const routineMaintenances = useAppSelector(
    (state) => state.routineMaintenance,
  ).routineMaintenances
  const checklists = useAppSelector((state) => state.checklist).checklists

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllJobCards({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllJobCardAmendments({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllRoutineMaintenances({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllChecklists({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  const tabs = [
    { value: 'plannedMaintenance', label: 'Planned Maintenance' },
    { value: 'routineMaintenance', label: 'Routine Maintenance' },
    { value: 'checklists', label: 'Checklists' },
  ]

  const plannedTabs = [
    { value: 'jobCards', label: 'Job Cards' },
    { value: 'jobCardAmendments', label: 'Job Card Amendments' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const handlePlannedTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentPlannedTab(value)
  }

  const getButtonText = (): string => {
    switch (currentTab) {
      case 'routineMaintenance':
        return 'Add Routine Maintenance'

      case 'plannedMaintenance':
        switch (currentPlannedTab) {
          case 'jobCards':
            return 'Add Job Card'

          default:
            return 'Add Job Card Amendments'
        }

      case 'checklists':
        return 'Add Checklist'

      default:
        return ''
    }
  }

  const getButtonLink = (): string => {
    switch (currentTab) {
      case 'routineMaintenance':
        return 'add-maintenance-strategy'

      case 'plannedMaintenance':
        switch (currentPlannedTab) {
          case 'jobCards':
            return 'add-maintenance-task'

          default:
            return 'add-job-card-amendment'
        }

      case 'checklists':
        return 'add-checklist'

      default:
        return ''
    }
  }

  return (
    <>
      <Helmet>
        <title>Planned Maintenance</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Planned Maintenance"
          subHeading="These are your planned maintenances."
          buttonText={getButtonText()}
          showButton={getButtonText() ? true : false}
          link={getButtonLink()}
        />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
            {currentTab === 'plannedMaintenance' && (
              <TabsWrapper
                onChange={handlePlannedTabsChange}
                value={currentPlannedTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="secondary"
                style={{ marginTop: 10 }}
              >
                {plannedTabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </TabsWrapper>
            )}
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'routineMaintenance' && (
              <RoutineMaintenance data={routineMaintenances} />
            )}
            {currentTab === 'checklists' && <Checklist data={checklists} />}
            {currentTab === 'plannedMaintenance' && currentPlannedTab === 'jobCardAmendments' && (
              <JobCardAmendment data={jobCardAmendments} />
            )}
            {currentTab === 'plannedMaintenance' && currentPlannedTab === 'jobCards' && (
              <JobCard data={jobCards} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default PlannedMaintenance
