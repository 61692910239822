import { useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Divider,
  lighten,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone'

import Hidden from '../../../../components/Hidden'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { logout } from '../../../../slice/user'

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
)

function HeaderUserbox() {
  const userState = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = {
    name: `${userState!.name} ${userState!.surname}`,
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: userState!.email,
  }

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleSignOut = async () => {
    try {
      await dispatch(logout()).unwrap()
      navigate('/')
    } catch (error) {
      // setErrorMessage(error as string)
    }
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton to="/account/profile" component={NavLink} onClick={handleClose}>
            <AccountCircleTwoToneIcon sx={{ mr: 1 }} fontSize="small" />
            <ListItemText primary="My Profile" />
          </ListItemButton>
          <ListItemButton to="/account/tasks" component={NavLink} onClick={handleClose}>
            <TableChartTwoToneIcon sx={{ mr: 1 }} fontSize="small" />
            <ListItemText primary="My Tasks" />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
