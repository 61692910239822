import axios from 'axios'

import { IJobCardPeople, IJobCardSpares, JobCardStatus } from '../models/jobCard'
import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IJobCardCreate {
  maintenanceRequestId: string
  people?: IJobCardPeople[]
  priority?: string
  responseType?: string
  spares?: IJobCardSpares[]
  startDate?: Date
  status?: JobCardStatus
  time?: number
  token: string
}

export interface IJobCardGet {
  id?: string
  token: string
}

export default class JobCardRequests {
  static getAll = (data: IJobCardGet) => {
    return axios.get(`${domain}/works-order`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IJobCardGet) => {
    return axios.get(`${domain}/works-order/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static create = (data: IJobCardCreate) => {
    return axios.post(
      `${domain}/works-order`,
      {
        maintenanceRequestId: data.maintenanceRequestId,
        people: data.people,
        priority: data.priority,
        responseType: data.responseType,
        spares: data.spares,
        startDate: data.startDate,
        status: data.status,
        time: data.time,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }
}
