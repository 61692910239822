import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function CrushingAndScreening() {
  return (
    <>
      <Helmet>
        <title>Crushing and Screening</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Crushing and Screening"
          subHeading="These are your crushing and screenings."
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default CrushingAndScreening
