import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
  MenuItem,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import Footer from '../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../store'
import { createDepartment } from '../../slice/department'
import { getAllCostCentres } from '../../slice/costCentre'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const DepartmentsDetails = () => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [costCentreId, setCostCentreId] = useState<string>('')
  const [contactNumber, setContactNumber] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [costCentreOptions, setCostCentreOptions] = useState<any[]>([])

  const user = useAppSelector((state) => state.user).user
  const costCentres = useAppSelector((state) => state.costCentre).costCentres

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllCostCentres({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempCostCentreOptions: any = []
    costCentres.forEach((item) => {
      tempCostCentreOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setCostCentreOptions(tempCostCentreOptions)
  }, [costCentres])

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleEmailChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const handleCostCentreChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCostCentreId(event.target.value as string)
  }

  const handleContactNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setContactNumber(event.target.value as string)
  }

  const onAddDepartment = async () => {
    const payload = {
      contactNumber,
      costCentreId,
      token: user!.accessToken!,
      description,
      email,
      name,
    }

    try {
      await dispatch(createDepartment(payload)).unwrap()
      navigate('../departments/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Create Department" subtitle="Add department details below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="Name"
                  variant="outlined"
                  required
                  id="Name"
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <TextField
                  name="Contact Number"
                  variant="outlined"
                  id="contact-number"
                  label="Contact Number"
                  value={contactNumber}
                  onChange={handleContactNumberChange}
                />
                <TextField
                  name="email"
                  variant="outlined"
                  id="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Select Cost Centre"
                  onChange={handleCostCentreChange}
                >
                  {costCentreOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
        <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
          <div />
          <Button variant="contained" onClick={onAddDepartment}>
            Create Department
          </Button>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}

export default DepartmentsDetails
