import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import CustomCardHeader from '../../components/CustomCardHeader'
import { getAllEquipment } from '../../slice/equipment'
import { useAppDispatch, useAppSelector } from '../../store'
import { IApiError, IMenuItem } from '../../models/helpers'
import { logout } from '../../slice/user'
import { FrequencyType, IServiceItem } from '../../models/routineMaintenance'
import { createRoutineMaintenance } from '../../slice/routineMaintenance'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const frequencyType = [
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'months',
    label: 'Months',
  },
  {
    value: 'years',
    label: 'Years',
  },
]

const RoutineMaintenanceDetails = () => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [equipmentOptions, setEquipmentOptions] = useState<IMenuItem[]>([])
  const [selectedEquipment, setSelectedEquipment] = useState<IMenuItem[]>([])
  const [serviceItems, setServiceItems] = useState<IServiceItem[]>([
    {
      id: uuidv4(),
      description: '',
      frequency: frequencyType[0].value as FrequencyType,
      name: '',
      value: 1,
    },
  ])

  const user = useAppSelector((state) => state.user).user
  const equipment = useAppSelector((state) => state.equipment).equipment

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllEquipment({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempEquipmentOptions: any = []
    equipment.forEach((item) => {
      tempEquipmentOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setEquipmentOptions(tempEquipmentOptions)
  }, [equipment])

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const onAddServiceItem = () => {
    const tempServiceItems = [...serviceItems]
    tempServiceItems.push({
      id: uuidv4(),
      description: '',
      frequency: frequencyType[0].value as FrequencyType,
      name: '',
      value: 1,
    })

    setServiceItems(tempServiceItems)
  }

  const handleServiceItemFrequencyChange = (id: string, frequency: FrequencyType) => {
    const tempServiceItems = [...serviceItems]

    for (let i = 0; i < tempServiceItems.length; i++) {
      if (tempServiceItems[i].id === id) {
        tempServiceItems[i].frequency = frequency
        break
      }
    }

    setServiceItems(tempServiceItems)
  }

  const handleServiceItemNameChange = (id: string, name: string) => {
    const tempServiceItems = [...serviceItems]

    for (let i = 0; i < tempServiceItems.length; i++) {
      if (tempServiceItems[i].id === id) {
        tempServiceItems[i].name = name
        break
      }
    }

    setServiceItems(tempServiceItems)
  }

  const handleServiceItemDescriptionChange = (id: string, description: string) => {
    const tempServiceItems = [...serviceItems]

    for (let i = 0; i < tempServiceItems.length; i++) {
      if (tempServiceItems[i].id === id) {
        tempServiceItems[i].description = description
        break
      }
    }

    setServiceItems(tempServiceItems)
  }

  const handleServiceItemValueChange = (id: string, value: number) => {
    const tempServiceItems = [...serviceItems]

    for (let i = 0; i < tempServiceItems.length; i++) {
      if (tempServiceItems[i].id === id) {
        tempServiceItems[i].value = value
        break
      }
    }

    setServiceItems(tempServiceItems)
  }

  const onAddRoutineMaintenance = async () => {
    const payload = {
      equipmentIds: selectedEquipment.map((item) => item.value),
      token: user!.accessToken!,
      description,
      name,
      serviceItems,
    }

    try {
      await dispatch(createRoutineMaintenance(payload)).unwrap()
      navigate('../planned-maintenance/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Task details" subtitle="Enter details of the tasks location" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
                '& .MuiAutocomplete-root': { width: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField required label="Task Name" value={name} onChange={handleNameChange} />
              <TextField
                required
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
              />
              <Autocomplete
                multiple
                limitTags={2}
                options={equipmentOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => setSelectedEquipment(newValue)}
                renderInput={(params) => <TextField {...params} required label="Equipment" />}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Add Items to be Serviced"
            subtitle="Add details for the items that need to be serviced for this equipment"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              {serviceItems.map((serviceItem, index) => (
                <>
                  <TextField
                    required
                    label="Item name"
                    value={serviceItem.name}
                    onChange={(event) =>
                      handleServiceItemNameChange(serviceItem.id, event.target.value)
                    }
                  />
                  <TextField
                    required
                    label="Description"
                    value={serviceItem.description}
                    onChange={(event) =>
                      handleServiceItemDescriptionChange(serviceItem.id, event.target.value)
                    }
                  />
                  <TextField
                    select
                    required
                    label="Frequency"
                    value={serviceItem.frequency}
                    onChange={(event) =>
                      handleServiceItemFrequencyChange(
                        serviceItem.id,
                        event.target.value as FrequencyType,
                      )
                    }
                  >
                    {frequencyType.map((option) => (
                      <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    type="number"
                    label="Value"
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{serviceItem.frequency}</InputAdornment>
                      ),
                    }}
                    value={serviceItem.value}
                    onChange={(event) =>
                      handleServiceItemValueChange(serviceItem.id, parseInt(event.target.value))
                    }
                  />
                  {index !== serviceItems.length - 1 && <Divider sx={{ my: 2 }} variant="middle" />}
                </>
              ))}
            </Box>
          </CardContent>
          <Grid sx={{ pr: 2, pb: 2 }} container justifyContent="flex-end">
            <Button variant="contained" onClick={onAddServiceItem}>
              Add Item
            </Button>
          </Grid>
        </Card>
      </Grid>
      <Grid sx={{ my: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddRoutineMaintenance}>
          Create Routine Maintenance
        </Button>
      </Grid>
    </>
  )
}

export default RoutineMaintenanceDetails
