import { ChangeEvent, useState } from 'react'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const referenceType = [
  {
    value: 'Breakdown on Conveyor Belt',
    label: 'Conveyor Belt BreakDown',
  },
]

const statusType = [
  {
    value: 'Breakdown',
    label: 'Breakdown',
  },
  {
    value: 'Mode Change',
    label: 'Mode Change',
  },
  {
    value: 'Normal Operations',
    label: 'Normal Operations',
  },
]

const equipment1Type = [
  {
    value: 'V Belt',
    label: 'V Belt',
  },
]

const actionType = [
  {
    value: 'Replace',
    label: 'Replace',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Monitor',
    label: 'Monitor',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

const priorityType = [
  {
    value: '01',
    label: '01-Immediate',
  },
  {
    value: '02- Change Daily Plan',
    label: '02- Change Daily Plan',
  },
  {
    value: '03- Change Weekly Plan',
    label: '03- Change Weekly Plan',
  },
  {
    value: '04- Change Monthly Plan',
    label: '04- Change Monthly Plan',
  },
  {
    value: '05- Change Quarterly Plan',
    label: '05- Change Quarterly Plan',
  },
  {
    value: '06- Change Yearly Plan',
    label: '06- Change Yearly Plan',
  },
  {
    value: '07- Other',
    label: '07- Other',
  },
]

const maintenanceType = [
  {
    value: 'Resposibility',
    label: 'Resposibility',
  },
  {
    value: 'Flow-Sheet',
    label: 'Flow-Sheet',
  },
  {
    value: 'New/Change Task List',
    label: 'New/Change Task List',
  },
  {
    value: 'Works Instructions',
    label: 'Works Instructions',
  },
  {
    value: 'Operator Inspection Standards',
    label: 'Operator Inspection Standards',
  },
  {
    value: 'Training',
    label: 'Training',
  },
]

const qualityType = [
  {
    value: 'Training',
    label: 'Training',
  },
  {
    value: 'Quality Output',
    label: 'Quality Output',
  },
  {
    value: 'Quality Input',
    label: 'Quality Input',
  },
  {
    value: 'Works Instructions',
    label: 'Works Instructions',
  },
  {
    value: 'Resources',
    label: 'Resources',
  },
]

const storesType = [
  {
    value: 'Retrieval System',
    label: 'Retrieval System',
  },
  {
    value: 'New Stock Item',
    label: 'New Stock Item',
  },
  {
    value: 'QC Part Inspection',
    label: 'QC Part Inspection',
  },
  {
    value: 'Reorder Level',
    label: 'Reorder Level',
  },
  {
    value: 'Bin Level',
    label: 'Bin Level',
  },
]

const informationType = [
  {
    value: 'Supplier Manuals',
    label: 'Supplier Manuals',
  },
  {
    value: 'Machine Drawings',
    label: 'Machine Drawings',
  },
  {
    value: 'Procedures',
    label: 'Procedures',
  },
  {
    value: 'Training Manuals',
    label: 'Training Manuals',
  },
]

const equipmentType = [
  {
    value: 'Modifications',
    label: 'Modifications',
  },
  {
    value: 'Change Log',
    label: 'Change Log',
  },
  {
    value: 'Similar Equipment That Needs Correcting',
    label: 'Similar Equipment That Needs Correcting',
  },
  {
    value: 'Secondary Effects',
    label: 'Secondary Effects',
  },
  {
    value: 'Effects That Might Appear Late',
    label: 'Effects That Might Appear Late',
  },
]

const planningType = [
  {
    value: 'No Written Plan',
    label: 'No Written Plan',
  },
  {
    value: 'Communication of Plan',
    label: 'Communication of Plan',
  },
  {
    value: 'Plan not Followed',
    label: 'Plan not Followed',
  },
]

const ReportDetails = () => {
  const [reference, setReference] = useState<string>('')
  const [equipment1, setEquipment1] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [priority, setPriority] = useState<string>('')
  const [maintenance, setMaintenance] = useState<string>('')
  const [quality, setQuality] = useState<string>('')
  const [stores, setStores] = useState<string>('')
  const [information, setInformation] = useState<string>('')
  const [equipment, setEquipment] = useState<string>('')
  const [planning, setPlanning] = useState<string>('')

  const Input = styled('input')({ display: 'none' })

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  const [value, setValue] = useState<Date | null>(new Date())

  const handleReferenceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setReference(event.target.value as string)
  }

  const handleEquipment1Change = (event: ChangeEvent<{ value: unknown }>) => {
    setReference(event.target.value as string)
  }

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string)
  }

  const handleActionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAction(event.target.value as string)
  }

  const handlePriorityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPriority(event.target.value as string)
  }

  const handleMaintenanceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenance(event.target.value as string)
  }

  const handleQualityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setQuality(event.target.value as string)
  }

  const handleStoresChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStores(event.target.value as string)
  }

  const handleInformationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setInformation(event.target.value as string)
  }

  const handleEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipment(event.target.value as string)
  }

  const handlePlanningChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPlanning(event.target.value as string)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Notification Details" subtitle="Details of the notification request" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="notification-reference"
                  disabled={true}
                  variant="outlined"
                  label="345869740"
                />
                <TextField id="summary" variant="outlined" required label="Notification Summary" />
                <TextField
                  id="reference"
                  select
                  variant="outlined"
                  required
                  label="Current Status"
                  value={status}
                  onChange={handleStatusChange}
                >
                  {statusType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="affected-equipment"
                  variant="outlined"
                  required
                  label="Affected Equipment"
                />
                <TextField id="diagnosis" variant="outlined" required label="Diagnosis" />
                <TextField
                  id="failure assumptions"
                  variant="outlined"
                  required
                  label="Failure assumptions"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Action Requirements" subtitle="Enter details of action to be taken" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="reference"
                  select
                  variant="outlined"
                  required
                  label="Response Type"
                  value={action}
                  onChange={handleActionChange}
                >
                  {actionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="priority"
                  select
                  variant="outlined"
                  required
                  label="Priority"
                  value={priority}
                  onChange={handlePriorityChange}
                >
                  {priorityType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="time" variant="outlined" required label="Time Required" />
                <TextField id="personnel" variant="outlined" required label="" />
                <TextField id="personnel" variant="outlined" required label="Personnel Required" />
                <TextField
                  id="personnel"
                  variant="outlined"
                  type="number"
                  required
                  label="Head Count"
                />
                <TextField id="spares" variant="outlined" required label="Spares (part number)" />
                <TextField
                  id="quantity"
                  variant="outlined"
                  type="number"
                  required
                  label="Quantity"
                />
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <label htmlFor="icon-button-file">
                  <Input accept="image/*" id="icon-button-file" type="file" />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Job Card Amendments" subtitle="Amend details of original job card" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField id="time" variant="outlined" required label="Over Time Required" />
                <TextField id="personnel" variant="outlined" required label="Description" />
                <TextField
                  id="personnel"
                  variant="outlined"
                  required
                  label="Extra Personnel Required"
                />
                <TextField
                  id="personnel"
                  variant="outlined"
                  type="number"
                  required
                  label="Head Count"
                />
                <TextField id="spares" variant="outlined" required label="Spares (part number)" />
                <TextField
                  id="quantity"
                  variant="outlined"
                  type="number"
                  required
                  label="Quantity"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Route Cause Analysis"
            subtitle="Detailed cause and preventative action"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="notification-reference"
                  variant="outlined"
                  disabled={true}
                  required
                  label="Job Card Number"
                />
                <TextField id="root-cause" variant="outlined" required label="Root-Cause" />
                <TextField
                  id="findings"
                  variant="outlined"
                  required
                  label="Findings From Verification"
                />
                <TextField
                  id="correction"
                  variant="outlined"
                  required
                  label="Corrective Action Taken"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Root Cause Analysis" subtitle="Loop Closing Items" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="maintenance"
                  select
                  variant="outlined"
                  required
                  label="Maintenance"
                  value={maintenance}
                  onChange={handleMaintenanceChange}
                >
                  {maintenanceType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
                <TextField
                  id="quality"
                  select
                  variant="outlined"
                  required
                  label="Quality"
                  value={quality}
                  onChange={handleQualityChange}
                >
                  {qualityType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
                <TextField
                  id="stores"
                  select
                  variant="outlined"
                  required
                  label="Stores"
                  value={stores}
                  onChange={handleStoresChange}
                >
                  {storesType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
                <TextField
                  id="information"
                  select
                  variant="outlined"
                  required
                  label="Information"
                  value={information}
                  onChange={handleInformationChange}
                >
                  {informationType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
                <TextField
                  id="equipment-rca"
                  select
                  variant="outlined"
                  required
                  label="Equipment RCA"
                  value={equipment}
                  onChange={handleEquipmentChange}
                >
                  {equipmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
                <TextField
                  id="planning"
                  select
                  variant="outlined"
                  required
                  label="Planning"
                  value={planning}
                  onChange={handlePlanningChange}
                >
                  {planningType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="comment" variant="outlined" required label="Comment" />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained">Generate Report</Button>
      </Grid>
      <Footer />
    </>
  )
}

export default ReportDetails
