import { FC, ChangeEvent, useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CardHeader,
} from '@mui/material'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

import { AccountStatus } from '../../models/users'
import { NavLink } from 'react-router-dom'

interface ProductionModesTableProps {
  className?: string
  productionModes: any[]
}

interface Filters {
  status: AccountStatus | null
}

const applyFilters = (productionModes: any[], filters: Filters): any[] => {
  return productionModes.filter((user) => {
    let matches = true

    if (filters.status && user.status !== filters.status) {
      matches = false
    }

    return matches
  })
}

const applyPagination = (productionModes: any[], page: number, limit: number): any[] => {
  return productionModes.slice(page * limit, page * limit + limit)
}

const ProductionModesTable: FC<ProductionModesTableProps> = ({ productionModes }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [filters, setFilters] = useState<Filters>({
    status: null,
  })

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const filteredProductionModes = applyFilters(productionModes, filters)
  const paginatedProductionModes = applyPagination(filteredProductionModes, page, limit)
  const theme = useTheme()

  return (
    <Card>
      <CardHeader title="All Production Modes" />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Activity Area</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProductionModes.map((productionMode) => {
              return (
                <TableRow hover key={productionMode.id}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {productionMode.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {format(new Date(productionMode.createdAt), 'MMMM dd yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {productionMode.functionalLocation.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {productionMode.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Mode" arrow>
                      <IconButton
                        component={NavLink}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        size="small"
                        to="view"
                        state={{ productionMode }}
                      >
                        <VisibilityTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Mode" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main,
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredProductionModes.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  )
}

ProductionModesTable.propTypes = {
  productionModes: PropTypes.array.isRequired,
}

ProductionModesTable.defaultProps = {
  productionModes: [],
}

export default ProductionModesTable
