import { ChangeEvent, forwardRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardContent, Divider, Grid, Snackbar, TextField } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import CustomCardHeader from '../../components/CustomCardHeader'
import RawMaterialRequests from '../../server/rawMaterial'
import Footer from '../../components/Footer'
import { useAppSelector } from '../../store'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const RawMaterialsDetails = () => {
  const userState = useAppSelector((state) => state.user).user
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [quantityAvailable, setQuantityAvailable] = useState<number>(0)
  const [yearToExplore, setYearToExplore] = useState<number>(0)

  const navigate = useNavigate()

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleQuantityAvailableChange = (event: ChangeEvent<{ value: unknown }>) => {
    setQuantityAvailable(event.target.value as number)
  }

  const handleYearToExploreChange = (event: ChangeEvent<{ value: unknown }>) => {
    setYearToExplore(event.target.value as number)
  }

  const onAddRawMaterial = async () => {
    const payload = {
      createdBy: userState!.id,
      name,
      quantityAvailable,
      yearToExplore,
    }

    const response = await RawMaterialRequests.create(payload)

    if (response.status === 201) {
      return navigate('../raw-materials/')
    }

    setErrorMessage(response.data.error.message)
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader
            title="Raw Materials Details"
            subtitle="Edit details about available raw materials"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Raw Material Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  variant="outlined"
                  type="number"
                  required
                  label="Quantity Available"
                  value={quantityAvailable}
                  onChange={handleQuantityAvailableChange}
                />
                <TextField
                  variant="outlined"
                  type="number"
                  required
                  label="Years to explore"
                  value={yearToExplore}
                  onChange={handleYearToExploreChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
        <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
          <div />
          <Button variant="contained" onClick={onAddRawMaterial}>
            Create Raw Material
          </Button>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}

export default RawMaterialsDetails
