import axios from 'axios'

import { ShiftBreakType } from '../models/shift'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

interface IShiftCreate {
  breakStart: string
  breakEnd: string
  breakPayType: ShiftBreakType
  createdBy: string
  endTime: string
  name: string
  shiftAllowance: boolean
  shiftAllowanceRate: number
  startTime: string
}

export default class ShiftRequests {
  static create = async (data: IShiftCreate) => {
    try {
      const response = await axios.post(`${domain}/shift`, {
        breakStart: data.breakStart,
        breakEnd: data.breakEnd,
        breakPayType: data.breakPayType,
        createdBy: data.createdBy,
        endTime: data.endTime,
        name: data.name,
        shiftAllowance: data.shiftAllowance,
        shiftAllowanceRate: data.shiftAllowanceRate,
        startTime: data.startTime,
      })

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }

  static getAll = async () => {
    try {
      const response = await axios.get(`${domain}/shift`)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
