import { Navigate } from 'react-router-dom'

import { useAppSelector } from '../../store'

interface RequireAuthProps {
  children: any
}
 
const RequireAuth = ({ children }: RequireAuthProps) => {
  const userState = useAppSelector((state) => state.user).user

  return userState ? children : <Navigate to="/signin" replace />
}

export default RequireAuth
