import { ChangeEvent, FC, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { IJobCard } from '../../models/jobCard'

const superEquipmentType = [
  {
    value: 'Conveyer Belt',
    label: 'Conveyer Belt',
  },
  {
    value: 'Smelter Dish',
    label: 'Smelter Dish',
  },
  {
    value: 'Hydraulic Shafts',
    label: 'Hydraulic Shafts',
  },
  {
    value: 'SA 30',
    label: 'SA 30 X 42 Jaw Crusher',
  },
]

const subAssemblyType = [
  {
    value: 'Sub 1',
    label: 'Sub 1',
  },
  {
    value: 'Sub 2',
    label: 'Sub 2',
  },
]

const superiorType = [
  {
    value: 'Primary Section',
    label: 'Primary Section',
  },
  {
    value: 'Secondary Section',
    label: 'Secondary Section',
  },
]

const maintenanceType = [
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Replacement',
    label: 'Replacement',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Inspection',
    label: 'Inspection',
  },
  {
    value: 'General Maintenance',
    label: 'General Maintenance',
  },
]

const mainTriggerType = [
  {
    value: 'Run Time',
    label: 'Run Time',
  },
  {
    value: 'Production Output',
    label: 'Production Output',
  },
  {
    value: 'Fixed',
    label: 'Fixed',
  },
]

const schedulingType = [
  {
    value: 'Runtime',
    label: 'Runtime',
  },
  {
    value: 'Cycle',
    label: 'Cycle',
  },
  {
    value: 'Production Output',
    label: 'Production Output',
  },
  {
    value: 'Set Date',
    label: 'Set Date',
  },
  {
    value: 'Set Time',
    label: 'Set Time',
  },
]

const productionType = [
  {
    value: 'Production Stopped',
    label: 'Production Stopped',
  },
  {
    value: 'Reduced Efficiency',
    label: 'Production Output Reduced',
  },
  {
    value: 'Production Normal',
    label: 'Production Normal',
  },
]

const maintenanceGroupType = [
  {
    value: 'Mechanical',
    label: 'Mechanical',
  },
  {
    value: 'Electrical',
    label: 'Electrical',
  },
  {
    value: 'All',
    label: 'All',
  },
]

const authorizingType = [
  {
    value: 'Supervisor',
    label: 'Supervisor',
  },
  {
    value: 'Head of Engineering',
    label: 'Head of Engineering',
  },
]

const subEquipmentType = [
  {
    value: 'Motor And GearBox',
    label: 'Discharge Point',
  },
  {
    value: 'Drum and Idlers',
    label: 'Drun and Idlers',
  },
]

const mainType = [
  {
    value: 'Routine',
    label: 'Routine',
  },
  {
    value: 'Preventative',
    label: 'Preventative',
  },
]

interface WorksOrderProps {
  data: IJobCard
}

const JobCardDetails: FC<WorksOrderProps> = ({ data }) => {
  const [superEquipment, setSuperEquipment] = useState<string>('')
  const [subAssembly, setSubAssembly] = useState<string>('')
  const [superior, setSuperior] = useState<string>('')
  const [maintenance, setMaintenance] = useState<string>('')
  const [mainTrigger, setMainTrigger] = useState<string>('')
  const [scheduling, setScheduling] = useState<string>('')
  const [production, setProduction] = useState<string>('')
  const [maintenanceGroup, setMaintenanceGroup] = useState<string>('')
  const [authorizing, setAuthorizing] = useState<string>('')
  const [subEquipment, setSubEquipment] = useState<string>('')
  const [main, setMain] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const [value, setValue] = useState<Date | null>(new Date())
  const [value2, setValue2] = useState<Date | null>(new Date())

  const handleSuperEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperEquipment(event.target.value as string)
  }
  const handleSuperiorChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperior(event.target.value as string)
  }

  const handleSubAssemblyChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubAssembly(event.target.value as string)
  }

  const handleMaintenanceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenance(event.target.value as string)
  }
  const handleMainTriggerChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMainTrigger(event.target.value as string)
  }
  const handleProductionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setProduction(event.target.value as string)
  }
  const handleMaintenanceGroupChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenanceGroup(event.target.value as string)
  }
  const handleAuthorizingChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAuthorizing(event.target.value as string)
  }
  const handleSubEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubEquipment(event.target.value as string)
  }

  const handleMainChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMain(event.target.value as string)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Maintenance Scheduling Request" subtitle="Maintenance approvals" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="works order number"
                  disabled
                  variant="outlined"
                  label="Job Card Number"
                  value={data.worksOrderNumber}
                />
                <TextField
                  id="summary"
                  disabled
                  variant="outlined"
                  label="Job Card Summary"
                  value={data.summary}
                />
                <TextField
                  id="details"
                  disabled
                  variant="outlined"
                  label="Job Card Details"
                  value={data.diagnosis}
                />
                <TextField
                  id="priority"
                  disabled
                  variant="outlined"
                  label="Priority"
                  value={data.priority}
                />
              </div>
              <MobileDatePicker
                renderInput={(props) => <TextField {...props} />}
                label="Schedule Start Date/Time"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue)
                }}
              />
              <MobileDatePicker
                renderInput={(props) => <TextField {...props} />}
                label="Schedule End Date/Time "
                value={value2}
                onChange={(newValue) => {
                  setValue2(newValue)
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Approve Job Card
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}

export default JobCardDetails
