import { FC, ChangeEvent, useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  SelectChangeEvent,
} from '@mui/material'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'

import Label from '../../components/Label'
import { IUser, AccountStatus } from '../../models/users'
import { toTitleCase } from '../../utilities/Helpers'
import { NavLink } from 'react-router-dom'

interface ManageUsersTableProps {
  className?: string
  users: IUser[]
}

interface Filters {
  status: AccountStatus | null
}

const getStatusLabel = (accountStatus: AccountStatus): JSX.Element => {
  const map = {
    deactivated: {
      text: 'Deactivated',
      color: 'error',
    },
    active: {
      text: 'Active',
      color: 'success',
    },
    pending: {
      text: 'Pending',
      color: 'warning',
    },
  }

  const { text, color }: any = map[accountStatus]

  return <Label color={color}>{text}</Label>
}

const applyFilters = (users: IUser[], filters: Filters): IUser[] => {
  return users.filter((user) => {
    let matches = true

    if (filters.status && user.status !== filters.status) {
      matches = false
    }

    return matches
  })
}

const applyPagination = (users: IUser[], page: number, limit: number): IUser[] => {
  return users.slice(page * limit, page * limit + limit)
}

const ManageUsersTable: FC<ManageUsersTableProps> = ({ users }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [filters, setFilters] = useState<Filters>({
    status: null,
  })

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'deactivated',
      name: 'Deactivated',
    },
  ]

  const handleStatusChange = (e: SelectChangeEvent<string>): void => {
    let value: AccountStatus | null = null

    if (e.target.value !== 'all') {
      value = e.target.value as AccountStatus
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }))
  }

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const filteredUsers = applyFilters(users, filters)
  const paginatedUsers = applyPagination(filteredUsers, page, limit)
  const theme = useTheme()

  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status || 'all'}
                onChange={(event) => handleStatusChange(event)}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title={`${toTitleCase(filters.status || '') || 'All'} Users`}
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Permission</TableCell>
              <TableCell align="center">Department</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => {
              return (
                <TableRow hover key={user.id}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {`${user.name} ${user.surname}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {format(new Date(user.createdAt), 'MMMM dd yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user.permission.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user.department ? user.department.name : ''}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {toTitleCase(user.type)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{getStatusLabel(user.status)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="View User" arrow>
                      <IconButton
                        component={NavLink}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        size="small"
                        to={user.id}
                      >
                        <VisibilityTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main,
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredUsers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  )
}

ManageUsersTable.propTypes = {
  users: PropTypes.array.isRequired,
}

ManageUsersTable.defaultProps = {
  users: [],
}

export default ManageUsersTable
