import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Snackbar, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import { IEquipment } from '../../models/equipment'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllEquipment } from '../../slice/equipment'
import { getAllBoms } from '../../slice/bom'
import EquipmentTable from './EquipmentTable'
import BomTable from './BomTable'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const Equipment = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentTab, setCurrentTab] = useState<string>('equipments')
  const [spares, setSpares] = useState<IEquipment[]>([])

  const user = useAppSelector((state) => state.user).user
  const equipment = useAppSelector((state) => state.equipment).equipment
  const boms = useAppSelector((state) => state.bom).boms

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllEquipment({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllBoms({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempSpares: IEquipment[] = []

    equipment.forEach((item: IEquipment) => {
      if (item.costCentre.name === 'on hold') {
        tempSpares.push(item)
        return
      }
    })
    setSpares(tempSpares)
  }, [equipment])

  const tabs = [
    { value: 'equipments', label: 'Equipment' },
    { value: 'spares', label: 'Spares' },
    { value: 'boms', label: 'BOMs' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const getButtonText = (): string => {
    switch (currentTab) {
      case 'spares':
        return 'Add Spare'

      case 'boms':
        return 'Add BOM'

      default:
        return 'Add Equipment'
    }
  }

  const getButtonLink = (): string => {
    switch (currentTab) {
      case 'spares':
        return 'add-equipment'

      case 'boms':
        return 'add-bom'

      default:
        return 'add-equipment'
    }
  }

  return (
    <>
      <Helmet>
        <title>Equipment</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Equipment"
          subHeading="List of all added equipment"
          buttonText={getButtonText()}
          showButton
          link={getButtonLink()}
        />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'equipments' && <EquipmentTable data={equipment} />}
            {currentTab === 'spares' && <EquipmentTable data={spares} />}
            {currentTab === 'boms' && <BomTable data={boms} />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Equipment
