import { ChangeEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import Primary from './Primary'
import Secondary from './Secondary'
import Tertiary from './Tertiary'
import StockpileRequests from '../../server/stockpiles'
import { IStockpile } from '../../models/stockpiles'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

function ProductionStockpiles() {
  const [currentTab, setCurrentTab] = useState<string>('primary')
  const [primaryStockpiles, setPrimaryStockpiles] = useState<IStockpile[]>([])
  const [secondaryStockpiles, setSecondaryStockpiles] = useState<IStockpile[]>([])
  const [tertiaryStockpiles, setTertiaryStockpiles] = useState<IStockpile[]>([])

  useEffect(() => {
    // Reset the state of redux
    getStockpiles()

    return () => {}
  }, [])

  const getStockpiles = async () => {
    const primaryResponse = await StockpileRequests.getAll('primary', 'production')
    const secondaryResponse = await StockpileRequests.getAll('secondary', 'production')
    const tertiaryResponse = await StockpileRequests.getAll('tertiary', 'production')

    if (primaryResponse.status === 200) {
      setPrimaryStockpiles(primaryResponse.data)
    }

    if (secondaryResponse.status === 200) {
      setSecondaryStockpiles(secondaryResponse.data)
    }

    if (primaryResponse.status === 200) {
      setTertiaryStockpiles(tertiaryResponse.data)
    }
  }

  const tabs = [
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'tertiary', label: 'Tertiary' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const getButtonText = (value: string): string => {
    switch (value) {
      case 'secondary':
        return 'Add Secondary Stockpile'
      case 'tertiary':
        return 'Add Tertiary Stockpile'

      default:
        return 'Add Primary Stockpile'
    }
  }

  return (
    <>
      <Helmet>
        <title>Stockpiles</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Stockpiles"
          subHeading="These are your stockpiles."
          buttonText={getButtonText(currentTab)}
          showButton
          link="add"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'primary' && <Primary stockpiles={primaryStockpiles} />}
            {currentTab === 'secondary' && <Secondary stockpiles={secondaryStockpiles} />}
            {currentTab === 'tertiary' && <Tertiary stockpiles={tertiaryStockpiles} />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ProductionStockpiles
