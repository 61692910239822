import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IPermissionCreate {
  description: string
  name: string
  permissions: any
  token: string
}

export interface IPermissionGet {
  id?: string
  token: string
}

export default class PermissionRequests {
  static create = (data: IPermissionCreate) => {
    return axios.post(
      `${domain}/permission`,
      {
        description: data.description,
        name: data.name,
        permissions: data.permissions,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IPermissionGet) => {
    return axios.get(`${domain}/permission`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IPermissionGet) => {
    return axios.get(`${domain}/permission/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
