import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IDepartmentCreate {
  costCentreId: string
  email: string
  description: string
  name: string
  contactNumber?: string
  token: string
}

export interface IDepartmentGet {
  id?: string
  token: string
}

export default class DepartmentRequests {
  static create = (data: IDepartmentCreate) => {
    return axios.post(
      `${domain}/department`,
      {
        costCentreId: data.costCentreId,
        contactNumber: data.contactNumber,
        description: data.description,
        email: data.email,
        name: data.name,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IDepartmentGet) => {
    return axios.get(`${domain}/department`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IDepartmentGet) => {
    return axios.get(`${domain}/department/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
