import { Card } from '@mui/material'
import { FC } from 'react'

import { IStockpile } from '../../models/stockpiles'
import StockpilesTable from './StockpilesTable'

interface SecondaryProps {
  stockpiles: IStockpile[]
}

const Secondary: FC<SecondaryProps> = ({ stockpiles }) => {
  return (
    <Card>
      <StockpilesTable stockpiles={stockpiles} />
    </Card>
  )
}

export default Secondary
