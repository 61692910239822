import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function ProductionOutput() {
  return (
    <>
      <Helmet>
        <title>Planned Maintenance</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Production Output"
          subHeading="Production output for the day."
          buttonText="Record Production Output"
          showButton
          link ='add'
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default ProductionOutput
