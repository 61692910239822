import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IJobCardAmendment } from '../models/jobCardAmendment'
import JobCardAmendmentRequests, {
  IJobCardAmendmentCreate,
  IJobCardAmendmentGet,
} from '../server/jobCardAmendment'
import { formatApiError } from '../utilities/Helpers'

interface IJobCardAmendmentState {
  selectedJobCardAmendment: IJobCardAmendment | null
  jobCardAmendments: IJobCardAmendment[]
}

export const getJobCardAmendment = createAsyncThunk(
  'jobCardAmendment/getJobCardAmendment',
  async (data: IJobCardAmendmentGet, thunkAPI) => {
    try {
      const response = await JobCardAmendmentRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllJobCardAmendments = createAsyncThunk(
  'jobCardAmendment/getAllJobCardAmendments',
  async (data: IJobCardAmendmentGet, thunkAPI) => {
    try {
      const response = await JobCardAmendmentRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createJobCardAmendment = createAsyncThunk(
  'jobCardAmendment/createJobCardAmendment',
  async (data: IJobCardAmendmentCreate, thunkAPI) => {
    try {
      await JobCardAmendmentRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearJobCardAmendment = createAsyncThunk(
  'jobCardAmendment/clearJobCardAmendment',
  () => {},
)

const initialState: IJobCardAmendmentState = {
  selectedJobCardAmendment: null,
  jobCardAmendments: [],
}

const jobCardAmendmentSlice = createSlice({
  name: 'jobCardAmendment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllJobCardAmendments.fulfilled, (state, { payload }) => {
      state.jobCardAmendments = payload.items
    })
    builder.addCase(getJobCardAmendment.fulfilled, (state, { payload }) => {
      state.selectedJobCardAmendment = payload.item
    })
    builder.addCase(clearJobCardAmendment.fulfilled, (state) => {
      state.selectedJobCardAmendment = null
    })
  },
})

const { reducer } = jobCardAmendmentSlice
export default reducer
