import axios from 'axios'

import helpers from './helpers'
import { IServiceItem } from '../models/routineMaintenance'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IRoutineMaintenanceCreate {
  description: string
  equipmentIds: string[]
  name: string
  serviceItems: IServiceItem[]
  token: string
}

export interface IRoutineMaintenanceGet {
  id?: string
  token: string
}

export default class RoutineMaintenanceRequests {
  static create = (data: IRoutineMaintenanceCreate) => {
    return axios.post(
      `${domain}/routine-maintenance`,
      {
        description: data.description,
        equipmentIds: data.equipmentIds,
        name: data.name,
        serviceItems: data.serviceItems,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IRoutineMaintenanceGet) => {
    return axios.get(`${domain}/routine-maintenance`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IRoutineMaintenanceGet) => {
    return axios.get(`${domain}/routine-maintenance/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
