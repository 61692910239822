import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'

import Footer from '../../components/Footer'
import Text from '../../components/Text'
import { IEquipment } from '../../models/equipment'

interface EquipmentProps {
  equipment: IEquipment
}

const EquipmentDetails: FC<EquipmentProps> = ({ equipment }) => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Equipment Details"
            subtitle="Manage informations related to equipment"
            buttonText="Edit"
            showButton
          />
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{equipment.name}</b>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Footer />
    </>
  )
}

export default EquipmentDetails
