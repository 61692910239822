import { ChangeEvent, forwardRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../store'
import { createBom } from '../../slice/bom'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const superEquipmentType = [
  {
    value: 'Conveyer Belt',
    label: 'Conveyer Belt',
  },
  {
    value: 'Smelter Dish',
    label: 'Smelter Dish',
  },
  {
    value: 'Hydraulic Shafts',
    label: 'Hydraulic Shafts',
  },
  {
    value: 'SA 30',
    label: 'SA 30 X 42 Jaw Crusher',
  },
]

const subEquipmentType = [
  {
    value: 'Shaft & Bearings',
    label: 'Shaft & Bearings',
  },
  {
    value: 'Turning',
    label: 'Turning Wheel',
  },
]

const drawingType = [
  {
    value: 'AH3042L511A',
    label: 'AH3042L511A',
  },
  {
    value: 'AH3042L511A3',
    label: 'AH3042L511A3',
  },
]

const criticalType = [
  {
    value: 'true',
    label: 'Critical',
  },
  {
    value: 'false',
    label: 'Non Critical',
  },
]

const BOMDetails = () => {
  const [itemNumber, setItemNumber] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [minSparesRequired, setMinSparesRequired] = useState<number>(0)
  const [quantity, setQuantity] = useState<number>(0)
  const [symbolNumber, setSymbolNumber] = useState<string>('')
  const [superEquipment, setSuperEquipment] = useState<string>('')
  const [subEquipment, setSubEquipment] = useState<string>('')
  const [isCritical, setIsCritical] = useState<string>('true')
  const [drawing, setDrawing] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const user = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleItemNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setItemNumber(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleMinSparesRequiredChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMinSparesRequired(event.target.value as number)
  }

  const handleQuantityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setQuantity(event.target.value as number)
  }

  const handleSymbolNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSymbolNumber(event.target.value as string)
  }

  const handleSuperEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperEquipment(event.target.value as string)
  }

  const handleSubEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubEquipment(event.target.value as string)
  }

  const handleDrawingChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDrawing(event.target.value as string)
  }

  const handleCriticalChange = (event: ChangeEvent<{ value: unknown }>) => {
    setIsCritical(event.target.value as string)
  }

  const onAddBom = async () => {
    const payload = {
      token: user!.accessToken!,
      description,
      isCritical: isCritical === 'true',
      itemNumber,
      minSparesRequired,
      quantity,
      symbolNumber,
    }

    try {
      await dispatch(createBom(payload)).unwrap()
      navigate('../bom/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader
            title="Reference Super Equipment"
            subtitle="Connect part to its super equipment"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="business-unit"
                  select
                  variant="outlined"
                  required
                  label="Reference Super Equipment"
                  value={superEquipment}
                  onChange={handleSuperEquipmentChange}
                >
                  {superEquipmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="business-unit"
                  select
                  variant="outlined"
                  required
                  label="Sub Equipment"
                  value={subEquipment}
                  onChange={handleSubEquipmentChange}
                >
                  {subEquipmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="business-unit"
                  select
                  variant="outlined"
                  required
                  label="Drawing"
                  value={drawing}
                  onChange={handleDrawingChange}
                >
                  {drawingType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Add Item" subtitle="Add parts for the corresponding equipment" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="item number"
                  variant="outlined"
                  required
                  id="item number"
                  label="Item Number"
                  value={itemNumber}
                  onChange={handleItemNumberChange}
                />
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <TextField
                  name="Symbol number"
                  variant="outlined"
                  required
                  id="symbol number"
                  label="Symbol Number"
                  value={symbolNumber}
                  onChange={handleSymbolNumberChange}
                />
                <TextField
                  name="quantity"
                  variant="outlined"
                  type="number"
                  required
                  id="quantity"
                  label="Quantity"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
                <TextField
                  id="critical-component"
                  select
                  variant="outlined"
                  required
                  label="Is this a critical component?"
                  value={isCritical}
                  onChange={handleCriticalChange}
                >
                  {criticalType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="no-spares"
                  variant="outlined"
                  type="number"
                  required
                  id="no-spares"
                  label="Minimum Spares Required"
                  value={minSparesRequired}
                  onChange={handleMinSparesRequiredChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddBom}>
          Create Bom
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default BOMDetails
