import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { countries } from 'countries-list'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { EquipmentStatusType } from '../../models/equipment'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllActivityAreas } from '../../slice/activityArea'
import { createEquipment, getAllEquipment } from '../../slice/equipment'
import { getAllCostCentres } from '../../slice/costCentre'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const countryOptions = Object.keys(countries).map((key) => {
  return {
    code: key.toLowerCase(),
    emoji: (countries as any)[key].emoji,
    label: (countries as any)[key].name,
  }
})

const statusOptions = [
  {
    value: 'in service',
    label: 'In Service',
  },
  {
    value: 'out of service',
    label: 'Out of Service',
  },
  {
    value: 'idle',
    label: 'Idle',
  },
  {
    value: 'dismantled',
    label: 'Dismantled',
  },
  {
    value: 'does not apply to spares',
    label: 'Does not apply to spares',
  },
]

const schedulingOptions = [
  {
    value: 'runtime',
    label: 'Runtime',
  },
  {
    value: 'cycle',
    label: 'Cycle',
  },
  {
    value: 'production output',
    label: 'Production Output',
  },
]

const maintenanceOptions = [
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'replacement',
    label: 'Replacement',
  },
]

const EquipmentDetails = () => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [functionalLocation, setFunctionalLocation] = useState<string>('')
  const [superEquipment, setSuperEquipment] = useState<string>('')
  const [equipmentStatus, setEquipmentStatus] = useState<string>('')
  const [scheduling, setScheduling] = useState<string>('')
  const [maintenance, setMaintenance] = useState<string>('')
  const [initialDate, setInitialDate] = useState<Date | null>(null)
  const [acquiredDate, setAcquiredDate] = useState<Date | null>(null)
  const [activityAreaOptions, setActivityAreaOptions] = useState<any[]>([])
  const [superEquipmentOptions, setSuperEquipmentOptions] = useState<any[]>([])
  const [costCentreOptions, setCostCentreOptions] = useState<any[]>([])
  const [costCentreId, setCostCentreId] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [complexName, setComplexName] = useState<string>('')
  const [addressCountry, setAddressCountry] = useState<string>('')
  const [province, setProvince] = useState<string>('')
  const [streetName, setStreetName] = useState<string>('')
  const [streetNumber, setStreetNumber] = useState<string>('')
  const [suburb, setSuburb] = useState<string>('')
  const [unitNumber, setUnitNumber] = useState<string>('')
  const [maintenancePlanDescription, setMaintenancePlanDescription] = useState<string>('')
  const [equipmentDescription, setEquipmentDescription] = useState<string>('')
  const [identification, setIdentification] = useState<string>('')
  const [modelNumber, setModelNumber] = useState<string>('')
  const [equipmentName, setEquipmentName] = useState<string>('')
  const [manufacturerName, setManufacturerName] = useState<string>('')
  const [partNumber, setPartNumber] = useState<string>('')
  const [serialNumber, setSerialNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [acquisitionValue, setAcquisitionValue] = useState<number>(0)
  const [weight, setWeight] = useState<number>(0)
  const [leadTime, setLeadTime] = useState<number>(0)

  const user = useAppSelector((state) => state.user).user
  const activityAreas = useAppSelector((state) => state.activityArea).activityAreas
  const equipment = useAppSelector((state) => state.equipment).equipment
  const costCentres = useAppSelector((state) => state.costCentre).costCentres

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllEquipment({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllActivityAreas({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllCostCentres({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempActivityAreaOptions: any = []
    activityAreas.forEach((item) => {
      tempActivityAreaOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setActivityAreaOptions(tempActivityAreaOptions)
  }, [activityAreas])

  useEffect(() => {
    const tempSuperEquipmentOptions: any = []
    equipment.forEach((item) => {
      tempSuperEquipmentOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setSuperEquipmentOptions(tempSuperEquipmentOptions)
  }, [equipment])

  useEffect(() => {
    const tempCostCentreOptions: any = []
    costCentres.forEach((item) => {
      tempCostCentreOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setCostCentreOptions(tempCostCentreOptions)
  }, [costCentres])

  const handleCostCentreChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCostCentreId(event.target.value as string)
  }

  const handleSuperiorLocationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFunctionalLocation(event.target.value as string)
  }

  const handleEquipmentStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentStatus(event.target.value as string)
  }

  const handleSuperEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperEquipment(event.target.value as string)
  }

  const handleSchedulingChange = (event: ChangeEvent<{ value: unknown }>) => {
    setScheduling(event.target.value as string)
  }

  const handleMaintenanceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenance(event.target.value as string)
  }

  const handleInitialDateChange = (date: Date | null) => {
    setInitialDate(date)
  }

  const handleAcquiredDateChange = (date: Date | null) => {
    setAcquiredDate(date)
  }

  const handleCityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCity(event.target.value as string)
  }

  const handleCodeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCode(event.target.value as string)
  }

  const handleComplexNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setComplexName(event.target.value as string)
  }

  const handleProvinceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setProvince(event.target.value as string)
  }

  const handleStreetNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStreetName(event.target.value as string)
  }

  const handleStreetNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStreetNumber(event.target.value as string)
  }

  const handleSuburbChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuburb(event.target.value as string)
  }

  const handleUnitNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setUnitNumber(event.target.value as string)
  }

  const handleMaintenancePlanDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenancePlanDescription(event.target.value as string)
  }

  const handleEquipmentDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentDescription(event.target.value as string)
  }

  const handleIdentificationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setIdentification(event.target.value as string)
  }

  const handleModelNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setModelNumber(event.target.value as string)
  }

  const handleEquipmentNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentName(event.target.value as string)
  }

  const handleManufacturerNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setManufacturerName(event.target.value as string)
  }

  const handlePartNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPartNumber(event.target.value as string)
  }

  const handleSerialNumberChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSerialNumber(event.target.value as string)
  }

  const handleEmailChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const handleTelephoneChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTelephone(event.target.value as string)
  }

  const handleAcquisitionValueChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAcquisitionValue(event.target.value as number)
  }

  const handleWeightChange = (event: ChangeEvent<{ value: unknown }>) => {
    setWeight(event.target.value as number)
  }

  const handleLeadTimeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setLeadTime(event.target.value as number)
  }

  const onAddEquipment = async () => {
    const payload = {
      acquiredDate: acquiredDate as Date,
      acquisitionValue,
      costCentreId,
      token: user!.accessToken!,
      equipmentDescription,
      functionalLocationId: functionalLocation,
      identification,
      initialDate: initialDate as Date,
      modelNumber,
      equipmentName,
      parentId: superEquipment || undefined,
      partNumber,
      serialNumber,
      status: equipmentStatus as EquipmentStatusType,
      weight,
      city,
      code,
      complexName,
      country: addressCountry,
      email,
      leadTime,
      manufacturerName,
      province,
      streetName,
      streetNumber,
      suburb,
      telephone,
      unitNumber,
      maintenancePlanDescription,
      maintenanceType: maintenance,
      schedulingType: scheduling,
    }

    try {
      await dispatch(createEquipment(payload)).unwrap()
      navigate('../equipment/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="General Equipment Data"
            subtitle="Add general data about new equipment"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Equipment Name"
                  value={equipmentName}
                  onChange={handleEquipmentNameChange}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Select Reference Activity Area"
                  onChange={handleSuperiorLocationChange}
                >
                  {activityAreaOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="outlined"
                  required
                  label="Equipment Description"
                  value={equipmentDescription}
                  onChange={handleEquipmentDescriptionChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Identification"
                  value={identification}
                  onChange={handleIdentificationChange}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Reference Super Equipment"
                  onChange={handleSuperEquipmentChange}
                >
                  {superEquipmentOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MobileDatePicker
                  label="Initial Date"
                  inputFormat="dd MMM yyyy"
                  value={initialDate}
                  onChange={handleInitialDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Equipment Status"
                  value={equipmentStatus}
                  onChange={handleEquipmentStatusChange}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Select Cost Centre"
                  onChange={handleCostCentreChange}
                >
                  {costCentreOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Reference Data"
            subtitle="Add more reference information for this equipment"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  type="number"
                  variant="outlined"
                  required
                  label="Acquisition Value"
                  value={acquisitionValue}
                  onChange={handleAcquisitionValueChange}
                />
                <MobileDatePicker
                  label="Acquired Date"
                  inputFormat="dd MMM yyyy"
                  value={acquiredDate}
                  onChange={handleAcquiredDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Model Number"
                  value={modelNumber}
                  onChange={handleModelNumberChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Serial Number"
                  value={serialNumber}
                  onChange={handleSerialNumberChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Part Number"
                  value={partNumber}
                  onChange={handlePartNumberChange}
                />
                <TextField
                  type="number"
                  variant="outlined"
                  required
                  label="Weight"
                  value={weight}
                  onChange={handleWeightChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Manufacture Information"
            subtitle="Add information about the equipment's manufacture"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
                '& .MuiAutocomplete-root': { width: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Manufacturer name"
                  value={manufacturerName}
                  onChange={handleManufacturerNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Manufacturer contact number"
                  value={telephone}
                  onChange={handleTelephoneChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Manufacturer email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Lead Time (days)"
                  value={leadTime}
                  onChange={handleLeadTimeChange}
                />
                <TextField
                  variant="outlined"
                  label="Block/Unit no."
                  required
                  value={unitNumber}
                  onChange={handleUnitNumberChange}
                />
                <TextField
                  variant="outlined"
                  label="Complex/Building name"
                  required
                  value={complexName}
                  onChange={handleComplexNameChange}
                />
                <TextField
                  variant="outlined"
                  label="Street number"
                  value={streetNumber}
                  onChange={handleStreetNumberChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Street name"
                  value={streetName}
                  onChange={handleStreetNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Suburb"
                  value={suburb}
                  onChange={handleSuburbChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="City"
                  value={city}
                  onChange={handleCityChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="State/Province"
                  value={province}
                  onChange={handleProvinceChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Code"
                  value={code}
                  onChange={handleCodeChange}
                />
                <Autocomplete
                  options={countryOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(event: any, newValue: any) => {
                    setAddressCountry(newValue.code)
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.emoji} {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Maintenance"
            subtitle="Add manufacture recommended maintenance plan"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Description"
                  value={maintenancePlanDescription}
                  onChange={handleMaintenancePlanDescriptionChange}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Scheduling Type"
                  value={scheduling}
                  onChange={handleSchedulingChange}
                >
                  {schedulingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Maintenance Type"
                  value={maintenance}
                  onChange={handleMaintenanceChange}
                >
                  {maintenanceOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddEquipment}>
          Create Equipment
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}
export default EquipmentDetails
