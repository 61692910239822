import { Navigate, RouteObject } from 'react-router-dom'

import SidebarLayout from './layouts/SidebarLayout'
import BaseLayout from './layouts/BaseLayout'
import RequireAuth from './components/RequireAuth'

// Status
import Status404 from './pages/Status404'

// Authentication
import SignIn from './pages/SignIn'

// Account
import Notifications from './pages/Notifications'
import Tasks from './pages/Tasks'
import Profile from './pages/Profile'

// Projects
import OPEX from './pages/OPEX'
import CAPEX from './pages/CAPEX'

// Maintenance
import ActivityAreas from './pages/ActivityAreas'
import AddActivityAreas from './pages/AddActivityAreas'
import ViewActivityArea from './pages/ViewActivityArea'
import AddEquipment from './pages/AddEquipment'
import Equipment from './pages/Equipment'
import ViewEquipment from './pages/ViewEquipment'
import AddBOMs from './pages/AddBOMs'
import ViewBom from './pages/ViewBom'
import Inspection from './pages/Inspection'
import AddEquipmentChecklist from './pages/AddEquipmentChecklist'
import AddMaintenanceRequest from './pages/AddMaintenanceRequest'
import ViewMaintenanceRequest from './pages/ViewMaintenanceRequest'
import Breakdowns from './pages/Breakdowns'
import PlannedMaintenance from './pages/PlannedMaintenance'
import AddMaintenanceTask from './pages/AddMaintenanceTask'
import AddJobCard from './pages/AddJobCard'
import AddJobCardAmendmentApproval from './pages/AddJobCardAmendmentApproval'
import AddActualMaintenance from './pages/AddActualMaintenance'
import AddMaintenanceStrategy from './pages/AddRoutineMaintenance'
import Report from './pages/Report'
import AddReport from './pages/AddReport'
import AddJobCardRequirement from './pages/AddJobCardRequirement'
import AddJobCardAmendment from './pages/AddJobCardAmendment'
import Suppliers from './pages/Suppliers'
import Energy from './pages/Energy'
import AddChecklist from './pages/AddChecklist'
import ViewCompletedChecklist from './pages/ViewCompletedChecklist'

// Production
import RawMaterials from './pages/Raw Materials'
import ProductionOutput from './pages/ProductionOutput'
import AddProductionOutput from './pages/AddProductionOutput'
import ProductionModes from './pages/ProductionModes'
import ProductionStockpiles from './pages/ProductionStockpiles'
import QuarryPit from './pages/QuarryPit'
import CrushingAndScreening from './pages/CrushingAndScreening'
import EarthMovingEquipment from './pages/EarthMovingEquipment'
import LoadAndHauling from './pages/LoadAndHauling'
import AddStockpile from './pages/AddStockpile'
import ViewStockpile from './pages/ViewStockpile'
import AddProductionModes from './pages/AddProductionModes'
import ViewProductionMode from './pages/ViewProductionMode'
import AddRawMaterials from './pages/AddRawMaterials'

// Sales
import SaleStockpiles from './pages/SaleStockpiles'
import Customers from './pages/Customers'
import Actual from './pages/Actual'
import Planned from './pages/Planned'

// HR
import ManageUsers from './pages/ManageUsers'
import LeavePolicies from './pages/LeavePolicies'
import AddLeavePolicy from './pages/AddLeavePolicy'
import AddUser from './pages/AddUser'
import ViewUser from './pages/ViewUser'
import ShiftManager from './pages/ShiftManager'
import Departments from './pages/Departments'
import AddDepartments from './pages/AddDepartments'
import AddPermission from './pages/AddPermission'
import CostCentres from './pages/CostCentres'
import AddCostCentre from './pages/AddCostCentre'
import Permissions from './pages/Permissions'
import AddShift from './pages/AddShift'

// Dashboards
// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')))

const routes: RouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/account" replace />,
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />,
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: '404',
            element: <Status404 />,
          },
          // {
          //   path: '500',
          //   element: <Status500 />,
          // },
          // {
          //   path: 'maintenance',
          //   element: <StatusMaintenance />,
          // },
          // {
          //   path: 'coming-soon',
          //   element: <StatusComingSoon />,
          // },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'signin',
    element: <SignIn />,
  },
  {
    path: 'dashboards',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/dashboards/crypto" replace />,
      },
      // {
      //   path: 'crypto',
      //   element: <Crypto />,
      // },
      // {
      //   path: 'messenger',
      //   element: <Messenger />,
      // },
    ],
  },
  {
    path: 'account',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/account/notifications" replace />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
      {
        path: 'tasks',
        element: <Tasks />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: 'projects',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/projects/opex" replace />,
      },
      {
        path: 'opex',
        element: <OPEX />,
      },
      {
        path: 'capex',
        element: <CAPEX />,
      },
    ],
  },
  {
    path: 'maintenance',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/maintenance/activity-area" replace />,
      },
      {
        path: 'activity-area',
        element: <ActivityAreas />,
      },
      {
        path: 'activity-area/add',
        element: <AddActivityAreas />,
      },
      {
        path: 'activity-area/:id',
        element: <ViewActivityArea />,
      },
      {
        path: 'equipment/add-equipment',
        element: <AddEquipment />,
      },
      {
        path: 'equipment',
        element: <Equipment />,
      },
      {
        path: 'equipment/:id',
        element: <ViewEquipment />,
      },
      {
        path: 'equipment/add-bom',
        element: <AddBOMs />,
      },
      {
        path: 'equipment/bom/:id',
        element: <ViewBom />,
      },
      {
        path: 'inspection',
        element: <Inspection />,
      },
      {
        path: 'inspection/add-equipment',
        element: <AddEquipmentChecklist />,
      },
      {
        path: 'inspection/add-maintenance-request',
        element: <AddMaintenanceRequest />,
      },
      {
        path: 'inspection/maintenance-request/:id',
        element: <ViewMaintenanceRequest />,
      },
      {
        path: 'breakdowns',
        element: <Breakdowns />,
      },
      {
        path: 'planned-maintenance',
        element: <PlannedMaintenance />,
      },
      {
        path: 'planned-maintenance/add-maintenance-task',
        element: <AddMaintenanceTask />,
      },
      {
        path: 'planned-maintenance/add-job-card',
        element: <AddJobCard />,
      },
      {
        path: 'planned-maintenance/add-job-card-amendment',
        element: <AddJobCardAmendmentApproval />,
      },
      {
        path: 'planned-maintenance/add-actual-maintenance',
        element: <AddActualMaintenance />,
      },
      {
        path: 'planned-maintenance/add-maintenance-strategy',
        element: <AddMaintenanceStrategy />,
      },
      {
        path: 'planned-maintenance/add-checklist',
        element: <AddChecklist />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'report/add',
        element: <AddReport />,
      },
      {
        path: 'report/add-job-card-amendment',
        element: <AddJobCardAmendment />,
      },
      {
        path: 'report/add-job-card-requirement',
        element: <AddJobCardRequirement />,
      },
      {
        path: 'report/completed-checklist/:id',
        element: <ViewCompletedChecklist />,
      },
      {
        path: 'suppliers',
        element: <Suppliers />,
      },
      {
        path: 'energy',
        element: <Energy />,
      },
    ],
  },
  {
    path: 'production',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/production/stockpiles" replace />,
      },
      {
        path: 'raw-materials',
        element: <RawMaterials />,
      },
      {
        path: 'raw-materials/add',
        element: <AddRawMaterials />,
      },
      {
        path: 'production-output',
        element: <ProductionOutput />,
      },
      {
        path: 'production-output/add',
        element: <AddProductionOutput />,
      },
      {
        path: 'production-modes',
        element: <ProductionModes />,
      },
      {
        path: 'production-modes/add',
        element: <AddProductionModes />,
      },
      {
        path: 'production-modes/view',
        element: <ViewProductionMode />,
      },
      {
        path: 'stockpiles',
        element: <ProductionStockpiles />,
      },
      {
        path: 'quarry-pit',
        element: <QuarryPit />,
      },
      {
        path: 'crushing-and-screening',
        element: <CrushingAndScreening />,
      },
      {
        path: 'earth-moving-equipment',
        element: <EarthMovingEquipment />,
      },
      {
        path: 'load-and-hauling',
        element: <LoadAndHauling />,
      },
      {
        path: 'stockpiles/add',
        element: <AddStockpile />,
      },
      {
        path: 'stockpiles/view',
        element: <ViewStockpile />,
      },
    ],
  },
  {
    path: 'sales',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/sales/stockpiles" replace />,
      },
      {
        path: 'stockpiles',
        element: <SaleStockpiles />,
      },
      {
        path: 'customers',
        element: <Customers />,
      },
      {
        path: 'actual',
        element: <Actual />,
      },
      {
        path: 'planned',
        element: <Planned />,
      },
    ],
  },
  {
    path: 'hr',
    element: (
      <RequireAuth>
        <SidebarLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/hr/manage-users" replace />,
      },
      {
        path: 'manage-users',
        element: <ManageUsers />,
      },
      {
        path: 'leave-policies',
        element: <LeavePolicies />,
      },
      {
        path: 'leave-policies/add',
        element: <AddLeavePolicy />,
      },
      {
        path: 'manage-users/add',
        element: <AddUser />,
      },
      {
        path: 'manage-users/:id',
        element: <ViewUser />,
      },
      {
        path: 'shift-manager',
        element: <ShiftManager />,
      },
      {
        path: 'shift-manager/add',
        element: <AddShift />,
      },
      {
        path: 'departments',
        element: <Departments />,
      },
      {
        path: 'departments/add',
        element: <AddDepartments />,
      },
      {
        path: 'permissions',
        element: <Permissions />,
      },
      {
        path: 'permissions/add',
        element: <AddPermission />,
      },

      {
        path: 'cost-centres',
        element: <CostCentres />,
      },
      {
        path: 'cost-centres/add',
        element: <AddCostCentre />,
      },
    ],
  },
]

export default routes
