import { IApiError } from '../models/helpers'
import { defaultErrorMessage } from './Constants'

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

/**
 * Capitalising each first letter of the text.
 */
export const toTitleCase = (text: string): string => {
  // eslint-disable-next-line eqeqeq
  if (text == 'hr') {
    return text.toUpperCase()
  }

  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * Format the error to type IApiError.
 */
export const formatApiError = (error: any): IApiError => {
  return {
    message: error.response.data.error
      ? error.response.data.error.message || defaultErrorMessage
      : defaultErrorMessage,
    status: error.response.status,
  }
}
