import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function EarthMovingEquipment() {
  return (
    <>
      <Helmet>
        <title>Earth Moving Equipment</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Earth Moving Equipment"
          subHeading="These are your earth moving equipments."
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default EarthMovingEquipment
