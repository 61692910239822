import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function Customers() {
  return (
    <>
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Customers" subHeading="These are your customers." />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default Customers
