import { Card } from '@mui/material'
import { FC } from 'react'

import { IChecklist } from '../../models/checklist'
import ChecklistTable from './ChecklistTable'

interface ChecklistProps {
  data: IChecklist[]
}

const Checklist: FC<ChecklistProps> = ({ data }) => {
  return (
    <Card>
      <ChecklistTable data={data} />
    </Card>
  )
}

export default Checklist
