import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import PermissionRequests, { IPermissionCreate, IPermissionGet } from '../server/permission'
import { IPermission } from '../models/permission'
import { formatApiError } from '../utilities/Helpers'

interface IPermissionState {
  selectedPermission: IPermission | null
  permissions: IPermission[]
}

export const getPermission = createAsyncThunk(
  'permission/getPermission',
  async (data: IPermissionGet, thunkAPI) => {
    try {
      const response = await PermissionRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllPermissions = createAsyncThunk(
  'permission/getAllPermissions',
  async (data: IPermissionGet, thunkAPI) => {
    try {
      const response = await PermissionRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createPermission = createAsyncThunk(
  'permission/createPermission',
  async (data: IPermissionCreate, thunkAPI) => {
    try {
      await PermissionRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearPermission = createAsyncThunk('permission/clearPermission', () => {})

const initialState: IPermissionState = {
  selectedPermission: null,
  permissions: [],
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPermissions.fulfilled, (state, { payload }) => {
      state.permissions = payload.items
    })
    builder.addCase(getPermission.fulfilled, (state, { payload }) => {
      state.selectedPermission = payload.item
    })
    builder.addCase(clearPermission.fulfilled, (state) => {
      state.selectedPermission = null
    })
  },
})

const { reducer } = permissionSlice
export default reducer
