import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IMaintenanceCreate {
  description: string
  equipmentId: string
  escalation: string
  functionalLocationId: string
  operationalEffect: string
  title: string
  token: string
}

export interface IMaintenanceGet {
  id?: string
  token: string
}

export default class MaintenanceRequests {
  static getAll = (data: IMaintenanceGet) => {
    return axios.get(`${domain}/maintenance-request`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IMaintenanceGet) => {
    return axios.get(`${domain}/maintenance-request/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static create = async (data: IMaintenanceCreate) => {
    return axios.post(
      `${domain}/maintenance-request`,
      {
        description: data.description,
        equipmentId: data.equipmentId,
        escalation: data.escalation,
        functionalLocationId: data.functionalLocationId,
        operationalEffect: data.operationalEffect,
        title: data.title,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }
}
