import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import EquipmentDetails from './EquipmentDetails'

const AddEquipment = () => {
  return (
    <>
      <Helmet>
        <title>Add Equipment</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <EquipmentDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddEquipment
