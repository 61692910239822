import { Card } from '@mui/material'
import { FC } from 'react'

import RoutineMaintenanceTable from './RoutineMaintenanceTable'
import { IRoutineMaintenance } from '../../models/routineMaintenance'

interface RoutineMaintenanceProps {
  data: IRoutineMaintenance[]
}

const RoutineMaintenance: FC<RoutineMaintenanceProps> = ({ data }) => {
  return (
    <Card>
      <RoutineMaintenanceTable data={data} />
    </Card>
  )
}

export default RoutineMaintenance
