import { ChangeEvent, forwardRef, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import Footer from '../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../store'
import { createPermission } from '../../slice/permission'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

type MaintenanceKeys = 'activity-areas' | 'equipment' | 'inspection' | 'maintenance'
type HrKeys = 'manage-users' | 'departments' | 'permissions' | 'leave-policies' | 'shift-manager'

type MaintenancePermissions = {
  'activity-areas': boolean
  equipment: boolean
  inspection: boolean
  maintenance: boolean
}

type HrPermissions = {
  'manage-users': boolean
  departments: boolean
  permissions: boolean
  'leave-policies': boolean
  'shift-manager': boolean
}

const PermissionDetails = () => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [maintenanceRead, setMaintenanceRead] = useState<MaintenancePermissions>({
    'activity-areas': false,
    equipment: false,
    inspection: false,
    maintenance: false,
  })
  const [maintenanceWrite, setMaintenanceWrite] = useState<MaintenancePermissions>({
    'activity-areas': false,
    equipment: false,
    inspection: false,
    maintenance: false,
  })
  const [hrRead, setHrRead] = useState<HrPermissions>({
    'manage-users': false,
    departments: false,
    permissions: false,
    'leave-policies': false,
    'shift-manager': false,
  })
  const [hrWrite, setHrWrite] = useState<HrPermissions>({
    'manage-users': false,
    departments: false,
    permissions: false,
    'leave-policies': false,
    'shift-manager': false,
  })

  const user = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleMaintenanceReadChange = (key: MaintenanceKeys) => {
    const tempMaintenanceRead = { ...maintenanceRead }
    switch (key) {
      case 'equipment':
        tempMaintenanceRead['equipment'] = !tempMaintenanceRead['equipment']
        break
      case 'activity-areas':
        tempMaintenanceRead['activity-areas'] = !tempMaintenanceRead['activity-areas']
        break
      case 'maintenance':
        tempMaintenanceRead['maintenance'] = !tempMaintenanceRead['maintenance']
        break
      case 'inspection':
        tempMaintenanceRead['inspection'] = !tempMaintenanceRead['inspection']
        break
    }

    setMaintenanceRead(tempMaintenanceRead)
  }

  const checkMaintenanceReadAllSelected = (): boolean => {
    let result = true
    for (const value of Object.values(maintenanceRead)) {
      if (value === false) {
        result = false
      }
    }

    return result
  }

  const handleMaintenanceWriteChange = (key: MaintenanceKeys) => {
    const tempMaintenanceWrite = { ...maintenanceWrite }
    switch (key) {
      case 'equipment':
        tempMaintenanceWrite['equipment'] = !tempMaintenanceWrite['equipment']
        break
      case 'activity-areas':
        tempMaintenanceWrite['activity-areas'] = !tempMaintenanceWrite['activity-areas']
        break
      case 'maintenance':
        tempMaintenanceWrite['maintenance'] = !tempMaintenanceWrite['maintenance']
        break
      case 'inspection':
        tempMaintenanceWrite['inspection'] = !tempMaintenanceWrite['inspection']
        break
    }

    setMaintenanceWrite(tempMaintenanceWrite)
  }

  const checkMaintenanceWriteAllSelected = (): boolean => {
    let result = true
    for (const value of Object.values(maintenanceWrite)) {
      if (value === false) {
        result = false
      }
    }

    return result
  }

  const handleHrReadChange = (key: HrKeys) => {
    const tempHrRead = { ...hrRead }
    switch (key) {
      case 'departments':
        tempHrRead['departments'] = !tempHrRead['departments']
        break
      case 'leave-policies':
        tempHrRead['leave-policies'] = !tempHrRead['leave-policies']
        break
      case 'manage-users':
        tempHrRead['manage-users'] = !tempHrRead['manage-users']
        break
      case 'permissions':
        tempHrRead['permissions'] = !tempHrRead['permissions']
        break
      case 'shift-manager':
        tempHrRead['shift-manager'] = !tempHrRead['shift-manager']
        break
    }

    setHrRead(tempHrRead)
  }

  const checkHrReadAllSelected = (): boolean => {
    let result = true
    for (const value of Object.values(hrRead)) {
      if (value === false) {
        result = false
      }
    }

    return result
  }

  const handleHrWriteChange = (key: HrKeys) => {
    const tempHrWrite = { ...hrWrite }
    switch (key) {
      case 'departments':
        tempHrWrite['departments'] = !tempHrWrite['departments']
        break
      case 'leave-policies':
        tempHrWrite['leave-policies'] = !tempHrWrite['leave-policies']
        break
      case 'manage-users':
        tempHrWrite['manage-users'] = !tempHrWrite['manage-users']
        break
      case 'permissions':
        tempHrWrite['permissions'] = !tempHrWrite['permissions']
        break
      case 'shift-manager':
        tempHrWrite['shift-manager'] = !tempHrWrite['shift-manager']
        break
    }

    setHrWrite(tempHrWrite)
  }

  const checkHrWriteAllSelected = (): boolean => {
    let result = true
    for (const value of Object.values(hrWrite)) {
      if (value === false) {
        result = false
      }
    }

    return result
  }

  const handleMaintenanceReadAllChange = () => {
    const isAllSelected = checkMaintenanceReadAllSelected()
    const tempMaintenanceRead = { ...maintenanceRead }
    for (const key of Object.keys(tempMaintenanceRead)) {
      tempMaintenanceRead[key as MaintenanceKeys] = !isAllSelected
    }

    setMaintenanceRead(tempMaintenanceRead)
  }

  const handleMaintenanceWriteAllChange = () => {
    const isAllSelected = checkMaintenanceWriteAllSelected()
    const tempMaintenanceWrite = { ...maintenanceWrite }
    for (const key of Object.keys(tempMaintenanceWrite)) {
      tempMaintenanceWrite[key as MaintenanceKeys] = !isAllSelected
    }

    setMaintenanceWrite(tempMaintenanceWrite)
  }

  const handleHrReadAllChange = () => {
    const isAllSelected = checkHrReadAllSelected()
    const tempHrRead = { ...hrRead }
    for (const key of Object.keys(tempHrRead)) {
      tempHrRead[key as HrKeys] = !isAllSelected
    }

    setHrRead(tempHrRead)
  }

  const handleHrWriteAllChange = () => {
    const isAllSelected = checkHrWriteAllSelected()
    const tempHrWrite = { ...hrWrite }
    for (const key of Object.keys(tempHrWrite)) {
      tempHrWrite[key as HrKeys] = !isAllSelected
    }

    setHrWrite(tempHrWrite)
  }

  const onAddPermission = async () => {
    const permissions = {
      maintenance: {
        'activity-areas': {
          read: maintenanceRead['activity-areas'],
          write: maintenanceWrite['activity-areas'],
        },
        equipment: {
          read: maintenanceRead['equipment'],
          write: maintenanceWrite['equipment'],
        },
        inspection: {
          read: maintenanceRead['inspection'],
          write: maintenanceWrite['inspection'],
        },
        maintenance: {
          read: maintenanceRead['maintenance'],
          write: maintenanceWrite['maintenance'],
        },
      },
      hr: {
        'manage-users': {
          read: hrRead['manage-users'],
          write: hrWrite['manage-users'],
        },
        departments: {
          read: hrRead['departments'],
          write: hrWrite['departments'],
        },
        permissions: {
          read: hrRead['permissions'],
          write: hrWrite['permissions'],
        },
        'leave-policies': {
          read: hrRead['leave-policies'],
          write: hrWrite['leave-policies'],
        },
        'shift-manager': {
          read: hrRead['shift-manager'],
          write: hrWrite['shift-manager'],
        },
      },
    }

    const payload = {
      token: user!.accessToken!,
      description,
      name,
      permissions,
    }

    try {
      await dispatch(createPermission(payload)).unwrap()
      navigate('../permissions/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Create Permission" subtitle="Add permission details below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="Name"
                  variant="outlined"
                  required
                  id="Name"
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Select Maintenance Permissions"
            subtitle="Select the permissions below"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <FormControlLabel
                  label="Maintenance (Read)"
                  control={
                    <Checkbox
                      checked={checkMaintenanceReadAllSelected()}
                      indeterminate={!checkMaintenanceReadAllSelected()}
                      onChange={handleMaintenanceReadAllChange}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    label="Activity Areas"
                    control={
                      <Checkbox
                        checked={maintenanceRead['activity-areas']}
                        onChange={() => handleMaintenanceReadChange('activity-areas')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Equipment"
                    control={
                      <Checkbox
                        checked={maintenanceRead['equipment']}
                        onChange={() => handleMaintenanceReadChange('equipment')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Inspections"
                    control={
                      <Checkbox
                        checked={maintenanceRead['inspection']}
                        onClick={() => handleMaintenanceReadChange('inspection')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Maintenance"
                    control={
                      <Checkbox
                        checked={maintenanceRead['maintenance']}
                        onChange={() => handleMaintenanceReadChange('maintenance')}
                      />
                    }
                  />
                </Box>
                <FormControlLabel
                  label="Maintenance (Write)"
                  control={
                    <Checkbox
                      checked={checkMaintenanceWriteAllSelected()}
                      indeterminate={!checkMaintenanceWriteAllSelected()}
                      onChange={handleMaintenanceWriteAllChange}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    label="Activity Areas"
                    control={
                      <Checkbox
                        checked={maintenanceWrite['activity-areas']}
                        onChange={() => handleMaintenanceWriteChange('activity-areas')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Equipment"
                    control={
                      <Checkbox
                        checked={maintenanceWrite['equipment']}
                        onChange={() => handleMaintenanceWriteChange('equipment')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Inspections"
                    control={
                      <Checkbox
                        checked={maintenanceWrite['inspection']}
                        onClick={() => handleMaintenanceWriteChange('inspection')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Maintenance"
                    control={
                      <Checkbox
                        checked={maintenanceWrite['maintenance']}
                        onChange={() => handleMaintenanceWriteChange('maintenance')}
                      />
                    }
                  />
                </Box>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Select HR Permissions" subtitle="Select the permissions below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <FormControlLabel
                  label="HR (Read)"
                  control={
                    <Checkbox
                      checked={checkHrReadAllSelected()}
                      indeterminate={!checkHrReadAllSelected()}
                      onChange={handleHrReadAllChange}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    label="Manage Users"
                    control={
                      <Checkbox
                        checked={hrRead['manage-users']}
                        onChange={() => handleHrReadChange('manage-users')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Departments"
                    control={
                      <Checkbox
                        checked={hrRead['departments']}
                        onChange={() => handleHrReadChange('departments')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Permissions"
                    control={
                      <Checkbox
                        checked={hrRead['permissions']}
                        onChange={() => handleHrReadChange('permissions')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Leave Policies"
                    control={
                      <Checkbox
                        checked={hrRead['leave-policies']}
                        onChange={() => handleHrReadChange('leave-policies')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Shift Manager"
                    control={
                      <Checkbox
                        checked={hrRead['shift-manager']}
                        onChange={() => handleHrReadChange('shift-manager')}
                      />
                    }
                  />
                </Box>
                <FormControlLabel
                  label="HR (Write)"
                  control={
                    <Checkbox
                      checked={checkHrWriteAllSelected()}
                      indeterminate={!checkHrWriteAllSelected()}
                      onChange={handleHrWriteAllChange}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <FormControlLabel
                    label="Manage Users"
                    control={
                      <Checkbox
                        checked={hrWrite['manage-users']}
                        onChange={() => handleHrWriteChange('manage-users')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Departments"
                    control={
                      <Checkbox
                        checked={hrWrite['departments']}
                        onChange={() => handleHrWriteChange('departments')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Permissions"
                    control={
                      <Checkbox
                        checked={hrWrite['permissions']}
                        onChange={() => handleHrWriteChange('permissions')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Leave Policies"
                    control={
                      <Checkbox
                        checked={hrWrite['leave-policies']}
                        onChange={() => handleHrWriteChange('leave-policies')}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Shift Manager"
                    control={
                      <Checkbox
                        checked={hrWrite['shift-manager']}
                        onChange={() => handleHrWriteChange('shift-manager')}
                      />
                    }
                  />
                </Box>
              </div>
            </Box>
          </CardContent>
        </Card>
        <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
          <div />
          <Button variant="contained" onClick={onAddPermission}>
            Create Permission
          </Button>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}

export default PermissionDetails
