import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import ProductionOutputRequests from '../../server/productionOutput'
import RawMaterialRequests from '../../server/rawMaterial'
import { IRawMaterial } from '../../models/rawMaterial'
import { useAppSelector } from '../../store'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const ProductionOutputDetails = () => {
  const userState = useAppSelector((state) => state.user).user
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [rawMaterialOptions, setRawMaterialOptions] = useState<any[]>([])
  const [material, setMaterial] = useState('')
  const [sludgeName, setSludgeName] = useState<string>('')
  const [productionTarget, setProductionTarget] = useState<number>(0)
  const [quantityPerTonne, setQuantityPerTonne] = useState<number>(0)
  const [sludgeQuantity, setSludgeQuantity] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    getRawMaterials()
  }, [])

  const getRawMaterials = async () => {
    const response = await RawMaterialRequests.getAll()

    const tempRawMaterialOptions: any = []
    if (response.status === 200) {
      response.data.forEach((item: IRawMaterial) => {
        tempRawMaterialOptions.push({
          value: item.id,
          label: item.name,
        })
      })

      setRawMaterialOptions(tempRawMaterialOptions)
    }
  }

  const handleMaterialChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaterial(event.target.value as string)
  }

  const handleSludgeNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSludgeName(event.target.value as string)
  }

  const handleProductionTargetChange = (event: ChangeEvent<{ value: unknown }>) => {
    setProductionTarget(event.target.value as number)
  }

  const handleQuantityPerTonneChange = (event: ChangeEvent<{ value: unknown }>) => {
    setQuantityPerTonne(event.target.value as number)
  }

  const handleSludgeQuantityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSludgeQuantity(event.target.value as number)
  }

  const onAddRawMaterial = async () => {
    const payload = {
      createdBy: userState!.id,
      productionTarget,
      quantityPerTonne,
      rawMaterialId: material,
      sludgeName,
      sludgeQuantity,
    }

    const response = await ProductionOutputRequests.create(payload)

    if (response.status === 201) {
      return navigate('../production-output/')
    }

    setErrorMessage(response.data.error.message)
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader
            title="Production Output Recording"
            subtitle="Edit production output records"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Raw Material"
                  value={material}
                  onChange={handleMaterialChange}
                >
                  {rawMaterialOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  variant="outlined"
                  type="number"
                  required
                  label="Recorded Quantity Per Tonnes"
                  value={quantityPerTonne}
                  onChange={handleQuantityPerTonneChange}
                />
                <TextField
                  variant="outlined"
                  type="number"
                  required
                  label="Production Target"
                  value={productionTarget}
                  onChange={handleProductionTargetChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Sludge" subtitle="Edit sludge details" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Sludge Name"
                  value={sludgeName}
                  onChange={handleSludgeNameChange}
                />
                <TextField
                  variant="outlined"
                  type="number"
                  label="Sludge Quantity"
                  value={sludgeQuantity}
                  onChange={handleSludgeQuantityChange}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
        <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
          <div />
          <Button variant="contained" onClick={onAddRawMaterial}>
            Create Raw Material
          </Button>
        </Grid>
        <Footer />
      </Grid>
    </>
  )
}

export default ProductionOutputDetails
