import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import JobCardRequests, { IJobCardCreate, IJobCardGet } from '../server/jobCard'
import { IJobCard } from '../models/jobCard'
import { formatApiError } from '../utilities/Helpers'

interface IJobCardState {
  selectedJobCard: IJobCard | null
  jobCards: IJobCard[]
}

export const getJobCard = createAsyncThunk(
  'jobCard/getJobCard',
  async (data: IJobCardGet, thunkAPI) => {
    try {
      const response = await JobCardRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllJobCards = createAsyncThunk(
  'jobCard/getAllJobCards',
  async (data: IJobCardGet, thunkAPI) => {
    try {
      const response = await JobCardRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createJobCard = createAsyncThunk(
  'jobCard/createJobCard',
  async (data: IJobCardCreate, thunkAPI) => {
    try {
      await JobCardRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearJobCard = createAsyncThunk('jobCard/clearJobCard', () => {})

const initialState: IJobCardState = {
  selectedJobCard: null,
  jobCards: [],
}

const jobCardSlice = createSlice({
  name: 'jobCard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllJobCards.fulfilled, (state, { payload }) => {
      state.jobCards = payload.items
    })
    builder.addCase(getJobCard.fulfilled, (state, { payload }) => {
      state.selectedJobCard = payload.item
    })
    builder.addCase(clearJobCard.fulfilled, (state) => {
      state.selectedJobCard = null
    })
  },
})

const { reducer } = jobCardSlice
export default reducer
