import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ProductionModesDetails from './ProductionModesDetails'

const AddProductionModes = () => {
  return (
    <>
      <Helmet>
        <title>Create Production Modes</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ProductionModesDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddProductionModes
