import { ChangeEvent, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

function Suppliers() {
  const [currentTab, setCurrentTab] = useState<string>('purchase_orders')

  const tabs = [
    { value: 'purchase_orders', label: 'Purchase Orders' },
    { value: 'invoices', label: 'Invoices' },
    { value: 'payments', label: 'Payments' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const getButtonText = (value: string): string => {
    switch (value) {
      case 'invoices':
        return 'Add Invoice'
      case 'payments':
        return 'Add Payment'

      default:
        return 'Add Purchase Order'
    }
  }

  return (
    <>
      <Helmet>
        <title>Suppliers</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Suppliers"
          subHeading="These are your suppliers."
          buttonText={getButtonText(currentTab)}
          showButton
          onPress={() => console.log('Add Supplier')}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'purchase_orders' && <div />}
            {currentTab === 'invoices' && <div />}
            {currentTab === 'payments' && <div />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Suppliers
