import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import { IProductionMode } from '../../models/productionMode'
import ProductionModeDetails from './ProductionModeDetails'

const ViewProductionMode = () => {
  const location = useLocation()
  const productionMode: IProductionMode = (location.state as any).productionMode

  return (
    <>
      <Helmet>
        <title>{productionMode.name}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading={productionMode.name} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <ProductionModeDetails productionMode={productionMode} />
        </Grid>
      </Container>
    </>
  )
}

export default ViewProductionMode
