import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function Planned() {
  return (
    <>
      <Helmet>
        <title>Planned</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Planned" subHeading="These are your planned sales." />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default Planned
