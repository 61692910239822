import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'

import Footer from '../../components/Footer'
import Text from '../../components/Text'
import { IActivityArea } from '../../models/activityArea'
import CustomCardHeader from '../../components/CustomCardHeader'

interface ActivityAreaProps {
  activityArea: IActivityArea
}

const ActivityAreaDetails: FC<ActivityAreaProps> = ({ activityArea }) => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Activity Areas"
            subtitle="Manage informations related to activity areas"
            buttonText="Edit"
            showButton
          />
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{activityArea.name}</b>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Footer />
    </>
  )
}

export default ActivityAreaDetails
