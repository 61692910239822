import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import DepartmentsDetails from './DepartmentsDetails'

const AddDepartments = () => {
  return (
    <>
      <Helmet>
        <title>Add Departments</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <DepartmentsDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddDepartments
