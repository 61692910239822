import axios from 'axios'

import helpers from './helpers'
import { IChecklistTask } from '../models/completedChecklist'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface ICompletedChecklistCreate {
  checklistId: string
  comment: string
  tasks: IChecklistTask[]
  token: string
}

export interface ICompletedChecklistGet {
  id?: string
  token: string
}

export default class CompletedChecklistRequests {
  static create = (data: ICompletedChecklistCreate) => {
    return axios.post(
      `${domain}/completed-checklist`,
      {
        checklistId: data.checklistId,
        comment: data.comment,
        tasks: data.tasks,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: ICompletedChecklistGet) => {
    return axios.get(`${domain}/completed-checklist`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: ICompletedChecklistGet) => {
    return axios.get(`${domain}/completed-checklist/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
