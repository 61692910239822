import axios from 'axios'

import {
  IJobCardAmendmentPeople,
  IJobCardAmendmentSpares,
  JobCardAmendmentStatus,
} from '../models/jobCardAmendment'
import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IJobCardAmendmentCreate {
  description: string
  people: IJobCardAmendmentPeople[]
  spares: IJobCardAmendmentSpares[]
  status: JobCardAmendmentStatus
  time: number
  token: string
  worksOrderId: string
}

export interface IJobCardAmendmentGet {
  id?: string
  token: string
}

export default class JobCardAmendmentRequests {
  static getAll = (data: IJobCardAmendmentGet) => {
    return axios.get(`${domain}/works-order-amendment`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IJobCardAmendmentGet) => {
    return axios.get(`${domain}/works-order-amendment/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static create = (data: IJobCardAmendmentCreate) => {
    return axios.post(
      `${domain}/works-order-amendment`,
      {
        description: data.description,
        people: data.people,
        spares: data.spares,
        status: data.status,
        time: data.time,
        worksOrderId: data.worksOrderId,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }
}
