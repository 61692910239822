import { Card } from '@mui/material'
import { FC } from 'react'

import { IJobCardAmendment } from '../../models/jobCardAmendment'
import JobCardAmendmentTable from './JobCardAmendmentTable'

interface JobCardAmendmentProps {
  data: IJobCardAmendment[]
}

const JobCardAmendment: FC<JobCardAmendmentProps> = ({ data }) => {
  return (
    <Card>
      <JobCardAmendmentTable data={data} />
    </Card>
  )
}

export default JobCardAmendment
