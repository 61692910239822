import { Card } from '@mui/material'
import { useEffect, useState } from 'react'
import ProductionModesTable from './ProductionModesTable'
import ProductionModeRequests from '../../server/productionMode'

function AllProductionModes() {
  const [productionModes, setProductionModes] = useState<any[]>([])

  useEffect(() => {
    getProductionModes()
  }, [])

  const getProductionModes = async () => {
    const response = await ProductionModeRequests.getAll()

    if (response.status === 200) {
      setProductionModes(response.data)
    }
  }

  return (
    <Card>
      <ProductionModesTable productionModes={productionModes} />
    </Card>
  )
}

export default AllProductionModes
