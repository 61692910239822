import axios from 'axios'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export default class StockpileRequests {
  static getAll = async (stage: string, status: string) => {
    try {
      const response = await axios.get(`${domain}/stockpile?stage=${stage}&status=${status}`)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
