import { Card } from '@mui/material'
import { FC } from 'react'

import { IJobCard } from '../../models/jobCard'
import JobCardTable from './JobCardTable'

interface JobCardProps {
  data: IJobCard[]
}

const JobCard: FC<JobCardProps> = ({ data }) => {
  return (
    <Card>
      <JobCardTable data={data} />
    </Card>
  )
}

export default JobCard
