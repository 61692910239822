import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import JobCardRequirementDetails from './JobCardRequirementDetails'

const AddJobCardRequirement = () => {
  return (
    <>
      <Helmet>
        <title>Add Job Card Action Requirements</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <JobCardRequirementDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddJobCardRequirement
