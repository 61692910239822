import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Snackbar,
  MenuItem,
  IconButton,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import ClearIcon from '@mui/icons-material/Clear'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'

import CustomCardHeader from '../../components/CustomCardHeader'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllPermissions } from '../../slice/permission'
import { ChecklistType, ITask } from '../../models/checklist'
import { IApiError, IMenuItem } from '../../models/helpers'
import { createChecklist } from '../../slice/checklist'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const typeOptions = [
  {
    value: 'pre-check',
    label: 'Pre check',
  },
  {
    value: 'peri-check',
    label: 'During',
  },
  {
    value: 'post-check',
    label: 'Post check',
  },
]

const ChecklistDetails = () => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [permissionId, setPermissionId] = useState<string>('')
  const [permissionOptions, setPermissionOptions] = useState<IMenuItem[]>([])
  const [tasks, setTasks] = useState<ITask[]>([
    {
      id: uuidv4(),
      description: '',
    },
  ])

  const user = useAppSelector((state) => state.user).user
  const permissions = useAppSelector((state) => state.permission).permissions

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllPermissions({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempPermissionsOptions: any = []
    permissions.forEach((item) => {
      tempPermissionsOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setPermissionOptions(tempPermissionsOptions)
  }, [permissions])

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string)
  }

  const handlePersonnelChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPermissionId(event.target.value as string)
  }

  const handleTaskDescriptionChange = (id: string, description: string) => {
    const tempTasks = [...tasks]

    for (let i = 0; i < tempTasks.length; i++) {
      if (tempTasks[i].id === id) {
        tempTasks[i].description = description
        break
      }
    }

    setTasks(tempTasks)
  }

  const onAddChecklist = async () => {
    const payload = {
      permissionId,
      token: user!.accessToken!,
      description,
      name,
      tasks,
      type: type as ChecklistType,
    }

    try {
      await dispatch(createChecklist(payload)).unwrap()
      navigate('../planned-maintenance/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  const onAddTask = () => {
    const tempTasks = [...tasks]
    tempTasks.push({
      id: uuidv4(),
      description: '',
    })

    setTasks(tempTasks)
  }

  const onRemoveTask = (id: string) => {
    const tempTasks = [...tasks]

    for (let i = 0; i < tempTasks.length; i++) {
      if (tempTasks[i].id === id) {
        tempTasks.splice(i, 1)
        break
      }
    }

    setTasks(tempTasks)
  }

  const handleDrop = (droppedItem: DropResult) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) {
      return
    }

    const tempTasks = [...tasks]

    // Remove dragged item
    const [reorderedItem] = tempTasks.splice(droppedItem.source.index, 1)

    // Add dropped item
    tempTasks.splice(droppedItem.destination.index, 0, reorderedItem)

    setTasks(tempTasks)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(undefined)}
      >
        <Alert onClose={() => setErrorMessage(undefined)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Add Checklist details" subtitle="Add checklist details below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                required
                label="Name"
                value={name}
                onChange={handleNameChange}
              />
              <TextField
                variant="outlined"
                required
                label="Description"
                value={description}
                onChange={handleDescriptionChange}
              />
              <TextField
                select
                variant="outlined"
                required
                label="Select Check Type"
                onChange={handleTypeChange}
              >
                {typeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                variant="outlined"
                required
                label="Responsible Unit"
                value={permissionId}
                onChange={handlePersonnelChange}
              >
                {permissionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Add Tasks" subtitle="Add task details below" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '90%' },
                '& .MuiIconButton-root': { my: 1.7 },
              }}
              noValidate
              autoComplete="off"
            >
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="tasks">
                  {(provided) => (
                    <div className="tasks" {...provided.droppableProps} ref={provided.innerRef}>
                      {tasks.map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <IconButton disabled size="medium">
                                <ImportExportIcon fontSize="inherit" color="primary" />
                              </IconButton>
                              <TextField
                                variant="outlined"
                                required
                                multiline
                                label="Task description"
                                value={task.description}
                                onChange={(event) =>
                                  handleTaskDescriptionChange(task.id, event.target.value as string)
                                }
                              />
                              <IconButton size="medium" onClick={() => onRemoveTask(task.id)}>
                                <ClearIcon fontSize="inherit" color="error" />
                              </IconButton>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </CardContent>

          <Grid sx={{ pr: 2, pb: 2 }} container justifyContent="flex-end">
            <Button variant="contained" onClick={onAddTask}>
              Add Task
            </Button>
          </Grid>
        </Card>
      </Grid>
      <Grid sx={{ my: 3 }} container justifyContent="flex-end">
        <Button variant="contained" onClick={onAddChecklist}>
          Create Checklist
        </Button>
      </Grid>
    </>
  )
}

export default ChecklistDetails
