import { ChangeEvent, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

function Energy() {
  const [currentTab, setCurrentTab] = useState<string>('electrical')

  const tabs = [
    { value: 'electrical', label: 'Electrical' },
    { value: 'diesel', label: 'Diesel' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  return (
    <>
      <Helmet>
        <title>Energy</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Energy" subHeading="These are your energies." />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'electrical' && <div />}
            {currentTab === 'diesel' && <div />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Energy
