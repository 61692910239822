import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import { FC } from 'react'

interface CardHeaderProps {
  title: string
  subtitle: string
  showButton?: boolean
  buttonText?: string
  onPress?: () => void
}

const CustomCardHeader: FC<CardHeaderProps> = ({
  title,
  subtitle,
  showButton,
  buttonText,
  onPress,
}) => {
  return (
    <Box p={3} display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      {showButton && (
        <Button variant="text" startIcon={<EditTwoToneIcon />} onClick={() => onPress && onPress()}>
          {buttonText}
        </Button>
      )}
    </Box>
  )
}

export default CustomCardHeader
