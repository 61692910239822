import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IEquipment } from '../models/equipment'
import EquipmentRequests, { IEquipmentCreate, IEquipmentGet } from '../server/equipment'
import { formatApiError } from '../utilities/Helpers'

interface IEquipmentState {
  selectedEquipment: IEquipment | null
  equipment: IEquipment[]
}

export const getEquipment = createAsyncThunk(
  'equipment/getEquipment',
  async (data: IEquipmentGet, thunkAPI) => {
    try {
      const response = await EquipmentRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllEquipment = createAsyncThunk(
  'equipment/getAllEquipment',
  async (data: IEquipmentGet, thunkAPI) => {
    try {
      const response = await EquipmentRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createEquipment = createAsyncThunk(
  'equipment/createEquipment',
  async (data: IEquipmentCreate, thunkAPI) => {
    try {
      await EquipmentRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearEquipment = createAsyncThunk('equipment/clearEquipment', () => {})

const initialState: IEquipmentState = {
  selectedEquipment: null,
  equipment: [],
}

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllEquipment.fulfilled, (state, { payload }) => {
      state.equipment = payload.items
    })
    builder.addCase(getEquipment.fulfilled, (state, { payload }) => {
      state.selectedEquipment = payload.item
    })
    builder.addCase(clearEquipment.fulfilled, (state) => {
      state.selectedEquipment = null
    })
  },
})

const { reducer } = equipmentSlice
export default reducer
