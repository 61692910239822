import { ChangeEvent, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const paidType = [
  {
    value: 'Paid',
    label: 'Paid',
  },
  {
    value: 'Unpaid',
    label: 'Unpaid',
  },
]

const genderType = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

const marriedType = [
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'Married',
    label: 'Married',
  },
]

const religionType = [
  {
    value: 'Muslim',
    label: 'Muslim',
  },
  {
    value: 'Christian',
    label: 'Christian',
  },
]

const departmentType = [
  {
    value: 'HR',
    label: 'HR',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Engineering',
    label: 'Engineering',
  },
]

const effectiveType = [
  {
    value: 'Day(s)',
    label: 'Day(s)',
  },
  {
    value: 'Week(s)',
    label: 'Week(s)',
  },
  {
    value: 'Month(s)',
    label: 'Month(s)',
  },
  {
    value: 'Year(s)',
    label: 'Year(s)',
  },
]

const fromType = [
  {
    value: 'Date of Joining',
    label: 'Date of Joining',
  },
  {
    value: 'Date of Confirmation',
    label: 'Date of Confirmation',
  },
  {
    value: 'Other Specify',
    label: 'Other (specify)',
  },
]

const accrualType = [
  {
    value: 'One Time',
    label: 'One Time',
  },
  {
    value: 'Yearly',
    label: 'Yearly',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
  },
  {
    value: 'Half Yearly',
    label: 'Half Yearly',
  },
  {
    value: 'Triannually',
    label: 'Triannually',
  },
  {
    value: 'Quarterly',
    label: 'Quarterly',
  },
  {
    value: 'Bi Monthly',
    label: 'Bi Monthly',
  },
  {
    value: 'Semi Monthly',
    label: 'Semi Monthly',
  },
  {
    value: 'Bi Weekly',
    label: 'Bi Weekly',
  },
  {
    value: 'Weekly',
    label: 'Weekly',
  },
]

const onType = [
  {
    value: 'Last Day',
    label: 'Policy Date',
  },
  {
    value: 'Policy Date',
    label: 'Policy Date',
  },
  {
    value: 'Joining Date',
    label: 'Joining Date',
  },
  {
    value: 'Birth Date',
    label: 'Birth Date',
  },
  {
    value: 'Other day (specify)',
    label: 'Other day (specify)',
  },
]

const LeavePolicyDetails = () => {
  const [paid, setPaid] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [married, setMarried] = useState<string>('')
  const [religion, setReligion] = useState<string>('')
  const [department, setDepartment] = useState<string>('')
  const [effective, setEffective] = useState<string>('')
  const [from, setFrom] = useState<string>('')
  const [accrual, setAccrual] = useState<string>('')
  const [on, setOn] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const handlePaidChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPaid(event.target.value as string)
  }

  const handleMarriedChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMarried(event.target.value as string)
  }

  const handleGenderChange = (event: ChangeEvent<{ value: unknown }>) => {
    setGender(event.target.value as string)
  }

  const handleReligionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setReligion(event.target.value as string)
  }

  const handleDepartmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDepartment(event.target.value as string)
  }

  const handleEffectiveChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEffective(event.target.value as string)
  }

  const handleFromChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFrom(event.target.value as string)
  }

  const handleAccrualChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAccrual(event.target.value as string)
  }

  const handleOnChange = (event: ChangeEvent<{ value: unknown }>) => {
    setOn(event.target.value as string)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="New Leave Type"
            subtitle="Configure costume leave type as per company policy"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="leave-name"
                  variant="outlined"
                  required
                  id="leave-name"
                  label="Leave Name"
                />
                <TextField
                  id="leave-type"
                  select
                  variant="outlined"
                  required
                  label="Type"
                  value={paid}
                  onChange={handlePaidChange}
                >
                  {paidType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="description"
                  variant="outlined"
                  required
                  id="description"
                  label="Description"
                />
                <MobileDatePicker
                  label="Valid from this date"
                  inputFormat="dd MMM yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="Validity ends on"
                  inputFormat="dd MMM yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Designated Group" subtitle="Configure leave to a specific group" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="gender"
                  select
                  variant="outlined"
                  required
                  label="Gender"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  {genderType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="marital-status"
                  select
                  variant="outlined"
                  required
                  label="Marital Status"
                  value={married}
                  onChange={handleMarriedChange}
                >
                  {marriedType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="religion"
                  select
                  variant="outlined"
                  required
                  label="Religion"
                  value={religion}
                  onChange={handleReligionChange}
                >
                  {religionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="department"
                  select
                  variant="outlined"
                  required
                  label="Department"
                  value={department}
                  onChange={handleDepartmentChange}
                >
                  {departmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField name="role" variant="outlined" required id="Role" label="Role" />
                <TextField
                  name="location"
                  variant="outlined"
                  required
                  id="location"
                  label="Location"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Exceptions" subtitle="Configure applicable exclusions if any" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="department"
                  select
                  variant="outlined"
                  required
                  label="Department"
                  value={department}
                  onChange={handleDepartmentChange}
                >
                  {departmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField name="role" variant="outlined" required id="Role" label="Role" />
                <TextField
                  name="designation"
                  variant="outlined"
                  required
                  id="designation"
                  label="Designation"
                />
                <TextField
                  name="location"
                  variant="outlined"
                  required
                  id="location"
                  label="Location"
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Entitlement"
            subtitle="Configure applicable leave entitlement settings"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name=""
                  variant="outlined"
                  type="number"
                  required
                  id=""
                  label="Effective After"
                />
                <TextField
                  id=""
                  select
                  variant="outlined"
                  required
                  label=""
                  value={effective}
                  onChange={handleEffectiveChange}
                >
                  {effectiveType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="from"
                  select
                  variant="outlined"
                  required
                  label="From"
                  value={effective}
                  onChange={handleFromChange}
                >
                  {fromType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Accrual" subtitle="If accrual applicable, complete" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="accrues"
                  select
                  variant="outlined"
                  required
                  label="Accrues"
                  value={accrual}
                  onChange={handleAccrualChange}
                >
                  {accrualType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="on"
                  select
                  variant="outlined"
                  required
                  label="On"
                  value={on}
                  onChange={handleOnChange}
                >
                  {onType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MobileDatePicker
                  label="Validity ends on"
                  inputFormat="MMM"
                  views={['month']}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained">Create Policy</Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}

export default LeavePolicyDetails
