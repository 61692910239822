import { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  Node,
  Edge,
  Connection,
  NodeChange,
  EdgeChange,
  MarkerType,
} from 'reactflow'
import { useNavigate } from 'react-router-dom'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { v4 as uuidv4 } from 'uuid'

import 'reactflow/dist/style.css'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import CustomNode from '../../components/CustomNode'
import { primaryColour } from '../../utilities/Constants'
import EditNodeDialog from '../../components/EditNodeDialog'
import { INodeData } from '../../models/node'
import ProductionModeRequests from '../../server/productionMode'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllEquipment } from '../../slice/equipment'
import { getAllActivityAreas } from '../../slice/activityArea'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const edgeOptions = {
  animated: true,
  style: {
    strokeWidth: 2,
    stroke: primaryColour,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: primaryColour,
  },
}

const connectionLineStyle = {
  strokeWidth: 2,
  stroke: primaryColour,
}

const nodeTypes = {
  custom: CustomNode as any,
}

const ProductionModesDetails = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [activityAreaOptions, setActivityAreaOptions] = useState<any[]>([])
  const [equipmentOptions, setEquipmentOptions] = useState<any[]>([])
  const [functionalLocation, setFunctionalLocation] = useState<string>('')
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [selectedNode, setSelectedNode] = useState<INodeData | null>(null)
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const user = useAppSelector((state) => state.user).user
  const activityAreas = useAppSelector((state) => state.activityArea).activityAreas
  const equipment = useAppSelector((state) => state.equipment).equipment

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllEquipment({ token: user!.accessToken! })).unwrap()
        await dispatch(getAllActivityAreas({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempActivityAreaOptions: any = []
    activityAreas.forEach((item) => {
      tempActivityAreaOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setActivityAreaOptions(tempActivityAreaOptions)
  }, [activityAreas])

  useEffect(() => {
    const tempEquipmentOptions: any = []
    equipment.forEach((item) => {
      tempEquipmentOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setEquipmentOptions(tempEquipmentOptions)
  }, [equipment])

  useEffect(() => {
    const id = uuidv4()
    setNodes([
      {
        id,
        data: {
          title: 'Input Material',
          nodeType: 'input',
          equipmentType: 'feed',
          onSettingsPress: (e: React.MouseEvent<HTMLButtonElement>) => handlePopoverOpen(e, id),
        },
        position: { x: 300, y: 0 },
        type: 'custom',
      },
    ])
  }, [])

  useEffect(() => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === selectedNodeId) {
        setSelectedNode(nodes[i].data)
      }
    }
  }, [nodes, selectedNodeId])

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  )

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  )

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleFunctionalLocationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFunctionalLocation(event.target.value as string)
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget)
    setPopoverOpen(true)

    setSelectedNodeId(id)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setSelectedNodeId(null)
    setPopoverOpen(false)
  }

  const addNode = useCallback(() => {
    setNodes((nodes) => {
      const id = uuidv4()
      return [
        ...nodes,
        {
          id,
          position: { x: 100, y: 0 },
          data: {
            title: 'New Item',
            nodeType: 'middle',
            equipmentType: 'custom',
            onSettingsPress: (e: React.MouseEvent<HTMLButtonElement>) => handlePopoverOpen(e, id),
          },
          type: 'custom',
        },
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditNodeDetails = () => {
    setPopoverOpen(false)
    setShowDialog(true)
  }

  const handleEditNodePress = (data: INodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.data = {
            ...node.data,
            nodeType: node.data.nodeType,
            description: data.description,
            equipmentId: data.equipmentId,
            equipmentType: data.equipmentType,
            feedRate: data.feedRate,
            rockSize: data.rockSize,
            title: data.title,
          }
        }

        return node
      }),
    )

    setShowDialog(false)
  }

  const onAddMode = async () => {
    const tempEdges: any[] = edges.map((edge) => {
      const data: any = { ...edge }
      data.edgeId = edge.id
      data.id = undefined

      return data
    })

    const tempNodes: any[] = nodes.map((node) => {
      const data: any = { ...node }
      data.nodeId = node.id
      data.id = undefined

      return data
    })

    const payload = {
      edges: tempEdges,
      nodes: tempNodes,
      name,
      createdBy: user!.id,
      description,
      functionalLocationId: functionalLocation,
    }

    const response = await ProductionModeRequests.create(payload)

    if (response.status === 201) {
      return navigate('../production-modes/')
    }

    setErrorMessage(response.data.error.message)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      {showDialog && selectedNode && (
        <EditNodeDialog
          data={selectedNode}
          equipmentOptions={equipmentOptions}
          onCancelPress={() => setShowDialog(false)}
          onEditPress={handleEditNodePress}
        />
      )}
      <Popover
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton>
            <ListItemText primary="Delete" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="Edit Details" onClick={handleEditNodeDetails} />
          </ListItemButton>
        </List>
      </Popover>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Mode" subtitle="Enter mode basic details" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  variant="outlined"
                  required
                  label="Name"
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  variant="outlined"
                  required
                  label="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Reference Activity Area"
                  onChange={handleFunctionalLocationChange}
                >
                  {activityAreaOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Configuration" subtitle="Enter configuration for the mode" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <div style={{ height: '450px', width: '100%' }}>
              <Button variant="contained" onClick={addNode}>
                Add Item
              </Button>
              <ReactFlow
                zoomOnScroll={false}
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                defaultEdgeOptions={edgeOptions}
                connectionLineStyle={connectionLineStyle}
                nodeTypes={nodeTypes}
              >
                <Background />
                <Controls />
              </ReactFlow>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddMode}>
          Add Mode
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default ProductionModesDetails
