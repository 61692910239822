import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IChecklist } from '../models/checklist'
import ChecklistRequests, { IChecklistCreate, IChecklistGet } from '../server/checklist'
import { formatApiError } from '../utilities/Helpers'

interface IChecklistState {
  selectedChecklist: IChecklist | null
  checklists: IChecklist[]
}

export const getChecklist = createAsyncThunk(
  'checklist/getChecklist',
  async (data: IChecklistGet, thunkAPI) => {
    try {
      const response = await ChecklistRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllChecklists = createAsyncThunk(
  'checklist/getAllChecklists',
  async (data: IChecklistGet, thunkAPI) => {
    try {
      const response = await ChecklistRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createChecklist = createAsyncThunk(
  'checklist/createChecklist',
  async (data: IChecklistCreate, thunkAPI) => {
    try {
      await ChecklistRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearChecklist = createAsyncThunk('checklist/clearChecklist', () => {})

const initialState: IChecklistState = {
  selectedChecklist: null,
  checklists: [],
}

const checklistSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllChecklists.fulfilled, (state, { payload }) => {
      state.checklists = payload.items
    })
    builder.addCase(getChecklist.fulfilled, (state, { payload }) => {
      state.selectedChecklist = payload.item
    })
    builder.addCase(clearChecklist.fulfilled, (state) => {
      state.selectedChecklist = null
    })
  },
})

const { reducer } = checklistSlice
export default reducer
