import { Card } from '@mui/material'
import { FC } from 'react'

import { IStockpile } from '../../models/stockpiles'
import StockpilesTable from './StockpilesTable'

interface TertiaryProps {
  stockpiles: IStockpile[]
}

const Tertiary: FC<TertiaryProps> = ({ stockpiles }) => {
  return (
    <Card>
      <StockpilesTable stockpiles={stockpiles} />
    </Card>
  )
}

export default Tertiary
