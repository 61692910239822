import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import AuthRequests, { IAuthSignIn } from '../server/authentication'
import { IUser } from '../models/users'
import UserRequests, { IUserCreate, IUserGet } from '../server/user'
import { formatApiError } from '../utilities/Helpers'

interface IUserState {
  selectedUser: IUser | null
  user: IUser | null
  users: IUser[]
}

const userString = localStorage.getItem('user')
const user = userString ? JSON.parse(userString) : null

export const signIn = createAsyncThunk('user/signIn', async (data: IAuthSignIn, thunkAPI) => {
  try {
    const response = await AuthRequests.signIn(data)

    return {
      user: response.data,
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(formatApiError(error))
  }
})

export const logout = createAsyncThunk('user/logout', () => {})

export const getUser = createAsyncThunk('user/getUser', async (data: IUserGet, thunkAPI) => {
  try {
    const response = await UserRequests.get(data)

    return {
      item: response.data,
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(formatApiError(error))
  }
})

export const getAllUsers = createAsyncThunk(
  'user/getAllUsers',
  async (data: IUserGet, thunkAPI) => {
    try {
      const response = await UserRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createUser = createAsyncThunk(
  'user/createUser',
  async (data: IUserCreate, thunkAPI) => {
    try {
      await UserRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearSelectedUser = createAsyncThunk('user/clearSelectedUser', () => {})

const initialState: IUserState = {
  selectedUser: null,
  user,
  users: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.user = payload.user
      localStorage.setItem('user', JSON.stringify(payload.user))
    })
    builder.addCase(signIn.rejected, (state) => {
      state.user = null
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null
      localStorage.removeItem('user')
    })
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.users = payload.items
    })
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.selectedUser = payload.item
    })
    builder.addCase(clearSelectedUser.fulfilled, (state) => {
      state.selectedUser = null
    })
  },
})

const { reducer } = userSlice
export default reducer
