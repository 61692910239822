import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import LeavePolicyDetails from './LeavePolicyDetails'

const AddLeavePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Add Leave Policy</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <LeavePolicyDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddLeavePolicy
