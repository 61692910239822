import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function CAPEX() {
  return (
    <>
      <Helmet>
        <title>CAPEX</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="CAPEX" subHeading="These are CAPEX." />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default CAPEX
