import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ReportDetails from './ReportDetails'

const AddReport = () => {
  return (
    <>
      <Helmet>
        <title>Add Works Report</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ReportDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddReport
