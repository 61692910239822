import axios from 'axios'

import helpers from './helpers'
import { ChecklistType, ITask } from '../models/checklist'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IChecklistCreate {
  description: string
  name: string
  permissionId: string
  tasks: ITask[]
  token: string
  type: ChecklistType
}

export interface IChecklistGet {
  id?: string
  token: string
}

export default class ChecklistRequests {
  static create = (data: IChecklistCreate) => {
    return axios.post(
      `${domain}/checklist`,
      {
        description: data.description,
        name: data.name,
        permissionId: data.permissionId,
        tasks: data.tasks,
        type: data.type,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IChecklistGet) => {
    return axios.get(`${domain}/checklist`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IChecklistGet) => {
    return axios.get(`${domain}/checklist/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
