import axios from 'axios'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

interface IRawMaterialCreate {
  createdBy: string
  name: string
  quantityAvailable: number
  yearToExplore: number
}

export default class RawMaterialRequests {
  static create = async (data: IRawMaterialCreate) => {
    try {
      const response = await axios.post(`${domain}/raw-material`, data)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }

  static getAll = async () => {
    try {
      const response = await axios.get(`${domain}/raw-material`)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
