import { ChangeEvent, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`,
)

function SaleStockpiles() {
  const [currentTab, setCurrentTab] = useState<string>('available_products')

  const tabs = [
    { value: 'available_products', label: 'Available Products' },
    { value: 'total_produce_on_month', label: 'Total Produce on Month' },
    { value: 'recycled', label: 'Recycled/Internal Use' },
    { value: 'monthly_unsellable_material', label: 'Monthly Unsellable Material' },
  ]

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  return (
    <>
      <Helmet>
        <title>Sale Stockpiles</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Sale Stockpiles" subHeading="These are your sale stockpiles." />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12}>
            {currentTab === 'available_products' && <div />}
            {currentTab === 'total_produce_on_month' && <div />}
            {currentTab === 'recycled' && <div />}
            {currentTab === 'monthly_unsellable_material' && <div />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SaleStockpiles
