import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface ICostCentreCreate {
  description: string
  name: string
  token: string
}

export interface ICostCentreGet {
  id?: string
  token: string
}

export default class CostCentreRequests {
  static create = (data: ICostCentreCreate) => {
    return axios.post(
      `${domain}/cost-centre`,
      {
        name: data.name,
        description: data.description,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: ICostCentreGet) => {
    return axios.get(`${domain}/cost-centre`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: ICostCentreGet) => {
    return axios.get(`${domain}/cost-centre/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
