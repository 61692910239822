import { Card, CardContent, Grid } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import ReactFlow, {
  Controls,
  Background,
  Node,
  Edge,
  MarkerType,
  NodeChange,
  applyNodeChanges,
  EdgeChange,
  applyEdgeChanges,
  Connection,
  addEdge,
} from 'reactflow'

import 'reactflow/dist/style.css'
import CustomNode from '../../components/CustomNode'

import { IProductionMode } from '../../models/productionMode'
import EdgeRequests from '../../server/edge'
import NodeRequests from '../../server/node'
import { primaryColour } from '../../utilities/Constants'

interface ProductionModeProps {
  productionMode: IProductionMode
}

const edgeOptions = {
  animated: true,
  style: {
    strokeWidth: 2,
    stroke: primaryColour,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: primaryColour,
  },
}

const connectionLineStyle = {
  strokeWidth: 2,
  stroke: primaryColour,
}

const nodeTypes = {
  custom: CustomNode as any,
}

const ProductionModeDetails: FC<ProductionModeProps> = ({ productionMode }) => {
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])

  useEffect(() => {
    getEdges()
    getNodes()
  }, [])

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  )

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  )

  const getNodes = async () => {
    const response = await NodeRequests.getAll(productionMode.id)

    if (response.status === 200) {
      setNodes(response.data)
    }
  }

  const getEdges = async () => {
    const response = await EdgeRequests.getAll(productionMode.id)

    if (response.status === 200) {
      setEdges(response.data)
    }
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent sx={{ p: 2 }}>
          <div style={{ height: '450px', width: '100%' }}>
            <ReactFlow
              zoomOnScroll={false}
              nodes={nodes}
              onNodesChange={onNodesChange}
              edges={edges}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              defaultEdgeOptions={edgeOptions}
              connectionLineStyle={connectionLineStyle}
              nodeTypes={nodeTypes}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ProductionModeDetails
