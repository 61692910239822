import { AnyAction, ThunkDispatch, combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import userReducer from './slice/user'
import departmentReducer from './slice/department'
import equipmentReducer from './slice/equipment'
import activityAreaReducer from './slice/activityArea'
import jobCardReducer from './slice/jobCard'
import jobCardAmendmentReducer from './slice/jobCardAmendment'
import bomReducer from './slice/bom'
import maintenanceRequestReducer from './slice/maintenanceRequest'
import costCentreReducer from './slice/costCentre'
import permissionReducer from './slice/permission'
import checklistReducer from './slice/checklist'
import routineMaintenanceReducer from './slice/routineMaintenance'
import completedChecklistReducer from './slice/completedChecklist'
import preWorkReducer from './slice/preWork'

const reducers = combineReducers({
  user: userReducer,
  department: departmentReducer,
  equipment: equipmentReducer,
  activityArea: activityAreaReducer,
  jobCard: jobCardReducer,
  jobCardAmendment: jobCardAmendmentReducer,
  bom: bomReducer,
  maintenanceRequest: maintenanceRequestReducer,
  costCentre: costCentreReducer,
  permission: permissionReducer,
  checklist: checklistReducer,
  routineMaintenance: routineMaintenanceReducer,
  completedChecklist: completedChecklistReducer,
  preWork: preWorkReducer,
})

export const store = configureStore({
  devTools: false,
  reducer: reducers,
})

type AppState = ReturnType<typeof reducers>
type AppThunkDispatch = ThunkDispatch<AppState, any, AnyAction>
export const useAppDispatch = () => useDispatch<AppThunkDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
