import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function Notifications() {
  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Notifications" subHeading="These are your notifications." />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default Notifications
