import ConstructionTwoToneIcon from '@mui/icons-material/ConstructionTwoTone'
import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone'
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone'
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone'
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone'
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone'
import LandscapeTwoToneIcon from '@mui/icons-material/LandscapeTwoTone'
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone'
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone'
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone'
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone'
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone'
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone'
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone'
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone'
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone'
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone'
import RoomPreferencesTwoToneIcon from '@mui/icons-material/RoomPreferencesTwoTone'
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone'
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone'
import RvHookupTwoToneIcon from '@mui/icons-material/RvHookupTwoTone'
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone'
import PolicyTwoToneIcon from '@mui/icons-material/PolicyTwoTone'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'

export interface MenuItem {
  link?: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }
  badge?: string
  items?: MenuItem[]
  name: string
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Logistics',
        icon: LocalShippingTwoToneIcon,
        link: '/dashboards/logistics',
      },
      {
        name: 'Projects',
        icon: DesignServicesTwoToneIcon,
        link: '/dashboards/projects',
      },
      {
        name: 'Maintenance',
        icon: ConstructionTwoToneIcon,
        link: '/dashboards/maintenance',
      },
      {
        name: 'Production',
        icon: LayersTwoToneIcon,
        link: '/dashboards/production',
      },
      {
        name: 'Sales',
        icon: BarChartTwoToneIcon,
        link: '/dashboards/sales',
      },
      {
        name: 'HR',
        icon: PeopleTwoToneIcon,
        link: '/dashboards/hr',
      },
    ],
  },
  {
    heading: 'Account',
    items: [
      {
        name: 'Notifications',
        icon: NotificationsActiveTwoToneIcon,
        link: '/account/notifications',
      },
      {
        name: 'Tasks',
        icon: TableChartTwoToneIcon,
        link: '/account/tasks',
      },
      {
        name: 'Profile',
        icon: AccountCircleTwoToneIcon,
        link: '/account/profile',
      },
      {
        name: 'Leave',
        icon: TableChartTwoToneIcon,
        link: '/account/leave',
      },
    ],
  },
  {
    heading: 'Logistics',
    items: [
      {
        name: 'Vehicles',
        icon: LocalShippingTwoToneIcon,
        link: '/logistics/vehicles',
      },
    ],
  },
  {
    heading: 'Projects',
    items: [
      {
        name: 'OPEX',
        icon: RequestQuoteTwoToneIcon,
        link: '/projects/opex',
      },
      {
        name: 'CAPEX',
        icon: RequestQuoteTwoToneIcon,
        link: '/projects/capex',
      },
    ],
  },
  {
    heading: 'Maintenance',
    items: [
      {
        name: 'Activity Areas',
        icon: GpsFixedTwoToneIcon,
        link: '/maintenance/activity-area',
      },
      {
        name: 'Equipment',
        icon: RoomPreferencesTwoToneIcon,
        link: '/maintenance/equipment',
      },
      {
        name: 'Inspections',
        icon: ConstructionTwoToneIcon,
        link: '/maintenance/inspection',
      },
      // {
      //   name: 'Breakdowns',
      //   icon: EngineeringTwoToneIcon,
      //   link: '/maintenance/breakdowns',
      // },
      {
        name: 'Maintenance',
        icon: ConstructionTwoToneIcon,
        link: '/maintenance/planned-maintenance',
      },
      {
        name: 'Reports',
        icon: RequestQuoteTwoToneIcon,
        link: '/maintenance/report',
      },
      {
        name: 'Suppliers',
        icon: GroupsTwoToneIcon,
        link: '/maintenance/suppliers',
      },
      // {
      //   name: 'Energy',
      //   icon: BoltTwoToneIcon,
      //   link: '/maintenance/energy',
      // },
    ],
  },
  {
    heading: 'Production',
    items: [
      {
        name: 'Raw Materials',
        icon: LandscapeTwoToneIcon,
        link: '/production/raw-materials',
      },
      {
        name: 'Production Output',
        icon: ReceiptLongTwoToneIcon,
        link: '/production/production-output',
      },
      {
        name: 'Production Modes',
        icon: LandscapeTwoToneIcon,
        link: '/production/production-modes',
      },
      {
        name: 'Stockpiles',
        icon: InventoryTwoToneIcon,
        link: '/production/stockpiles',
      },
      // {
      //   name: 'Quarry Pit',
      //   icon: LandscapeTwoToneIcon,
      //   link: '/production/quarry-pit',
      // },
      // {
      //   name: 'Crushing and Screening',
      //   icon: PrecisionManufacturingTwoToneIcon,
      //   link: '/production/crushing-and-screening',
      // },
      // {
      //   name: 'Earth Moving Equipment',
      //   icon: PublicTwoToneIcon,
      //   link: '/production/earth-moving-equipment',
      // },
      // {
      //   name: 'Load and Hauling',
      //   icon: RvHookupTwoToneIcon,
      //   link: '/production/load-and-hauling',
      // },
    ],
  },
  {
    heading: 'Sales',
    items: [
      {
        name: 'Stockpiles',
        icon: InventoryTwoToneIcon,
        link: '/sales/stockpiles',
      },
      {
        name: 'Customers',
        icon: GroupsTwoToneIcon,
        link: '/sales/customers',
      },
      {
        name: 'Actual',
        icon: ReceiptLongTwoToneIcon,
        link: '/sales/actual',
      },
      {
        name: 'Planned',
        icon: PendingActionsTwoToneIcon,
        link: '/sales/planned',
      },
    ],
  },
  {
    heading: 'HR',
    items: [
      {
        name: 'Manage Users',
        icon: ManageAccountsTwoToneIcon,
        link: '/hr/manage-users',
      },
      {
        name: 'Departments',
        icon: LayersTwoToneIcon,
        link: '/hr/departments',
      },
      {
        name: 'Cost Centres',
        icon: LayersTwoToneIcon,
        link: '/hr/cost-centres',
      },
      {
        name: 'Permissions',
        icon: PolicyTwoToneIcon,
        link: '/hr/permissions',
      },
      {
        name: 'Leave Policies',
        icon: PolicyTwoToneIcon,
        link: '/hr/leave-policies',
      },
      {
        name: 'Shift Manager',
        icon: ContactsTwoToneIcon,
        link: '/hr/shift-manager',
      },
    ],
  },
]

export default menuItems
