import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IActivityArea } from '../models/activityArea'
import ActivityAreaRequests, { IActivityAreaCreate, IActivityAreaGet } from '../server/activityArea'
import { formatApiError } from '../utilities/Helpers'

interface IActivityAreaState {
  selectedActivityArea: IActivityArea | null
  activityAreas: IActivityArea[]
}

export const getActivityArea = createAsyncThunk(
  'activityArea/getActivityArea',
  async (data: IActivityAreaGet, thunkAPI) => {
    try {
      const response = await ActivityAreaRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllActivityAreas = createAsyncThunk(
  'activityArea/getAllActivityAreas',
  async (data: IActivityAreaGet, thunkAPI) => {
    try {
      const response = await ActivityAreaRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createActivityArea = createAsyncThunk(
  'activityArea/createActivityArea',
  async (data: IActivityAreaCreate, thunkAPI) => {
    try {
      await ActivityAreaRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearActivityArea = createAsyncThunk('activityArea/clearActivityArea', () => {})

const initialState: IActivityAreaState = {
  selectedActivityArea: null,
  activityAreas: [],
}

const activityAreaSlice = createSlice({
  name: 'activityArea',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActivityAreas.fulfilled, (state, { payload }) => {
      state.activityAreas = payload.items
    })
    builder.addCase(getActivityArea.fulfilled, (state, { payload }) => {
      state.selectedActivityArea = payload.item
    })
    builder.addCase(clearActivityArea.fulfilled, (state) => {
      state.selectedActivityArea = null
    })
  },
})

const { reducer } = activityAreaSlice
export default reducer
