import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ICompletedChecklist } from '../models/completedChecklist'
import CompletedChecklistRequests, {
  ICompletedChecklistCreate,
  ICompletedChecklistGet,
} from '../server/completedChecklist'
import { formatApiError } from '../utilities/Helpers'

interface ICompletedChecklistState {
  selectedCompletedChecklist: ICompletedChecklist | null
  completedChecklists: ICompletedChecklist[]
}

export const getCompletedChecklist = createAsyncThunk(
  'completedChecklist/getCompletedChecklist',
  async (data: ICompletedChecklistGet, thunkAPI) => {
    try {
      const response = await CompletedChecklistRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllCompletedChecklists = createAsyncThunk(
  'completedChecklist/getAllCompletedChecklists',
  async (data: ICompletedChecklistGet, thunkAPI) => {
    try {
      const response = await CompletedChecklistRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createCompletedChecklist = createAsyncThunk(
  'completedChecklist/createCompletedChecklist',
  async (data: ICompletedChecklistCreate, thunkAPI) => {
    try {
      await CompletedChecklistRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearCompletedChecklist = createAsyncThunk(
  'completedChecklist/clearCompletedChecklist',
  () => {},
)

const initialState: ICompletedChecklistState = {
  selectedCompletedChecklist: null,
  completedChecklists: [],
}

const completedChecklistSlice = createSlice({
  name: 'completedChecklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCompletedChecklists.fulfilled, (state, { payload }) => {
      state.completedChecklists = payload.items
    })
    builder.addCase(getCompletedChecklist.fulfilled, (state, { payload }) => {
      state.selectedCompletedChecklist = payload.item
    })
    builder.addCase(clearCompletedChecklist.fulfilled, (state) => {
      state.selectedCompletedChecklist = null
    })
  },
})

const { reducer } = completedChecklistSlice
export default reducer
