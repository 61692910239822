import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, Grid, Button } from '@mui/material'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'

interface PageTitleProps {
  heading?: string
  subHeading?: string
  showButton?: boolean
  buttonText?: string
  link?: string
  onPress?: () => void
}

const PageTitle: FC<PageTitleProps> = ({
  heading = '',
  subHeading = '',
  showButton = false,
  buttonText = '',
  link = '',
  onPress,
  ...rest
}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" {...rest}>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="subtitle2">{subHeading}</Typography>
      </Grid>
      {showButton && (
        <Grid item>
          <Button
            component={NavLink}
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
            onClick={() => onPress && onPress()}
            to={link}
          >
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

PageTitle.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
}

export default PageTitle
