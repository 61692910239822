import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ShiftDetails from './ShiftDetails'

const AddShift = () => {
  return (
    <>
      <Helmet>
        <title>Add Shift</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ShiftDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddShift
