import { Helmet } from 'react-helmet-async'
import { Container, Grid } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import AllProductionModes from './AllProductionModes'
import Footer from '../../components/Footer'

function ProductionModes() {
  return (
    <>
      <Helmet>
        <title>Production Modes</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Production Modes"
          subHeading="Available production modes"
          buttonText="Create Mode"
          showButton
          link ='add'
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <AllProductionModes />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  )
}

export default ProductionModes
