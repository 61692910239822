import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ChecklistDetails from './ChecklistDetails'

const AddChecklist = () => {
  return (
    <>
      <Helmet>
        <title>Add Checklist</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ChecklistDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddChecklist
