import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ActualMaintenanceDetails from './ActualMaintenanceDetails'

const AddActualMaintenance = () => {
  return (
    <>
      <Helmet>
        <title>Add Equipment</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ActualMaintenanceDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddActualMaintenance