import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import ProductionOutputDetails from './ProductionOutputDetails'

const AddProductionOutput = () => {
  return (
    <>
      <Helmet>
        <title>Production Performance</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <ProductionOutputDetails />
        </Grid>
      </Container>
    </>
  )
}

export default AddProductionOutput
