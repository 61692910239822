import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { MobileDatePicker } from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { useAppDispatch, useAppSelector } from '../../store'
import { getAllCostCentres } from '../../slice/costCentre'
import { createActivityArea } from '../../slice/activityArea'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const ActivityAreasDetails = () => {
  const [name, setName] = useState<string>('')
  const [refId, setRefId] = useState<string>('')
  const [costCentreId, setCostCentreId] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [costCentreOptions, setCostCentreOptions] = useState<any[]>([])

  const user = useAppSelector((state) => state.user).user
  const costCentres = useAppSelector((state) => state.costCentre).costCentres

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getAllCostCentres({ token: user!.accessToken! })).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()
  }, [dispatch, navigate, user])

  useEffect(() => {
    const tempCostCentreOptions: any = []
    costCentres.forEach((item) => {
      tempCostCentreOptions.push({
        value: item.id,
        label: item.name,
      })
    })

    setCostCentreOptions(tempCostCentreOptions)
  }, [costCentres])

  const handleNameChange = (event: ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const handleRefIdChange = (event: ChangeEvent<{ value: unknown }>) => {
    setRefId(event.target.value as string)
  }

  const handleCostCentreChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCostCentreId(event.target.value as string)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date as Date)
  }

  const onAddFunctionLocation = async () => {
    const payload = {
      costCentreId,
      initialDate: selectedDate,
      name,
      refId,
      token: user!.accessToken!,
    }

    try {
      await dispatch(createActivityArea(payload)).unwrap()
      navigate('../activity-area/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Activity Area" subtitle="" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  name="functional-name"
                  variant="outlined"
                  required
                  id="functional-name"
                  label="Enter Activity Area Name"
                  onChange={handleNameChange}
                />
                {/* <TextField
                  id="reference-location"
                  select
                  variant="outlined"
                  required
                  label="Select Reference Activity Area"
                  value={superiorLocation}
                  onChange={handleSuperiorLocationChange}
                >
                  {superiorType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
                <TextField
                  id="reference"
                  variant="outlined"
                  required
                  label="Enter Reference ID"
                  onChange={handleRefIdChange}
                />
                <MobileDatePicker
                  label="Initial Date"
                  inputFormat="dd MMM yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                    <TextField {...params} />
                  )}
                />
                <TextField
                  select
                  variant="outlined"
                  required
                  label="Select Cost Centre"
                  onChange={handleCostCentreChange}
                >
                  {costCentreOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={onAddFunctionLocation}>
          Create Activity Area
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}
export default ActivityAreasDetails
