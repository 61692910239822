import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IMaintenanceRequest } from '../models/maintenanceRequest'
import MaintenanceRequests, {
  IMaintenanceCreate,
  IMaintenanceGet,
} from '../server/maintenanceRequest'
import { formatApiError } from '../utilities/Helpers'

interface IMaintenanceRequestState {
  selectedMaintenanceRequest: IMaintenanceRequest | null
  maintenanceRequests: IMaintenanceRequest[]
}

export const getMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/getMaintenanceRequest',
  async (data: IMaintenanceGet, thunkAPI) => {
    try {
      const response = await MaintenanceRequests.get(data)

      return {
        item: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const getAllMaintenanceRequests = createAsyncThunk(
  'maintenanceRequest/getAllMaintenanceRequests',
  async (data: IMaintenanceGet, thunkAPI) => {
    try {
      const response = await MaintenanceRequests.getAll(data)

      return {
        items: response.data,
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const createMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequest',
  async (data: IMaintenanceCreate, thunkAPI) => {
    try {
      await MaintenanceRequests.create(data)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(formatApiError(error))
    }
  },
)

export const clearMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/clearMaintenanceRequest',
  () => {},
)

const initialState: IMaintenanceRequestState = {
  selectedMaintenanceRequest: null,
  maintenanceRequests: [],
}

const maintenanceRequestSlice = createSlice({
  name: 'maintenanceRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMaintenanceRequests.fulfilled, (state, { payload }) => {
      state.maintenanceRequests = payload.items
    })
    builder.addCase(getMaintenanceRequest.fulfilled, (state, { payload }) => {
      state.selectedMaintenanceRequest = payload.item
    })
    builder.addCase(clearMaintenanceRequest.fulfilled, (state) => {
      state.selectedMaintenanceRequest = null
    })
  },
})

const { reducer } = maintenanceRequestSlice
export default reducer
