import { FC, ChangeEvent, useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'
import { NavLink } from 'react-router-dom'

import { IJobCard, JobCardStatus } from '../../models/jobCard'
import Label from '../../components/Label'

interface WorksOrderTableProps {
  className?: string
  data: IJobCard[]
}

const getStatusLabel = (status: JobCardStatus): JSX.Element => {
  const map = {
    open: {
      text: 'Open',
      color: 'error',
    },
    closed: {
      text: 'Closed',
      color: 'success',
    },
    pending: {
      text: 'Pending',
      color: 'warning',
    },
  }

  const { text, color }: any = map[status]

  return <Label color={color}>{text}</Label>
}

const JobCardTable: FC<WorksOrderTableProps> = ({ data }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const theme = useTheme()

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Job Card Number</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Priority</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => {
              return (
                <TableRow hover key={item.id}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.worksOrderNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.startDate?.toDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.endDate?.toDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {`${item.time} hours`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.priority}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {format(new Date(item.createdAt), 'dd MMMM yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{getStatusLabel(item.status)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Job Card" arrow>
                      <IconButton
                        component={NavLink}
                        sx={{
                          '&:hover': {
                            background: theme.colors.success.lighter,
                          },
                          color: theme.colors.success.main,
                        }}
                        color="inherit"
                        size="small"
                        to="add-job-card"
                        state={{ worksOrder: item }}
                      >
                        <VisibilityTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  )
}

JobCardTable.propTypes = {
  data: PropTypes.array.isRequired,
}

JobCardTable.defaultProps = {
  data: [],
}

export default JobCardTable
