import { forwardRef, useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'

import './style.css'

import { emailRegEx } from '../../utilities/Constants'
import { useAppDispatch, useAppSelector } from '../../store'
import { signIn } from '../../slice/user'
import { IApiError } from '../../models/helpers'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function SignIn() {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailError, setEmailError] = useState<string>()
  const [passwordError, setPasswordError] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const userState = useAppSelector((state) => state.user).user

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const classes = useStyles()

  useEffect(() => {
    if (userState) {
      navigate('../account', { replace: true })
    }
  }, [navigate, userState])

  const onEmailChangeText = (text: string) => {
    setEmail(text)

    // RegEx to check if the email is of the correct form
    setEmailError(emailRegEx.test(text) ? undefined : 'Valid email is required')
  }

  const onPasswordChangeText = (text: string) => {
    setPassword(text)

    // RegEx to check if the password has been entered
    setPasswordError(text ? undefined : 'Password is required')
  }

  const onSignIn = async () => {
    const payload = {
      email,
      password,
    }

    try {
      await dispatch(signIn(payload)).unwrap()
      navigate('../account/')
    } catch (error) {
      setErrorMessage((error as IApiError).message)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            defaultValue={email}
            helperText={emailError}
            error={emailError ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => onEmailChangeText(e.target.value)}
          />
          <TextField
            defaultValue={password}
            helperText={passwordError}
            error={passwordError ? true : false}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => onPasswordChangeText(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSignIn}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default SignIn
