import { forwardRef, useEffect, useState } from 'react'
import { Container, Grid, Snackbar } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import MaintenanceRequestDetails from './MaintenanceRequestDetails'
import { useAppDispatch, useAppSelector } from '../../store'
import { clearMaintenanceRequest, getMaintenanceRequest } from '../../slice/maintenanceRequest'
import { IApiError } from '../../models/helpers'
import { getAllUsers, logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const ViewMaintenanceRequest = () => {
  const params = useParams()

  const [errorMessage, setErrorMessage] = useState<string>()

  const user = useAppSelector((state) => state.user).user
  const users = useAppSelector((state) => state.user).users
  const maintenanceRequest = useAppSelector(
    (state) => state.maintenanceRequest,
  ).selectedMaintenanceRequest

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(
          getMaintenanceRequest({
            id: params.id || '',
            token: user!.accessToken!,
          }),
        ).unwrap()
        await dispatch(
          getAllUsers({
            id: params.id || '',
            token: user!.accessToken!,
          }),
        ).unwrap()
      } catch (error) {
        if ((error as IApiError).status !== 401) {
          return setErrorMessage((error as IApiError).message)
        }

        dispatch(logout()).unwrap()
        navigate('/')
      }
    })()

    return () => {
      dispatch(clearMaintenanceRequest()).unwrap()
    }
  }, [dispatch, navigate, params.id, user])

  return (
    <>
      <Helmet>
        <title>{maintenanceRequest?.title || 'Maintenance Request'}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading={maintenanceRequest?.title || ''} />
      </PageTitleWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={errorMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {maintenanceRequest && (
            <MaintenanceRequestDetails
              data={maintenanceRequest}
              isAssigningUser={maintenanceRequest.status === 'open'}
              users={users}
            />
          )}
        </Grid>
      </Container>
    </>
  )
}

export default ViewMaintenanceRequest
