import { FC, ChangeEvent, useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  SelectChangeEvent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'
import { NavLink } from 'react-router-dom'

import Label from '../../components/Label'
import { ChecklistType } from '../../models/checklist'
import { ICompletedChecklist } from '../../models/completedChecklist'

interface CompletedChecklistTableProps {
  className?: string
  data: ICompletedChecklist[]
}

interface Filters {
  type: ChecklistType | null
}

const getTypeLabel = (type: ChecklistType): JSX.Element => {
  const map = {
    'pre-check': {
      text: 'Pre Check',
      color: 'error',
    },
    'peri-check': {
      text: 'During',
      color: 'warning',
    },
    'post-check': {
      text: 'Post Check',
      color: 'success',
    },
  }

  const { text, color }: any = map[type]

  return <Label color={color}>{text}</Label>
}

const applyFilters = (completedChecklists: ICompletedChecklist[], filters: Filters) => {
  return completedChecklists.filter((completedChecklist) => {
    let matches = true

    if (filters.type && completedChecklist.checklist.type !== filters.type) {
      matches = false
    }

    return matches
  })
}

const applyPagination = (
  completedChecklists: ICompletedChecklist[],
  page: number,
  limit: number,
) => {
  return completedChecklists.slice(page * limit, page * limit + limit)
}

const CompletedChecklistTable: FC<CompletedChecklistTableProps> = ({ data }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [filters, setFilters] = useState<Filters>({
    type: null,
  })

  const typeOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'pre-check',
      name: 'Pre Check',
    },
    {
      id: 'peri-check',
      name: 'During',
    },
    {
      id: 'post-check',
      name: 'Post Check',
    },
  ]

  const handleTypeChange = (e: SelectChangeEvent<string>): void => {
    let value: ChecklistType | null = null

    if (e.target.value !== 'all') {
      value = e.target.value as ChecklistType
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      type: value,
    }))
  }

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }
  const filteredCompletedChecklists = applyFilters(data, filters)
  const paginatedCompletedChecklists = applyPagination(filteredCompletedChecklists, page, limit)
  const theme = useTheme()

  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Type</InputLabel>
              <Select
                value={filters.type || 'all'}
                onChange={(event) => handleTypeChange(event)}
                label="Type"
                autoWidth
              >
                {typeOptions.map((typeOption) => (
                  <MenuItem key={typeOption.id} value={typeOption.id}>
                    {typeOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Comment</TableCell>
              <TableCell align="center">Completed By</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCompletedChecklists.map((completedChecklist) => {
              return (
                <TableRow hover key={completedChecklist.id}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {completedChecklist.checklist.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {format(new Date(completedChecklist.createdAt), 'MMMM dd yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {completedChecklist.comment}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {`${completedChecklist.createdBy.name} ${completedChecklist.createdBy.surname}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {getTypeLabel(completedChecklist.checklist.type)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={`View ${completedChecklist.checklist.name}`} arrow>
                      <IconButton
                        component={NavLink}
                        sx={{
                          '&:hover': {
                            background: theme.colors.success.lighter,
                          },
                          color: theme.colors.success.main,
                        }}
                        color="inherit"
                        size="small"
                        to={`completed-checklist/${completedChecklist.id}`}
                      >
                        <VisibilityTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCompletedChecklists.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  )
}

CompletedChecklistTable.propTypes = {
  data: PropTypes.array.isRequired,
}

CompletedChecklistTable.defaultProps = {
  data: [],
}

export default CompletedChecklistTable
