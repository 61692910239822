import { ChangeEvent, forwardRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../store'
import { createMaintenanceRequest } from '../../slice/maintenanceRequest'
import { IApiError } from '../../models/helpers'
import { logout } from '../../slice/user'

const Alert = forwardRef(function Alert(props: AlertProps, ref) {
  return <MuiAlert elevation={6} ref={ref as any} variant="filled" {...props} />
})

const actionType = [
  {
    value: 'OK',
    label: 'OK',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Replace',
    label: 'Replace',
  },
  {
    value: 'Re-visit',
    label: 'Re-visit',
  },
]

const functionalLocationType = [
  {
    value: 'Primary Section',
    label: 'Primary Section',
  },
  {
    value: 'Secondary Section',
    label: 'Secondary Section',
  },
]

const failureType = [
  {
    value: 'Mechanical',
    label: 'Mechanical',
  },
  {
    value: 'Electrical',
    label: 'Electrical',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]
const severityType = [
  {
    value: 'Critical',
    label: 'Critical',
  },
  {
    value: 'Non-Critical',
    label: 'Non-Critical',
  },
]
const operationEffectType = [
  {
    value: '01',
    label: '01-Production Stopped',
  },
  {
    value: '02-',
    label: '02-Mode Change',
  },
  {
    value: '03-',
    label: '03-No effect',
  },
]

const fixType = [
  {
    value: 'Operator',
    label: 'Operator to initiate repair themselves',
  },
  {
    value: 'Maintenance',
    label: 'Maintenance Personnel Required',
  },
  {
    value: 'Electrical Personnel Required',
    label: 'Electrical Personnel Required',
  },
]
const escalationType = [
  {
    value: 'Fitter',
    label: 'Fitter',
  },
  {
    value: 'Mechanic',
    label: 'Mechanic',
  },
  {
    value: 'Electrician',
    label: 'Electrician',
  },
  {
    value: 'Diesel Mechanic',
    label: 'Diesel Mechanic',
  },
  {
    value: 'Unkown',
    label: 'Unknown',
  },
]

const MaintenanceRequestDetails = () => {
  const Input = styled('input')({ display: 'none' })

  const [description, setDescription] = useState<string>('')
  const [equipmentId, setEquipmentId] = useState<string>('')
  const [escalation, setEscalation] = useState<string>('')
  const [functionalLocationId, setFunctionalLocationId] = useState<string>('')
  const [operationalEffect, setOperationalEffect] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const user = useAppSelector((state) => state.user).user

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleEquipmentIdChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentId(event.target.value as string)
  }

  const handleEscalationChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEscalation(event.target.value as string)
  }

  const handleFunctionalLocationIdChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFunctionalLocationId(event.target.value as string)
  }
  const handleOperationalEffectChange = (event: ChangeEvent<{ value: unknown }>) => {
    setOperationalEffect(event.target.value as string)
  }

  const handleTitleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTitle(event.target.value as string)
  }

  const onAddMaintenanceRequest = async () => {
    const payload = {
      token: user!.accessToken!,
      description,
      equipmentId,
      escalation,
      functionalLocationId,
      operationalEffect,
      title,
    }

    try {
      await dispatch(createMaintenanceRequest(payload)).unwrap()
      navigate('../maintenanceRequest/')
    } catch (error) {
      if ((error as IApiError).status !== 401) {
        return setErrorMessage((error as IApiError).message)
      }

      dispatch(logout()).unwrap()
      navigate('/')
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={errorMessage ? true : false}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Card>
          <CustomCardHeader title="Notification" subtitle="Create a Maintenance Request" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField id="discharge" variant="outlined" label="Header" />
                <TextField
                  id="FL"
                  select
                  variant="outlined"
                  required
                  label="Activity Area"
                  value={functionalLocationId}
                  onChange={handleFunctionalLocationIdChange}
                >
                  {functionalLocationType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="discharge" variant="outlined" label="Equipment" />
                <TextField id="discharge" variant="outlined" label="Description" />
                <TextField
                  id="grading"
                  select
                  variant="outlined"
                  required
                  label="Effect on Operations"
                  value={operationalEffect}
                  onChange={handleOperationalEffectChange}
                >
                  {operationEffectType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="action-to"
                  select
                  variant="outlined"
                  required
                  label="Escalation Required"
                  value={escalation}
                  onChange={handleEscalationChange}
                >
                  {escalationType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <label htmlFor="contained-button-file">
                  <Input accept="image/*" id="contained-button-file" multiple type="file" />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <label htmlFor="icon-button-file">
                  <Input accept="image/*" id="icon-button-file" type="file" />
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Add Maintenance Request
        </Button>
      </Grid>
      <Footer />
    </>
  )
}

export default MaintenanceRequestDetails
