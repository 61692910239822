import { FC, ChangeEvent, useState } from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent,
} from '@mui/material'
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone'
import { NavLink } from 'react-router-dom'

import { IMaintenanceRequest, RequestStatus } from '../../models/maintenanceRequest'
import Label from '../../components/Label'
import { toTitleCase } from '../../utilities/Helpers'
import { OpenClosedStatus } from '../../models/helpers'

interface MaintenanceTaskTableProps {
  className?: string
  data: IMaintenanceRequest[]
}

interface Filters {
  status: OpenClosedStatus | null
}

const getStatusLabel = (status: RequestStatus): JSX.Element => {
  const map = {
    open: {
      text: 'Open',
      color: 'error',
    },
    closed: {
      text: 'Closed',
      color: 'success',
    },
  }

  const { text, color }: any = map[status]

  return <Label color={color}>{text}</Label>
}

const applyFilters = (
  maintenanceRequests: IMaintenanceRequest[],
  filters: Filters,
): IMaintenanceRequest[] => {
  return maintenanceRequests.filter((user) => {
    let matches = true

    if (filters.status && user.status !== filters.status) {
      matches = false
    }

    return matches
  })
}

const applyPagination = (
  maintenanceRequests: IMaintenanceRequest[],
  page: number,
  limit: number,
): IMaintenanceRequest[] => {
  return maintenanceRequests.slice(page * limit, page * limit + limit)
}

const MaintenanceRequestTable: FC<MaintenanceTaskTableProps> = ({ data }) => {
  const [page, setPage] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [filters, setFilters] = useState<Filters>({
    status: null,
  })

  const statusOptions = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'open',
      name: 'Open',
    },
    {
      id: 'closed',
      name: 'Closed',
    },
  ]

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage)
  }

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value))
  }

  const handleStatusChange = (e: SelectChangeEvent<string>): void => {
    let value: OpenClosedStatus | null = null

    if (e.target.value !== 'all') {
      value = e.target.value as OpenClosedStatus
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }))
  }

  const filteredData = applyFilters(data, filters)
  const paginatedData = applyPagination(filteredData, page, limit)
  const theme = useTheme()

  return (
    <Card>
      <CardHeader
        action={
          <Box width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status || 'all'}
                onChange={(event) => handleStatusChange(event)}
                label="Status"
                autoWidth
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title={`${toTitleCase(filters.status || '') || 'All'} Notifications`}
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Request Number</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Effects on Operations</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((item) => {
              return (
                <TableRow hover key={item.id}>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.requestNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {format(new Date(item.createdAt), 'MMMM dd yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {item.operationalEffect}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{getStatusLabel(item.status)}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Request" arrow>
                      <IconButton
                        component={NavLink}
                        sx={{
                          '&:hover': {
                            background: theme.colors.success.lighter,
                          },
                          color: theme.colors.success.main,
                        }}
                        color="inherit"
                        size="small"
                        to={`maintenance-request/${item.id}`}
                      >
                        <VisibilityTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  )
}

MaintenanceRequestTable.propTypes = {
  data: PropTypes.array.isRequired,
}

MaintenanceRequestTable.defaultProps = {
  data: [],
}

export default MaintenanceRequestTable
