import axios from 'axios'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

interface IProductionOutputCreate {
  createdBy: string
  productionTarget: number
  quantityPerTonne: number
  rawMaterialId: string
  sludgeName: string
  sludgeQuantity: number
}

export default class ProductionOutputRequests {
  static create = async (data: IProductionOutputCreate) => {
    try {
      const response = await axios.post(`${domain}/production-output`, data)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }

  static getAll = async () => {
    try {
      const response = await axios.get(`${domain}/production-output`)

      return {
        status: response.status,
        data: response.data,
      }
    } catch (error: any) {
      return {
        status: error.response.status,
        data: error.response.data,
      }
    }
  }
}
