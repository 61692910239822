import { Helmet } from 'react-helmet-async'
import { Container, Grid, Typography, CardContent, Card, Box, Divider } from '@mui/material'
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone'

import Label from '../../components/Label'
import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import Text from '../../components/Text'
import Footer from '../../components/Footer'
import CustomCardHeader from '../../components/CustomCardHeader'

function Profile() {
  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Profile" subHeading="This is your personal profile." />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                title="Personal Details"
                subtitle="Manage informations related to your personal details"
                buttonText="Edit"
                showButton
              />
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Name:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>Azile Silwana</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Date of birth:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>15 March 1977</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Email:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>azile@test.com</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Address:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                        <Text color="black">
                          <b>1749 High Meadow Lane, SEQUOIA NATIONAL PARK, California, 93262</b>
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                title="Account Settings"
                subtitle="Manage details related to your account"
                buttonText="Edit"
                showButton
              />
              <Divider />
              <CardContent sx={{ p: 4 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Language:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>English (US)</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Timezone:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>GMT +2</b>
                      </Text>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box pr={3} pb={2}>
                        Account status:
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Label color="success">
                        <DoneTwoToneIcon fontSize="small" />
                        <b>Active</b>
                      </Label>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CustomCardHeader
                title="Change Password"
                subtitle="You can change your password here"
                buttonText="Change Password"
                showButton
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  )
}

export default Profile
