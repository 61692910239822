import axios from 'axios'

import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IActivityAreaCreate {
  costCentreId: string
  initialDate: Date
  name: string
  refId: string
  parent?: string
  token: string
}

export interface IActivityAreaGet {
  id?: string
  token: string
}

export default class ActivityAreaRequests {
  static getAll = (data: IActivityAreaGet) => {
    return axios.get(`${domain}/functional-location`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IActivityAreaGet) => {
    return axios.get(`${domain}/functional-location/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static create = (data: IActivityAreaCreate) => {
    return axios.post(
      `${domain}/functional-location`,
      {
        costCentreId: data.costCentreId,
        initialDate: data.initialDate,
        name: data.name,
        parent: data.parent,
        refId: data.refId,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }
}
