const getHeaders = (token: string) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

const helpers = {
  getHeaders,
}

export default helpers
