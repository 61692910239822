import { useRoutes } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { Provider } from 'react-redux'

import routes from './router'
import ThemeProvider from './theme/ThemeProvider'
import { store } from './store'

const App = () => {
  const content = useRoutes(routes)

  return (
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        {content}
      </ThemeProvider>
    </Provider>
  )
}

export default App
