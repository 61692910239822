import axios from 'axios'

import {
  AccountHolderRelationship,
  AccountStatus,
  AccountType,
  EmploymentType,
  Gender,
  MaritalStatus,
  Race,
  Title,
} from '../models/users'
import helpers from './helpers'

// Get the domain of the server
const domain = process.env.REACT_APP_DOMAIN

export interface IUserCreate {
  birthDate: Date
  cellphone: string
  departmentId: string
  disability?: string
  disabled: boolean
  email: string
  gender: Gender
  homePhone?: string
  idNumber?: string
  jobTitle: string
  maritalStatus: MaritalStatus
  name: string
  nationality: string
  managerId?: string
  naturalisationDate?: Date
  otherGender?: string
  passportExpiryDate?: Date
  passportNumber?: string
  permissionId: string
  race: Race
  startDate: Date
  status: AccountStatus
  surname: string
  taxNumber: string
  taxOffice: string
  title: Title
  type: EmploymentType
  workPhone?: string
  city: string
  code: string
  complexName?: string
  country: string
  poBox?: string
  province: string
  streetName: string
  streetNumber: string
  suburb: string
  unitNumber?: string
  accountHolder: string
  accountHolderRelationship: AccountHolderRelationship
  accountNumber: string
  accountType: AccountType
  bank: string
  universalCode: string
  token: string
}

export interface IUserGet {
  id?: string
  token: string
}

export default class UserRequests {
  /**
   * Add a user.
   * @param data.cellphone is the user's cellphone number.
   * @param data.department is the user's department.
   * @param data.disabled determines if the user is disabled or not.
   * @param data.email is the user's email.
   * @param data.gender is the user's gender.
   * @param data.maritalStatus is the user's marital status.
   * @param data.name is the user's name.
   * @param data.nationality is the user's nationality in the alpha-3 code format.
   * @param data.permission is the user's permission in the app.
   * @param data.race is the user's race.
   * @param data.startDate is the user's employment start date.
   * @param data.status is the user's account status.
   * @param data.surname is the user's surname.
   * @param data.taxNumber is the user's tax number.
   * @param data.taxOffice is the user's tax office.
   * @param data.title is the user's title when addressed.
   * @param data.type is the user's employment type.
   */
  static create = (data: IUserCreate) => {
    return axios.post(
      `${domain}/user`,
      {
        birthDate: data.birthDate,
        cellphone: data.cellphone,
        departmentId: data.departmentId,
        disability: data.disability,
        disabled: data.disabled,
        email: data.email,
        gender: data.gender,
        homePhone: data.homePhone,
        idNumber: data.idNumber,
        jobTitle: data.jobTitle,
        managerId: data.managerId,
        maritalStatus: data.maritalStatus,
        name: data.name,
        nationality: data.nationality,
        naturalisationDate: data.naturalisationDate,
        otherGender: data.otherGender,
        passportExpiryDate: data.passportExpiryDate,
        passportNumber: data.passportNumber,
        permissionId: data.permissionId,
        race: data.race,
        startDate: data.startDate,
        status: data.status,
        surname: data.surname,
        taxNumber: data.taxNumber,
        taxOffice: data.taxOffice,
        title: data.title,
        type: data.type,
        workPhone: data.workPhone,
        city: data.city,
        code: data.code,
        complexName: data.complexName,
        country: data.country,
        poBox: data.poBox,
        province: data.province,
        streetName: data.streetName,
        streetNumber: data.streetNumber,
        suburb: data.suburb,
        unitNumber: data.unitNumber,
        accountHolder: data.accountHolder,
        accountHolderRelationship: data.accountHolderRelationship,
        accountNumber: data.accountNumber,
        accountType: data.accountType,
        bank: data.bank,
        universalCode: data.universalCode,
      },
      {
        headers: helpers.getHeaders(data.token),
      },
    )
  }

  static getAll = (data: IUserGet) => {
    return axios.get(`${domain}/user`, {
      headers: helpers.getHeaders(data.token),
    })
  }

  static get = (data: IUserGet) => {
    return axios.get(`${domain}/user/${data.id}`, {
      headers: helpers.getHeaders(data.token),
    })
  }
}
