import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { ChangeEvent, FC, useState } from 'react'

import { EquipmentType, INodeData } from '../../models/node'

interface EditNodeDialogProps {
  data: INodeData
  equipmentOptions: any[]
  onCancelPress?: () => void
  onEditPress?: (data: INodeData) => void
}

const equipmentTypeOptions = [
  {
    value: 'crusher',
    label: 'Crusher',
  },
  {
    value: 'conveyorBelt',
    label: 'Conveyor Belt',
  },
  {
    value: 'screen',
    label: 'Screen',
  },
  {
    value: 'chute',
    label: 'Chute',
  },
  {
    value: 'isp',
    label: 'ISP',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
]

const EditNodeDialog: FC<EditNodeDialogProps> = ({
  data,
  equipmentOptions,
  onCancelPress,
  onEditPress,
}) => {
  const [title, setTitle] = useState<string>(data.title)
  const [description, setDescription] = useState<string | undefined>(data.description)
  const [equipmentType, setEquipmentType] = useState<EquipmentType>(data.equipmentType)
  const [equipmentId, setEquipmentId] = useState<string | undefined>(data.equipmentId)
  const [feedRate, setFeedRate] = useState<number>(0)
  const [rockSize, setRockSize] = useState<number | undefined>(data.rockSize)

  const handleEquipmentTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentType(event.target.value as EquipmentType)
  }

  const handleEquipmentIdChange = (event: ChangeEvent<{ value: unknown }>) => {
    setEquipmentId(event.target.value as string)
  }

  const handleEditPress = () => {
    onEditPress &&
      onEditPress({
        description,
        equipmentId,
        equipmentType,
        feedRate,
        rockSize,
        title,
      })
  }

  const handleTitleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setTitle(event.target.value as string)
  }

  const handleDescriptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setDescription(event.target.value as string)
  }

  const handleRockSizeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setRockSize(event.target.value as number)
  }

  const handleFeedRateChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFeedRate(event.target.value as number)
  }

  return (
    <>
      <Dialog open>
        <DialogTitle>Edit Section</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, minWidth: '47%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              variant="outlined"
              required
              label="Description"
              multiline
              sx={{ width: '97%' }}
              value={description}
              onChange={handleDescriptionChange}
            />
            <TextField
              variant="outlined"
              required
              label="Title"
              value={title}
              onChange={handleTitleChange}
            />
            {equipmentType !== 'feed' && equipmentType !== 'isp' && (
              <TextField
                select
                variant="outlined"
                required
                label="Equipment"
                value={equipmentId}
                onChange={handleEquipmentIdChange}
              >
                {equipmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {equipmentType !== 'feed' && (
              <TextField
                select
                variant="outlined"
                required
                label="Equipment Type"
                value={equipmentType}
                onChange={handleEquipmentTypeChange}
              >
                {equipmentTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {(equipmentType === 'crusher' ||
              equipmentType === 'screen' ||
              equipmentType === 'custom') && (
              <>
                <TextField
                  variant="outlined"
                  type="number"
                  required
                  label={
                    equipmentType === 'crusher'
                      ? 'Open Side Setting (mm)'
                      : equipmentType === 'screen'
                      ? 'Max Size (mm)'
                      : 'Max Output Size (mm)'
                  }
                  value={rockSize}
                  onChange={handleRockSizeChange}
                />
              </>
            )}
            <TextField
              variant="outlined"
              type="number"
              required
              label="Feed Rate (mtph)"
              value={feedRate}
              onChange={handleFeedRateChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelPress}>Cancel</Button>
          <Button onClick={handleEditPress}>Edit</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditNodeDialog
