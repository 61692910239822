import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'

import { IJobCard } from '../../models/jobCard'
import JobCardDetails from './JobCardDetails'

const AddJobCard = () => {
  const location = useLocation()
  const worksOrder: IJobCard = (location.state as any).worksOrder

  return (
    <>
      <Helmet>
        <title>Add Maintenance</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid container spacing={3} pt={4}>
          <JobCardDetails data={worksOrder} />
        </Grid>
      </Container>
    </>
  )
}

export default AddJobCard
