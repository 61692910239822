import { Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'
import { IStockpile } from '../../models/stockpiles'
import StockpileDetails from './StockpileDetails'

const ViewStockpile = () => {
  const location = useLocation()
  const stockpile: IStockpile = (location.state as any).stockpile

  return (
    <>
      <Helmet>
        <title>{stockpile.name}</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading={stockpile.name} subHeading={stockpile.description} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <StockpileDetails />
        </Grid>
      </Container>
    </>
  )
}

export default ViewStockpile
