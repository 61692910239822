import { Helmet } from 'react-helmet-async'
import { Container } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import PageTitleWrapper from '../../components/PageTitleWrapper'

function LeavePolicies() {
  return (
    <>
      <Helmet>
        <title>Leave Policies</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Leave Policies"
          subHeading="Managing and setting up company leave policy"
          buttonText="Add Policy"
          showButton
          link='add'
        />
      </PageTitleWrapper>
      <Container maxWidth="lg"></Container>
    </>
  )
}

export default LeavePolicies
