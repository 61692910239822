import { ChangeEvent, useState } from 'react'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { Button, Card, CardContent, Divider, Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import CustomCardHeader from '../../components/CustomCardHeader'
import Footer from '../../components/Footer'

const superEquipmentType = [
  {
    value: 'Conveyer Belt',
    label: 'Conveyer Belt',
  },
  {
    value: 'Smelter Dish',
    label: 'Smelter Dish',
  },
  {
    value: 'Hydraulic Shafts',
    label: 'Hydraulic Shafts',
  },
  {
    value: 'SA 30',
    label: 'SA 30 X 42 Jaw Crusher',
  },
]

const subAssemblyType = [
  {
    value: 'Sub 1',
    label: 'Sub 1',
  },
  {
    value: 'Sub 2',
    label: 'Sub 2',
  },
]

const superiorType = [
  {
    value: 'Primary Section',
    label: 'Primary Section',
  },
  {
    value: 'Secondary Section',
    label: 'Secondary Section',
  },
]

const maintenanceType = [
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Replacement',
    label: 'Replacement',
  },
  {
    value: 'Repair',
    label: 'Repair',
  },
  {
    value: 'Inspection',
    label: 'Inspection',
  },
  {
    value: 'General Maintenance',
    label: 'General Maintenance',
  },
]

const mainTriggerType = [
  {
    value: 'Run Time',
    label: 'Run Time',
  },
  {
    value: 'Production Output',
    label: 'Production Output',
  },
  {
    value: 'Fixed',
    label: 'Fixed',
  },
]

const schedulingType = [
  {
    value: 'Runtime',
    label: 'Runtime',
  },
  {
    value: 'Cycle',
    label: 'Cycle',
  },
  {
    value: 'Production Output',
    label: 'Production Output',
  },
  {
    value: 'Set Date',
    label: 'Set Date',
  },
  {
    value: 'Set Time',
    label: 'Set Time',
  },
]

const productionType = [
  {
    value: 'Production Stopped',
    label: 'Production Stopped',
  },
  {
    value: 'Reduced Efficiency',
    label: 'Production Output Reduced',
  },
  {
    value: 'Production Normal',
    label: 'Production Normal',
  },
]

const maintenanceGroupType = [
  {
    value: 'Mechanical',
    label: 'Mechanical',
  },
  {
    value: 'Electrical',
    label: 'Electrical',
  },
  {
    value: 'All',
    label: 'All',
  },
]

const authorizingType = [
  {
    value: 'Supervisor',
    label: 'Supervisor',
  },
  {
    value: 'Head of Engineering',
    label: 'Head of Engineering',
  },
]

const subEquipmentType = [
  {
    value: 'Motor And GearBox',
    label: 'Discharge Point',
  },
  {
    value: 'Drum and Idlers',
    label: 'Drun and Idlers',
  },
]

const mainType = [
  {
    value: 'Routine',
    label: 'Routine',
  },
  {
    value: 'Preventative',
    label: 'Preventative',
  },
]

const MaintenanceTaskDetails = () => {
  const [superEquipment, setSuperEquipment] = useState<string>('')
  const [subAssembly, setSubAssembly] = useState<string>('')
  const [superior, setSuperior] = useState<string>('')
  const [maintenance, setMaintenance] = useState<string>('')
  const [mainTrigger, setMainTrigger] = useState<string>('')
  const [scheduling, setScheduling] = useState<string>('')
  const [production, setProduction] = useState<string>('')
  const [maintenanceGroup, setMaintenanceGroup] = useState<string>('')
  const [authorizing, setAuthorizing] = useState<string>('')
  const [subEquipment, setSubEquipment] = useState<string>('')
  const [main, setMain] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const [value, setValue] = useState<Date | null>(new Date())
  const [value2, setValue2] = useState<Date | null>(new Date())

  const handleSuperEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperEquipment(event.target.value as string)
  }
  const handleSuperiorChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSuperior(event.target.value as string)
  }

  const handleSubAssemblyChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubAssembly(event.target.value as string)
  }

  const handleMaintenanceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenance(event.target.value as string)
  }
  const handleMainTriggerChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMainTrigger(event.target.value as string)
  }
  const handleProductionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setProduction(event.target.value as string)
  }
  const handleMaintenanceGroupChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMaintenanceGroup(event.target.value as string)
  }
  const handleAuthorizingChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAuthorizing(event.target.value as string)
  }
  const handleSubEquipmentChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSubEquipment(event.target.value as string)
  }

  const handleMainChange = (event: ChangeEvent<{ value: unknown }>) => {
    setMain(event.target.value as string)
  }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Reference Equipment/Assembly"
            subtitle="Enter assembly/equipment details"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="unit"
                  select
                  variant="outlined"
                  required
                  label="Activity Area"
                  value={superior}
                  onChange={handleSuperiorChange}
                >
                  {superiorType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="equipment"
                  select
                  variant="outlined"
                  required
                  label="Equipment"
                  value={superEquipment}
                  onChange={handleSuperEquipmentChange}
                >
                  {superEquipmentType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="equipment"
                  select
                  variant="outlined"
                  required
                  label="Sub Assembly"
                  value={subAssembly}
                  onChange={handleSubAssemblyChange}
                >
                  {subAssemblyType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="type"
                  select
                  variant="outlined"
                  required
                  label="Planned Maintenance Type"
                  value={main}
                  onChange={handleMainChange}
                >
                  {mainType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader
            title="Planning Data"
            subtitle="Type of task and length of time needed to complete"
          />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="m-type"
                  select
                  variant="outlined"
                  required
                  label="Maintenance Trigger"
                  value={mainTrigger}
                  onChange={handleMainTriggerChange}
                >
                  {mainTriggerType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField id="when" variant="outlined" required label="After" />
                <TextField
                  id="m-type"
                  select
                  variant="outlined"
                  required
                  label="Maintenance Type"
                  value={maintenance}
                  onChange={handleMaintenanceChange}
                >
                  {maintenanceType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="production-type"
                  select
                  variant="outlined"
                  required
                  label="Production State"
                  value={production}
                  onChange={handleProductionChange}
                >
                  {productionType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CustomCardHeader title="Work Scheduling" subtitle="Enter details of the assignment group" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="unit"
                  select
                  variant="outlined"
                  required
                  label="Maintenance Group"
                  value={maintenanceGroup}
                  onChange={handleMaintenanceGroupChange}
                >
                  {maintenanceGroupType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="authorizing"
                  select
                  variant="outlined"
                  required
                  label="Authorizing Source"
                  value={authorizing}
                  onChange={handleAuthorizingChange}
                >
                  {authorizingType.map((option) => (
                    <MenuItem key={option.value} value={option.value} sx={{ width: '100%' }}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MobileTimePicker
                  label="Scheduled Start"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileTimePicker
                  label="Scheduled Finished"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center">
        <div />
        <Button variant="contained" onClick={() => {}}>
          Add Maintenance Task
        </Button>
      </Grid>
      <Footer />
    </LocalizationProvider>
  )
}

export default MaintenanceTaskDetails
