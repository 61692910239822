import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'

const StockpileDetails = () => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Details" />
          <Divider />
          <CardContent sx={{ p: 2 }}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, minWidth: '48%' },
              }}
              noValidate
              autoComplete="off"
            ></Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default StockpileDetails
